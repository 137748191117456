import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Brush, CartesianGrid } from 'recharts';

// Props
// graph: <ExpansionGraph type={type} sensorID={liveReading.sensorID} />


export default class DetailPirGraph extends React.Component {

    constructor() {
        super()

        this.state = {
            type: undefined,
            graph: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        this.createGraphs();
    }

    createGraphs = () => {
        //Takes graph data from the PIR calculations class and outputs them here
        let completeGraph =
            <ResponsiveContainer height="90%">
                <LineChart data={this.props.data} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="Motion" stroke="#8884d8" fill="#8884d8" dot={false}/>
                    <XAxis dataKey="name" />
                    <YAxis/>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip/>
                    <Brush dataKey="name" height={30} stroke="#1A355C" />
                </LineChart>
            </ResponsiveContainer>

        this.setState({ graph: completeGraph, isLoading: false });
    }

    render() {
        return (
            this.state.isLoading ?
                ""
                :
                this.state.graph
        )
    }
}