import React from 'react';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Brush, CartesianGrid } from 'recharts';
import { datetimeformatterService } from '../../services/datetimeformatter.service';

// Props
// graph: <ExpansionGraph type={type} sensorID={liveReading.sensorID} />

export default class DetailGraph extends React.Component {

    constructor() {
        super()

        this.state = {
            type: undefined,
            graph: [],
            isLoading: true,
            label: "",
        }
    }

    componentDidMount() {
        this.setState({ type: this.props.type });

        this.createGraphs(this.props.data);
    }

    createGraphs = (processedData) => {
        const graphData = this.processGraphData(processedData);
            let completeGraph =
                <ResponsiveContainer height="90%">
                    <LineChart data={graphData[0]} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                        <Line type="monotone" dataKey={graphData[1]} stroke="#8884d8" fill="#8884d8" dot={false} />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <Tooltip formatter={(label) => label + " " + processedData[0].unit}/>
                        {/* <Legend /> */}
                        <Brush dataKey="name" height={30} stroke="#1A355C" />
                    </LineChart>
                </ResponsiveContainer>

            this.setState({ graph: completeGraph, isLoading: false });
    }

    processGraphData = (processedData) => {
        let data = [], label;

        // Creates an array of graph data based on the sensor name matching the style name from dashboard
        processedData.forEach((reading, i) => {
            if (reading.type === this.props.type) {
                let time = datetimeformatterService.formatDateTimeNoSeconds(reading.readingTime);
                if (reading.type === "L8") {
                    label = "Temperature";
                } else {
                    label = reading.type.charAt(0) + reading.type.slice(1).toLowerCase();
                }
                
                data.push({ name: time, [label]: parseFloat(reading.value) });
            }
        })
        const graphData = [data, label];
        return graphData;
    }

    render() {
        return (
            this.state.isLoading ?
                ""
                :
                this.state.graph
        )
    }
}