import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { apiService } from '../../../services/api.service';

import { ReactComponent as MoveHandle } from '../../../icons/MoveHandle.svg';
import { ReactComponent as Wifi } from '../../../icons/WiFi.svg';
import { ReactComponent as WifiNavy } from '../../../icons/WiFiNavy.svg';
import { dataService } from '../../../services/data.service';

export default class WifiDetails extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            ssid: '',
            password: ''
        }
    }

    componentDidMount() {
        //Check if coming from site or room
        if (this.props.site !== undefined) {
            apiService.getData("/engineer/wifi/" + this.props.site)
                .then(wifi => {
                    this.setState({ ssid: wifi.wifiSsid, password: wifi.wifiPass, isLoading: false })
                })
        } else if (this.props.room !== undefined) {
            //Get site id from room id
            dataService.getAllRooms()
                .then(rooms => {
                    const currentRoom = rooms.filter(room => room.id === this.props.room);
                    apiService.getData("/engineer/wifi/" + currentRoom[0].site_Id)
                        .then(wifi => {
                            this.setState({ ssid: wifi.wifiSsid, password: wifi.wifiPass, isLoading: false })
                        })
                })
        }
    }

    render() {
        var sensorDisplayInfo = {
            id: 0,
            type: "",
            isGraph: true,
            colours: {
                header: "",
                body: ""
            },
            alertLevel: "",
        };

        sensorDisplayInfo.name = ""
        sensorDisplayInfo.type = "WIFI"
        sensorDisplayInfo.id = ""

        let headerStyles = {
            backgroundColor: "#5C7395"
        }

        let bodyStyles = { backgroundColor: "#3C5373" };

        return (
            <div className="graph-tile">
                <Col lg={12} className="header-wrapper" style={headerStyles}>
                    <Row className="text-center header">
                        <Col lg={2} className="icon">
                            {<Wifi width={40} />}
                        </Col>
                        <Col lg={8} className="title">
                            {sensorDisplayInfo.type}
                        </Col>
                        <Col lg={2} className="drag-handle">
                            <MoveHandle width={15} />
                        </Col>
                    </Row>
                </Col>

                {this.state.isLoading ?
                    <Col lg={12} className="body" style={bodyStyles}>
                        <div className="tile-loading">
                            <Spinner className="tile-spinner" />
                        </div>
                    </Col>
                    :
                    <Col lg={12} className="body" style={bodyStyles}>
                        <div className="wifi-tile">
                            <div className="wifi-tile-bg">
                                <WifiNavy width={160} height={160} />
                            </div>
                            <div className="wifi-tile-text">
                                SSID: <br />
                                <strong>{this.state.ssid}</strong>
                            </div>
                            <div className="wifi-tile-text">
                                PASSWORD: <br />
                                <strong>{this.state.password}</strong>
                            </div>
                        </div>
                    </Col>
                }
            </div>
        );
    }
}