import React from "react";
import { Col } from "reactstrap";
import "./Rollover.scss";

import { ReactComponent as ExpandArrows } from "../../../icons/ExpandArrows.svg";

export default class Rollover extends React.Component {
  render() {
    return (
      <Col className="rollover">
        <ExpandArrows width={70} />
        <p className="rollover-text">GO TO SENSOR</p>
      </Col>
    );
  }
}
