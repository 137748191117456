import React from 'react';

class GradientSVG extends React.Component {
    render() {
      let { startColor, midColor, endColor, idCSS, rotation } = this.props;
  
      let gradientTransform = `rotate(${rotation})`;
  
      return (
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id={idCSS} gradientTransform={gradientTransform} x1="0%" y1="0%" x2="50%" y2="0%">
              <stop offset="0%" stopColor={startColor} />
              <stop offset="50%" stopColor={midColor} />
              <stop offset="100%" stopColor={endColor} />
            </linearGradient>
          </defs>
        </svg>
      );
    }
  }
  
  export default GradientSVG;