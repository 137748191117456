import React from 'react';

import { ReactComponent as SadFace } from '../../../icons/SadFace.svg';
import { ReactComponent as HappyFace } from '../../../icons/HappyFace.svg';

export default class DetailAirQualityHighLow extends React.Component {

    constructor() {
        super()

        this.state = {
            cssClass: "",
            text: "",
            type: "",
            icon: "",
            data: "",
        }
    }

    componentDidMount() {
        this.ifPeak();
    }

    ifPeak = () => {
        const { peak } = this.props;
        var cssClass, icon, text, data = [];

        //Checks if high or low
        if (peak) {
            text = "BEST"
            cssClass = "airquality-high";
            icon = <HappyFace width={40} className="airquality-icon" />;
        } else {
            text = "WORST";
            cssClass = "airquality-low";
            icon = <SadFace width={40} className="airquality-icon" />;
        }

        this.setState({ text: text, cssClass: cssClass, icon: icon, data: data });
    }

    render() {
        const data = this.props.values;

        return (
            data.length > 0 ?
                <div className={this.state.cssClass}>
                    <h2>{this.state.text} <br /> AIR QUALITY</h2>
                    {this.state.icon}
                    <div className="airquality-high-low-readings">
                        <span><strong>PM<sub>1</sub> {data[0].value}</strong> {data[0].date}</span>
                        <span><strong>PM<sub>2.5</sub> {data[1].value}</strong> {data[1].date}</span>
                        <span><strong>PM<sub>10</sub> {data[2].value}</strong> {data[2].date}</span>
                    </div>
                </div>
                :
                ""
        )

    }
}