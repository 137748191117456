import React from 'react';
import Dexie from 'dexie';
import Leaflet from 'leaflet'
import { Spinner } from 'reactstrap';
import { Map, TileLayer, Marker, Popup, Tooltip, LayersControl, LayerGroup, GeoJSON  } from 'react-leaflet';

import { processService } from '../../services/processing.service';
import { dataService } from '../../services/data.service';
import SiteCard from '../sites/SiteCard';
import { Mixpanel } from '../../Mixpanel';

import './Map.scss';

const { Overlay } = LayersControl;

const UK = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {},
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-6.6796875, 49.653404588437894],
              [-5.295410156249999, 49.69606181911566],
              [-4.06494140625, 49.89463439573421],
              [-2.48291015625, 50.162824333817284],
              [0.46142578125, 50.499452103967734],
              [1.1865234375, 50.708634400828224],
              [1.4694213867187498, 50.94544969875718],
              [1.8814086914062498, 51.10524653088847],
              [1.988525390625, 51.22408779639158],
              [2.010498046875, 51.38378095727871],
              [1.9445800781249998, 51.72022261695929],
              [1.9445800781249998, 51.86292391360244],
              [2.054443359375, 52.01869808104436],
              [2.384033203125, 52.61639023304539],
              [2.362060546875, 52.76289173758374],
              [2.142333984375, 52.9883372533954],
              [-0.81298828125, 56.523139564372194],
              [-1.0986328125, 56.88500172043518],
              [-1.07666015625, 57.362089861548945],
              [-0.9667968749999999, 58.0546319113729],
              [-0.703125, 58.81374171570782],
              [0.615234375, 61.10078883158897],
              [0.087890625, 61.56457388515458],
              [-1.0546875, 61.60639637138628],
              [-2.2412109375, 61.41775026352097],
              [-6.459960937499999, 60.30518536282736],
              [-8.4375, 59.28833169203345],
              [-9.931640625, 58.12431960569374],
              [-10.458984375, 56.728621973140726],
              [-11.6455078125, 53.48804553605622],
              [-11.57958984375, 51.713416052417614],
              [-11.337890625, 51.31688050404585],
              [-10.8984375, 51.07591977545679],
              [-9.68994140625, 50.534380406110806],
              [-8.06396484375, 49.97242235423708],
              [-6.6796875, 49.653404588437894]
            ]
          ]
        }
      }
    ]
  };
  
//Import the different pins
const LocationGreen = new Leaflet.Icon({
    iconUrl: require('../../icons/glowGreen.png'),
    iconSize: [64, 64],
});

const LocationAmber = new Leaflet.Icon({
    iconUrl: require('../../icons/glowAmber.png'),
    iconSize: [64, 64],
});

const LocationRed = new Leaflet.Icon({
    iconUrl: require('../../icons/glowRed.png'),
    iconSize: [64, 64],
});

//Shape of the UK
// const UK = {
//     "type": "FeatureCollection",
//     "features": [{
//         "type": "Feature",
//         "properties": {},
//         "geometry": {
//             "type": "Polygon",
//             "coordinates": [
//                 [
//                     [
//                         -6.6796875,
//                         49.653404588437894
//                     ],
//                     [
//                         -5.295410156249999,
//                         49.69606181911566
//                     ],
//                     [
//                         -4.06494140625,
//                         49.89463439573421
//                     ],
//                     [
//                         -2.48291015625,
//                         50.162824333817284
//                     ],
//                     [
//                         0.46142578125,
//                         50.499452103967734
//                     ],
//                     [
//                         1.1865234375,
//                         50.708634400828224
//                     ],
//                     [
//                         1.4694213867187498,
//                         50.94544969875718
//                     ],
//                     [
//                         1.8814086914062498,
//                         51.10524653088847
//                     ],
//                     [
//                         1.988525390625,
//                         51.22408779639158
//                     ],
//                     [
//                         2.010498046875,
//                         51.38378095727871
//                     ],
//                     [
//                         1.9445800781249998,
//                         51.72022261695929
//                     ],
//                     [
//                         1.9445800781249998,
//                         51.86292391360244
//                     ],
//                     [
//                         2.054443359375,
//                         52.01869808104436
//                     ],
//                     [
//                         2.384033203125,
//                         52.61639023304539
//                     ],
//                     [
//                         2.362060546875,
//                         52.76289173758374
//                     ],
//                     [
//                         2.142333984375,
//                         52.9883372533954
//                     ],
//                     [
//                         -0.81298828125,
//                         56.523139564372194
//                     ],
//                     [
//                         -1.0986328125,
//                         56.88500172043518
//                     ],
//                     [
//                         -1.07666015625,
//                         57.362089861548945
//                     ],
//                     [
//                         -0.9667968749999999,
//                         58.0546319113729
//                     ],
//                     [
//                         -0.703125,
//                         58.81374171570782
//                     ],
//                     [
//                         0.615234375,
//                         61.10078883158897
//                     ],
//                     [
//                         0.087890625,
//                         61.56457388515458
//                     ],
//                     [
//                         -1.0546875,
//                         61.60639637138628
//                     ],
//                     [
//                         -2.2412109375,
//                         61.41775026352097
//                     ],
//                     [
//                         -6.459960937499999,
//                         60.30518536282736
//                     ],
//                     [
//                         -8.4375,
//                         59.28833169203345
//                     ],
//                     [
//                         -9.931640625,
//                         58.12431960569374
//                     ],
//                     [
//                         -10.458984375,
//                         56.728621973140726
//                     ],
//                     [
//                         -11.6455078125,
//                         53.48804553605622
//                     ],
//                     [
//                         -11.57958984375,
//                         51.713416052417614
//                     ],
//                     [
//                         -11.337890625,
//                         51.31688050404585
//                     ],
//                     [
//                         -10.8984375,
//                         51.07591977545679
//                     ],
//                     [
//                         -9.68994140625,
//                         50.534380406110806
//                     ],
//                     [
//                         -8.06396484375,
//                         49.97242235423708
//                     ],
//                     [
//                         -6.6796875,
//                         49.653404588437894
//                     ]
//                 ]
//             ]
//         }
//     }]
// };


export default class MapView extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            lat: 50.444278,
            lng: -4.074625,
            zoom: 6,
            minZoom: 6,
        }
    }

    componentDidMount() {
        this.getSites();
    }

    getSites = () => {
        //Get all sites for this account
        dataService.getAllSites()
            .then((sites) => {
                this.getSiteReadings(sites);
            })
            .catch((error) => {
                console.log("Catching error");
                console.log(error);
                this.setState({ error: error });
            })
    }

    getSiteReadings = (sites) => {
        var allPromises = Dexie.Promise.all;

        let siteReadings = sites.map(site => {
            //Run function to get back worst readings for each site
            return processService.getSiteCardReadings(site)
        })

        return allPromises(siteReadings)
            .then(allReadings => {
                this.createMarkers(sites, allReadings);
            })
    }

    createMarkers = (sites, readings) => {
        let greenMarkers = [], amberMarkers = [], redMarkers = [];

        // const southWest = Leaflet.latLng(52.05835433, -0.3295716), northeast = Leaflet.latLng(61.173758, 0.797381), bounds = Leaflet.latLngBounds(southWest, northeast);

        sites.forEach((site, i) => {
            // WHY
            // const siteReadings = readings.filter((reading) => reading.siteId === site.id);

            let icon, tooltip = site.name, lat = site.latitude, long = site.longitude, position = [lat, long];

            //Depending on alert level assign each site a coloured marker
            if (readings[i].some(reading => (reading.alertLevel > 1))) {
                icon = LocationRed;
                redMarkers.push(<Marker position={position} icon={icon}>
                    <Popup
                        className='redPopup'
                        offset={[320, -20]}
                    ><SiteCard site={site} /></Popup>
                    <Tooltip>{tooltip}</Tooltip>
                </Marker>)
            } else if (readings[i].some(reading => (reading.alertLevel === 1))) {
                icon = LocationAmber;
                amberMarkers.push(<Marker position={position} icon={icon}>
                    <Popup className='amberPopup' offset={[320, -20]}><SiteCard site={site} /></Popup>
                    <Tooltip>{tooltip}</Tooltip>
                </Marker>)
            } else {
                icon = LocationGreen;
                greenMarkers.push(<Marker position={position} icon={icon}>
                    <Popup className='greenPopup' offset={[320, -20]}><SiteCard site={site}  /></Popup>
                    <Tooltip>{tooltip}</Tooltip>
                </Marker>)
            }
        });

        this.setState({ greenMarkers: greenMarkers, amberMarkers: amberMarkers, redMarkers: redMarkers, isLoading: false })

    }

    render() {
        Mixpanel.track('Map view');
        //Failed attempt to add a mask to the map to hide all but the UK

        // credits: https://github.com/turban/Leaflet.Mask
        // Leaflet.Mask = Leaflet.Polygon.extend({
        //     options: {
        //         stroke: false,
        //         color: '#AAD3DF',
        //         fillOpacity: 1.0,
        //         clickable: true,

        //         outerBounds: new Leaflet.LatLngBounds([-90, -360], [90, 360])
        //     },

        //     initialize: function (latLngs, options) {

        //         var outerBoundsLatLngs = [
        //             this.options.outerBounds.getSouthWest(),
        //             this.options.outerBounds.getNorthWest(),
        //             this.options.outerBounds.getNorthEast(),
        //             this.options.outerBounds.getSouthEast()
        //         ];
        //         Leaflet.Polygon.prototype.initialize.call(this, [outerBoundsLatLngs, latLngs], options);
        //     },

        // });
        // Leaflet.mask = function (latLngs, options) {
        //     return new Leaflet.Mask(latLngs, options);
        // };

        // var coordinates = UK.features[0].geometry.coordinates[0];
        // var latLngs = [];
        // for (let i = 0; i < coordinates.length; i++) {
        //     latLngs.push(new Leaflet.LatLng(coordinates[i][1], coordinates[i][0]));
        // }

        // Leaflet.mask(latLngs).addTo("map");

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="map-spinner" />
                </div>
                :
                <div>
                    <Map center={[54.211033, -4.544794]} zoom={this.state.zoom} >
                        {/* <ZoomControl position="bottomleft"/> */}
                        <LayersControl position="topright" collapsed={false}>
                            <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.osm.org/{z}/{x}/{y}.png' minZoom={this.state.minZoom} />
                            <Overlay name="Green" checked="true">
                                <LayerGroup>
                                    {this.state.greenMarkers}
                                </LayerGroup>
                            </Overlay>
                            <Overlay name="Amber" checked="true">
                                <LayerGroup>
                                    {this.state.amberMarkers}
                                </LayerGroup>
                            </Overlay>
                            <Overlay name="Red" checked="true">
                                <LayerGroup>
                                    {this.state.redMarkers}
                                </LayerGroup>
                            </Overlay>
                        </LayersControl>
                        {/* <GeoJSON data={UK} style={{ fillColor: 'green', fillOpacity: 0.5 }} /> */}
                    </Map>
                </div>
        );
    }
}
