import { cacheService } from './cache.service';
import Dexie from 'dexie';

export const dataService = {
    //SITES//
    getAllSites,
    getSiteById,
    //ROOMS//
    getAllRooms,
    getRoomById,
    getRoomsBySiteId,
    //SENSORS//
    getAllSensors,
    getSensorsByRoomId,
    getSensorsBySiteId,
    //READINGS//
    getAllReadings,
    getReadingsBySensorId,
    getReadingsByRoomId,
    getReadingsBySiteId,
    //ASSETS//
    getAllAssets,
    //DEFECTS//
    getAllDefects,
    //TILES//
    getAllTiles,
    getTilesByLocationId,
}

//-----BEGIN SITES-----//

function getAllSites() {
    return new Promise((resolve, reject) => {
        cacheService.db.sites.toArray()
            .then(allSites => {
                resolve(allSites);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getSiteById(siteId) {
    return new Promise((resolve, reject) => {
        cacheService.db.sites.where("id").equals(siteId).first()
            .then(site => {
                resolve(site);
            })
            .catch(error => {
                reject(error);
            })
    })
}

//-----END SITES-----//

//-----BEGIN ROOMS-----//

function getAllRooms() {
    return new Promise((resolve, reject) => {
        cacheService.db.rooms.toArray()
            .then(allRooms => {
                resolve(allRooms);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getRoomById(roomId) {
    return new Promise((resolve, reject) => {
        cacheService.db.rooms.where("id").equals(roomId).first()
            .then(room => {
                resolve(room);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getRoomsBySiteId(siteId) {
    return new Promise((resolve, reject) => {
        cacheService.db.rooms.where("site_Id").equals(siteId).toArray()
            .then(roomsBySite => {
                resolve(roomsBySite);
            })
            .catch(error => {
                reject(error);
            })
    })
}

//-----END ROOMS-----//

//-----BEGIN SESNORS-----//

function getAllSensors() {
    return new Promise((resolve, reject) => {
        cacheService.db.sensors.toArray()
            .then(allSensors => {
                resolve(allSensors);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getSensorsByRoomId(roomId) {
    return new Promise((resolve, reject) => {
        cacheService.db.sensors.where("roomId").equals(roomId).toArray()
            .then(sensorsByRoom => {
                resolve(sensorsByRoom);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getSensorsBySiteId(siteId) {
    var allPromises = Dexie.Promise.all;

    //Get the rooms that match the site id we pass in
    return cacheService.db.rooms.where("site_Id").equals(siteId).toArray(rooms => {
        //Foreach every room for that site
        let roomSensors = rooms.map(room => {
            //Then return all sensors that match the room id
            return cacheService.db.sensors.where("roomId").equals(room.id).toArray();
        })

        return allPromises(roomSensors)
            .then(allSensors => {
                return allSensors;
            })
    })
}

//-----END SESNORS-----//

//-----BEGIN READINGS-----//

function getAllReadings() {
    return new Promise((resolve, reject) => {
        cacheService.db.readings.toArray()
            .then(allReadings => {
                resolve(allReadings);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getReadingsBySensorId(sensorId) {
    return new Promise((resolve, reject) => {
        cacheService.db.readings.where("sensorId").equals(sensorId).toArray()
            .then(readingsBySensor => {
                resolve(readingsBySensor);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getReadingsByRoomId(roomId) {
    var allPromises = Dexie.Promise.all;

    //Get all sensors for a room
    return cacheService.db.sensors.where("roomId").equals(roomId).toArray(sensors => {

        //Get all readings for each sensor
        let sensorReadings = sensors.map(sensor => {
            return cacheService.db.readings.where("sensorId").equals(sensor.sensorId).toArray()
        })
        return allPromises(sensorReadings)
            .then(allReadings => {
                return allReadings;
            })
    })
}

function getReadingsBySiteId(siteId) {
    var allPromises = Dexie.Promise.all;

    //Get the rooms for a site id
    return cacheService.db.rooms.where("site_Id").equals(siteId).toArray(rooms => {

        //Get all sensors for those rooms
        let roomSensors = rooms.map(room => {
            return cacheService.db.sensors.where("roomId").equals(room.id).toArray(sensors => {

                //Get all readings for those sensors
                let sensorReadings = sensors.map(sensor => {
                    return cacheService.db.readings.where("sensorId").equals(sensor.sensorId).toArray()
                })
                return allPromises(sensorReadings);
            });
        })

        return allPromises(roomSensors)
            .then(allReadings => {
                return allReadings;
            })
    })
}


//------END READINGS-----//

//------BEGIN ASSETS-----//

function getAllAssets() {
    return new Promise((resolve, reject) => {
        cacheService.db.assets.toArray()
            .then(allAssets => {
                resolve(allAssets);
            })
            .catch(error => {
                reject(error);
            })
    })
}

//------END ASSETS-----//

//------BEGIN DEFECTS-----//

function getAllDefects() {
    return new Promise((resolve, reject) => {
        cacheService.db.defects.toArray()
            .then(allDefects => {
                resolve(allDefects);
            })
            .catch(error => {
                reject(error);
            })
    })
}

//------END DEFECTS-----//

//------START TILES------//

function getAllTiles() {
    return new Promise((resolve, reject) => {
        cacheService.db.tiles.toArray()
            .then(allTiles => {
                resolve(allTiles);
            })
            .catch(error => {
                reject(error);
            })
    })
}

function getTilesByLocationId(siteId, roomId) {
    return new Promise((resolve, reject) => {
        //Pass in either a site or a room id
        if (siteId !== undefined) {
            //Get all the rooms for the site
            this.getRoomsBySiteId(siteId).then(rooms => {
                var roomids = rooms.map(room => {
                    return room.id;
                })
                Promise.all(roomids).then(roomids => {
                    //Return all the tiles that match any of the room ids
                    cacheService.db.tiles.where('roomId').anyOf(roomids).toArray().then(tiles => {
                        return resolve(tiles);
                    })
                        .catch(error => {
                            reject(error);
                        })
                })
            })
        } else if (roomId !== undefined) {
            //Get tiles by room id
            cacheService.db.tiles.where('roomId').equals(roomId).toArray().then(tiles => {
                return resolve(tiles);
            })
                .catch(error => {
                    reject(error);
                })
        }
    })
}

//------END TILES------//