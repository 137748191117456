import React from 'react';

import { ReactComponent as LoneWorkerSVG } from '../../../icons/LoneWorker.svg';

export default class OtherTile extends React.Component {
    constructor() {
        super()

        this.state = {
            data: "",
        }
    }

    componentDidMount() {
        //Grab the number of times a reading returns true for a total activations count
        let motionData = this.props.data.filter((reading) => (reading.value === "True")).length;
        this.setState({ data: motionData });
    }

    render() {
        return(
            <div className = "motion-data">
                <h2>{this.state.data}</h2>
                <div className = "svg-div">
                <LoneWorkerSVG width = {30} /> <br />
                </div>
                MOTION EVENTS
            </div>
            )        
    }
}