import React from 'react';

import { datetimeformatterService } from '../../../services/datetimeformatter.service';


export default class DetailLuxTotal extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            dailyTime: "",
        }
    }

    componentDidMount() {
        this.calculations(this.props.readings)
    }

    calculations = (readingss) => {
        let readings = readingss.slice();

        //If i > 5 and i+1 > 5 then keep going. Measure end when current < 5 ()
        let start = null, end = null, total = 0, endChanged = false, segments = [];
        for (let i = 0; i < readings.length; i++) {
            const currentValue = parseInt(readings[i].value);

            //Beginning start value
            if (currentValue > 5 && start === null) {
                start = i;
            } else if (readings[i] !== undefined && readings[i - 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i - 1].value) < 5) {
                    start = i;
                }
            }

            //This is the end of a section of lights on
            if (readings[i] !== undefined && readings[i + 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i + 1].value) <= 5) {
                    end = i;
                    endChanged = true;
                }
            }

            //Once it reaches the end of the array, check if it needs to end
            if (readings[i + 1] === undefined && currentValue > 5) {
                end = i;
                endChanged = true;
            } else if (readings[i + 1] === undefined && parseInt(readings[i - 1].value) > 5) {
                end = i - 1;
                endChanged = true;
            }

            //Get the dates from milliseconds from start and end, end-start and add to running total 
            if (endChanged === true && end > start) {
                const startTime = readings[start].readingTime;
                const endTime = readings[end].readingTime;
                const segment = endTime - startTime;

                segments.push(segment);

                total += segment;

                endChanged = false;
            }
        }


        //convert milliseconds back to useable time
        const time = datetimeformatterService.convertMilliseconds(total);

        //Add x days worth of hours onto hours
        if (time.day !== undefined) {
            time.hour = time.hour + (time.day * 60);
        }

        //Get dates into a more readable format
        const startDate = datetimeformatterService.formatDate(readings[0].readingTime);
        const endDate = datetimeformatterService.formatDate(readings[readings.length - 1].readingTime);

        this.setState({ totalTime: time, startDate: startDate, endDate: endDate, isLoading: false });
    }

    render() {
        return (
            this.state.isLoading ?
                ""
                :
                <div className="lux-total">
                    <h3>TOTAL TIME ON</h3>
                    <p><strong>{this.state.totalTime.hour}</strong>hrs <strong>{this.state.totalTime.minute}</strong>mins</p>
                    <h4>{this.state.startDate} - {this.state.endDate}</h4>
                </div>
        )
    }
}