import React from 'react';
import { Col } from 'reactstrap';

export default class DetailAirQualityLiveReading extends React.Component {

    render() {
        const { reading } = this.props;

        //split into PM values
        const splitValues = reading.value.split('|');

        var styles;

        if (reading.alertLevel === 1) {
            styles = {
                //Amber
                backgroundColor: "#F4A105",
                color: "#FFF"
            }
        } else if (reading.alertLevel > 1) {
            styles = {
                //Red
                backgroundColor: "#FF0000",
                color: "#FFF"
            }
        } else {
            styles = {
                //Green
                backgroundColor: "#8CC63F",
                color: "#FFF"
            }
        }

        return (
            <Col lg={12} style={styles} className={`live-reading text-center`}>
                <h4>LIVE READING</h4>

                <h2>
                PM<sub>1 </sub>{splitValues[0]} {reading.unit} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                PM<sub>2.5 </sub>{splitValues[1]} {reading.unit} &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; 
                PM<sub>10 </sub>{splitValues[2]} {reading.unit}
                </h2>
            </Col>
        )
    }
}
