import React from 'react';

export default class OtherTile extends React.Component {
    constructor() {
        super()

        this.state = {
            data: "",
        }
    }

    componentDidMount() {
        //We assume this works, never got a sensor live to test
        const data = this.props.data;
        const length = data.length;
        const currentReading = data[length - 1].value + " WATER DETECTION";
        this.setState({data: currentReading});
    }

    render() {
        
        return(
            <div className = "no-data">
                {this.state.data}
            </div>
            )
    }
}