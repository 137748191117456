import React from 'react';
import { Alert, Spinner, Col, Row } from 'reactstrap';

import { ReactComponent as Tick } from '../../icons/Tick.svg';

export default class LoginProccess extends React.Component {
    constructor() {
        super()

        this.state = {
            gettingSites: true,
            gotSites: false,
            gettingRooms: false,
            gotRooms: false,
            gettingSensors: false,
            gotSensors: false,
            gettingReadings: false,
            gotReadings: false,
        }
    }

    componentDidMount() {
        //Outputs current cache filling stage
        switch(this.props.cacheState.status) {
            case "Getting rooms...":
                this.setState({ gotSites: true });
                break;
            case "Getting sensors...":
                this.setState({ gotSites: true, gotRooms: true });
                break;
            case "Getting readings...":
                this.setState({ gotSites: true, gotRooms: true, gotSensors: true });
                break;
            default:
                break;
        }
    }

    render() {

        const { gotSites, gotRooms, gotSensors, gotReadings } = this.state;

        return (
            <div>
                <Alert fade={false} color={ gotSites ? "success" : "secondary" } className="login-loading-info">
                    <Row>
                        <Col xl={1} />
                        <Col xl={8} className="text-left">
                            { gotSites ? "Got sites!" : "Getting sites..." }
                        </Col>
                        <Col xl={2} className="text-right">
                            { gotSites ? <Tick style={{ fill: '#0B2E13'}} width={20} /> : <Spinner size="sm" /> }
                        </Col>
                        <Col xl={1} />
                    </Row>
                </Alert>
                <Alert fade={false} color={ gotRooms ? "success" : "secondary" } className="login-loading-info">
                    <Row>
                        <Col xl={1} />
                        <Col xl={8} className="text-left">
                            { gotRooms ? "Got rooms!" : "Getting rooms..." }
                        </Col>
                        <Col xl={2} className="text-right">
                            { gotRooms ? <Tick width={20} /> : <Spinner size="sm" /> }
                        </Col>
                        <Col xl={1} />
                    </Row>
                </Alert>
                <Alert fade={false} color={ gotSensors ? "success" : "secondary" } className="login-loading-info">
                    <Row>
                        <Col xl={1} />
                        <Col xl={8} className="text-left">
                            { gotSensors ? "Got sensors!" : "Getting sensors..." }
                        </Col>
                        <Col xl={2} className="text-right">
                            { gotSensors ? <Tick width={20} /> : <Spinner size="sm" /> }
                        </Col>
                        <Col xl={1} />
                    </Row>
                </Alert>
                <Alert fade={false} color={ gotReadings ? "success" : "secondary" } className="login-loading-info">
                    <Row>
                        <Col xl={1} />
                        <Col xl={8} className="text-left">
                            { gotReadings ? "Got readings!" : "Getting readings..." }
                        </Col>
                        <Col xl={2} className="text-right">
                            { gotReadings ? <Tick width={20} /> : <Spinner size="sm" /> }
                        </Col>
                        <Col xl={1} />
                    </Row>
                </Alert>
            </div>
        );
    }
}