import React from 'react'
import { apiService } from './api.service';
import { cacheService } from './cache.service';
import { dataService } from './data.service';

import { ReactComponent as RecentEvents } from '../icons/RecentEvents.svg';
import { ReactComponent as NetworkSecurity } from '../icons/NetworkSecurity.svg';
import { ReactComponent as AssetList } from '../icons/AssetList.svg';
import { ReactComponent as WorkHistory } from '../icons/WorkHistory.svg';
import { ReactComponent as CurrentDefects } from '../icons/CurrentDefects.svg';
import { ReactComponent as Alerts } from '../icons/Alerts.svg';
import { ReactComponent as TestSensors } from '../icons/TestSensors.svg';
import { ReactComponent as PartyMode } from '../icons/PartyMode.svg';
import { ReactComponent as StreamAudio } from '../icons/StreamAudio.svg';
import { ReactComponent as StreamVideo } from '../icons/StreamVideo.svg';
import { ReactComponent as CallBox } from '../icons/CallBox.svg';

export const tileService = {
    createTileData,
    getMiniTiles,
}

function createTileData() {
    return new Promise(resolve => {
        dataService.getAllSensors().then(sensors => {
            //Take the sensors and get the correct data by type
            let sensorInfo = sensors.map(sensor => {
                return filterTypes(sensor);
            })

            //Check for assets with sensor ids
            Promise.all(sensorInfo).then(sensorInfo => {
                apiService.getData("/asset/hassensor").then(assets => {
                    sensorInfo.forEach((sensor, i) => {
                        assets.forEach((asset, j) => {

                            //If a sensor id does match a sensor and an asset then add/edit some of it's values
                            if (sensor.sensorId === asset.sensorId) {
                                sensor.isGraph = false;
                                sensor.isSensor = false;
                                sensor.manufacturer = asset.manufacturer;
                                sensor.model = asset.model;
                                sensor.serialNumber = asset.serialNumber;
                                sensor.assetId = asset.id;
                                sensor.assetType = asset.type;
                                sensor.sensorName = sensor.name;
                                sensor.name = asset.name;
                            }
                        });
                    });

                    //Fill the cache with the data
                    cacheService.fillTileCache(sensorInfo).then((data) => {
                        resolve(data);
                    })
                })
            })
        })
    })
}



//Switch case to set for the various sensors
const filterTypes = (sensor) => {
    return new Promise(resolve => {

        dataService.getReadingsBySensorId(sensor.sensorId)
            .then(readings => {

                var sensorDisplayInfo = {
                    sensorId: 0,
                    type: "",
                    isGraph: true,
                    headColour: "",
                    bodyColour: "",
                    icon: "",
                    isMini: false,
                    isSensor: true,
                    alertLevel: "",
                    unit: "",
                };

                //If array not empty then sort by date
                if (readings.length > 0) {
                    readings.sort(function (a, b) {
                        var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                        return dateA - dateB;
                    });

                    sensorDisplayInfo.alertLevel = readings[readings.length - 1].alertLevel;
                }

                //Set some of the sensorDisplayInfo
                sensorDisplayInfo.type = sensor.type;
                sensorDisplayInfo.sensorId = sensor.sensorId;
                sensorDisplayInfo.name = sensor.name;
                sensorDisplayInfo.roomId = sensor.roomId;

                // sensorDisplayInfo.siteId = "";
                // sensorDisplayInfo.siteName = locationInfo.siteName;
                // sensorDisplayInfo.siteAddress = locationInfo.siteAddress;

                //Switch on the sensor type for the rest of the sensorDisplayInfo
                switch (sensor.type) {
                    case ("HUMIDITY"):
                        sensorDisplayInfo.icon = '/icons/Humidity.svg';
                        sensorDisplayInfo.headColour = "#14A9FF";
                        sensorDisplayInfo.bodyColour = "#0095EA";
                        sensorDisplayInfo.unit = "%";
                        break;
                    case ("TEMPERATURE"):
                        sensorDisplayInfo.icon = '/icons/Temperature.svg';
                        sensorDisplayInfo.headColour = "#931BA8";
                        sensorDisplayInfo.bodyColour = "#EA01FC";
                        sensorDisplayInfo.unit = "°C";
                        break;
                    case ("L8"):
                        sensorDisplayInfo.icon = '/icons/L8Sensor.svg';
                        sensorDisplayInfo.headColour = "#2057B1";
                        sensorDisplayInfo.bodyColour = "#2D6FDB";
                        sensorDisplayInfo.unit = "°C";
                        break;
                    case ("LIGHT"):
                        sensorDisplayInfo.icon = '/icons/Lux.svg';
                        sensorDisplayInfo.headColour = "#270039";
                        sensorDisplayInfo.bodyColour = "#3C0057";
                        sensorDisplayInfo.unit = "LUX";
                        break;
                    case ("CO LEVEL"):
                        sensorDisplayInfo.icon = '/icons/CO.svg';
                        sensorDisplayInfo.headColour = "#47415C";
                        sensorDisplayInfo.bodyColour = "#35333C";
                        sensorDisplayInfo.unit = "ppm";
                        sensorDisplayInfo.isGraph = false;
                        break;
                    case ("SOUND"):
                        // sensorDisplayInfo.icon = "";
                        sensorDisplayInfo.headColour = "#2C2933";
                        sensorDisplayInfo.bodyColour = "#423F4A";
                        sensorDisplayInfo.unit = "dB";
                        break;
                    case ("AIR QUALITY"):
                        sensorDisplayInfo.icon = '/icons/AirQuality.svg';
                        sensorDisplayInfo.headColour = "#2E0369";
                        sensorDisplayInfo.bodyColour = "#1D063B";
                        sensorDisplayInfo.unit = "µg/m³";
                        sensorDisplayInfo.isGraph = false;
                        break;
                    case ("WATER LEVEL"):
                        sensorDisplayInfo.icon = '/icons/WaterLevel.svg';
                        sensorDisplayInfo.headColour = "#423F4A";
                        sensorDisplayInfo.bodyColour = "#2C2933";
                        sensorDisplayInfo.unit = "cm";
                        sensorDisplayInfo.isGraph = false;
                        break;
                    case ("WATER ROPE"):
                        // sensorDisplayInfo.icon = "";
                        sensorDisplayInfo.headColour = "#423F4A";
                        sensorDisplayInfo.bodyColour = "#2C2933";
                        sensorDisplayInfo.unit = "";
                        sensorDisplayInfo.isGraph = false;
                        break;
                    // case ("CO TWA LEVEL"):
                    //     sensorDisplayInfo.icon = '/icons/CO.svg';
                    //     sensorDisplayInfo.headColour = "#47415C";
                    //     sensorDisplayInfo.bodyColour = "#35333C";
                    //     break;
                    case ("PIR"):
                        sensorDisplayInfo.icon = '/icons/PIR.svg';
                        sensorDisplayInfo.headColour = "#AB27C3";
                        sensorDisplayInfo.bodyColour = "#931BA8";
                        sensorDisplayInfo.unit = "";
                        sensorDisplayInfo.isGraph = false;
                        break;
                    default:
                        break;
                }

                return resolve(sensorDisplayInfo);
            })
    })
}

//Store mini tiles here
function getMiniTiles() {
    let size = 35;

    const firstSet = [
        { name: "RECENT EVENTS", icon: <RecentEvents width={size} height={size} />, colour: "#77B3CC" },
        { name: "NETWORK SECURITY", icon: <NetworkSecurity width={size} height={size} />, colour: "#727D8E" },
        { name: "ASSETS", icon: <AssetList width={size} height={size} />, colour: "#2D3B52" },
        { name: "WORK HISTORY", icon: <WorkHistory width={size} height={size} />, colour: "#4E4166" },
        { name: "DEFECTS", icon: <CurrentDefects width={size} height={size} />, colour: "#1A345C" },
        { name: "ALERTS", icon: <Alerts width={size} height={size} />, colour: "#2EABDE" },
    ]

    const secondSet = [
        // {name: "TEST", icon: <TestSensors width={35} />, colour: "#807C9D" },
        { name: "TEST SENSORS", icon: <TestSensors width={size} height={size} />, colour: "#CF32EB" },
        { name: "PARTY MODE", icon: <PartyMode width={size} height={size} />, colour: "#4246BF", modalRef: "partyMode" },
        { name: "STREAM AUDIO", icon: <StreamAudio width={size} height={size} />, colour: "#403757", modalRef: "liveFeed" },
        { name: "STREAM VIDEO", icon: <StreamVideo width={size} height={size} />, colour: "#50A1B6", modalRef: "liveFeed" },
        { name: "CALL BOX", icon: <CallBox width={size} height={size} />, colour: "#8C4FA7", modalRef: "callBox" },
    ]

    const miniTiles = [firstSet, secondSet];

    return miniTiles;
}