import React from 'react';
import DataTable from 'react-data-table-component';
import { datetimeformatterService } from '../../services/datetimeformatter.service';

import Battery from './Battery';
import Signal from './Signal';

export default class DetailTable extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            interval: 600000,
        }
    }

    componentDidMount() {
        const { readings, interval } = this.props;

        let readingCopy = JSON.parse(JSON.stringify(readings));

        const displayReadings = readingCopy.reverse();
        displayReadings.forEach(reading => {
            reading = this.parseReading(reading);
        });

        this.setState({ displayReadings: displayReadings, interval: interval, isLoading: false })
    }

    handleInterval() {
        this.setState({ isLoading: true })

        let { interval, readings } = this.props;

        //Get a copy of the readings array and reverse it
        let displayReadings = JSON.parse(JSON.stringify(readings));
        displayReadings.reverse()

        //Default 10 minutes
        if (interval === undefined || interval === 600000) {
            displayReadings.forEach(reading => {
                reading = this.parseReading(reading);
            });

            this.setState({ displayReadings: displayReadings, interval: interval, isLoading: false })
        } else {
            let intervalReadings = [], prevReading;

            //Interval margin of error
            let intervalMargin = interval - 200;

            //go through each reading and check if it's in the interval range
            displayReadings.forEach(reading => {
                //get ms value of current reading
                var currentReading = new Date(reading.readingTime).getTime();

                //Get first reading else check within interval range
                if (intervalReadings.length === 0) {
                    prevReading = new Date(reading.readingTime).getTime();

                    reading = this.parseReading(reading);

                    intervalReadings.push(reading)

                } else if (currentReading <= (prevReading - intervalMargin)) {
                    prevReading = new Date(reading.readingTime).getTime();

                    reading = this.parseReading(reading);

                    intervalReadings.push(reading)
                }
            });

            this.setState({ displayReadings: intervalReadings, interval: interval, isLoading: false })
        }
    }

    parseReading(reading) {
        //Convert reading time back into an object (lost when taking a copy)
        reading.readingTime = datetimeformatterService.formatDateTime(new Date(reading.readingTime));

        //Check for CO to make sure we remove the split
        if (reading.type === "CO LEVEL") {
            const splits = reading.value.split("|");
            reading.value = splits[0];
        }

        //Get the signal and battery icons instead of an int
        reading.signalStrength = <Signal width={20} value={reading.signalStrength} />;
        reading.batteryLevel = <Battery width={30} value={reading.batteryLevel} />;

        return reading;
    }

    getColumns() {
        let units = this.props.readings[0].unit;

        const columns = [
            {
                name: units,
                selector: 'value',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Date/Time',
                selector: 'readingTime',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Signal',
                selector: 'signalStrength',
                sortable: false,
                grow: 1,
            },
            {
                name: "Battery",
                selector: "batteryLevel",
                sortable: false,
                grow: 1,
            }
        ];

        return columns;
    }

    render() {
        const { displayReadings, isLoading } = this.state;
        const columns = this.getColumns();

        if (this.props.interval !== this.state.interval && isLoading === false) {
            this.handleInterval();
        }

        return (
            isLoading ?
                ""
                :
                <div className="readings-table-body">
                    <DataTable
                        columns={columns}
                        data={displayReadings}
                        striped
                        dense
                        highlightOnHover
                        pagination
                        noHeader
                        fixedHeader
                        paginationPerPage={12}
                        paginationRowsPerPageOptions={[12, 30, 50, 100, 500]}
                    />
                </div>
        );
    }
}