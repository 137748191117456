import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Form, Input, Label, FormGroup } from 'reactstrap';
import { authService } from '../../services/auth.service';
import { cacheService } from '../../services/cache.service';
import { twilioService } from '../../services/twilio.service';
import LoginProcess from './LoginProcess';

import './login.scss';

import AHU from '../../images/AHU.png';
import Logo from '../../images/MetlLogoBottom.png';
import { ReactComponent as SadFace } from '../../icons/SadFace.svg';
import { Mixpanel } from '../../Mixpanel';

const Registration =() =>{

    

    

    

    
        return (
            <Row noGutters>
                <Col xl={{ size: 10, offset: 1 }} className="login-wrapper clearfix col-xxl-8 offset-xxl-2">
                    <Col xl={6} className="float-left login-image">
                        <img src={AHU} width={600} alt="Air handling unit" />
                    </Col>
                    <Col xl={6} className="float-right content-wrapper">
                        <Col xl={12} className="login-brand text-center">
                            <img src={Logo} width={200} alt={"Logo"} style={{ verticalAlign: "baseline" }} className="" />
                            DASHBOARD
                        </Col>
                        <br />
                        <br />
                        
                            
                        <React.Fragment>
                            <div style={{ marginTop: '-2rem' }}>
                                    <div className="register-link">Registration Form</div>
                                    <Form className="login-form" onSubmit={(ev) => this.login(ev)}>
                                        <Row>
                                    {/* First Column */}
                                    <Col>
                                        <FormGroup>
                                                <Label for="firstName">First Name</Label>
                                            <Input type="text" className="form-control" name="firstName" id="firstName" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input type="email" className="form-control" name="email" id="email" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="firstName">User Name</Label>
                                            <Input type="text" className="form-control" name="username" id="firstName" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">Confirm Password</Label>
                                            <Input type="password" className="form-control" name="password" id="password" />
                                        </FormGroup>
                                    </Col>

                                    

                                    <Col>
                                        <FormGroup>
                                            <Label for="username">Last Name</Label>
                                            <Input type="text" className="form-control" name="username" id="username" />
                                        </FormGroup>
                                        <FormGroup>
                                        <Label for="phone">Mobile Phone</Label>
                                            <Input type="tel" className="form-control" name="phone" id="phone" />
                                        </FormGroup>
                                        <FormGroup>
                                        <Label for="password">Password</Label>
                                            <Input type="password" className="form-control" name="password" id="password" />
                                            </FormGroup>
                                        </Col>
                                </Row>



                                
                                {/* Third Column */}
                                

                              

                                      
                                        <br />
                                        <br />
                                        <Input type="submit" className="btn btn-primary" value="Register" />
                                </Form>
                                </div>

                                </React.Fragment>
                        
                    </Col>
                </Col>
            </Row>
        );
    }
export default Registration;