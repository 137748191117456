import React from 'react';

export default class DetailPirMostActiveDay extends React.Component {

    render () {

         //Very simple class to return most active day as calculated in detailPirCalculations class
        return (
            <div className="most-active-day">
                <h3>MOST <br /> ACTIVE DAY</h3>
                <p><strong>{this.props.value}</strong></p>
            </div>
        )
    }
}