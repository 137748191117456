import React from 'react';
import DataTable from 'react-data-table-component';
import { history } from '../../../services/history.service';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { cacheService } from '../../../services/cache.service';
import { Mixpanel } from '../../../Mixpanel';
import Notes from '../notes/Notes';

import { ReactComponent as  CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as  AssetList } from '../../../icons/AssetList.svg';

import './Assets.scss';

export default class Assets extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            rooms: [],
        }
    }

    componentDidMount() {
        //Get all assets from cache, if nothing cached then fill cache first
        dataService.getAllAssets()
            .then(assets => {
                if (assets.length === 0) {
                    cacheService.fillAssetDefectCache()
                        .then(() => {
                            dataService.getAllAssets()
                                .then(assets => {
                                    this.afterDataImport(assets)
                                })
                        })
                } else {
                    dataService.getAllAssets()
                        .then(assets => {
                            this.afterDataImport(assets)
                        })
                }
            })
    }

    getColumns() {
        const columns = [
            {
                name: 'Image',
                selector: 'thumbnailImage',
                sortable: false,
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
            },
            {
                name: "Manufacturer",
                selector: "manufacturer",
                sortable: true
            },
            {
                name: "Model",
                selector: "model",
                sortable: true,
            },
            {
                name: 'Serial/Part Number',
                selector: 'serialNumber',
                sortable: true,
            },
            {
                name: 'Location',
                selector: 'location',
                sortable: true,
            },
            {
                name: 'Condition',
                selector: 'assetCondition',
                sortable: true,
            },
        ];
        return columns;
    }

    afterDataImport = (assets) => {
        let data = [], rooms = [];
        const user = JSON.parse(localStorage.getItem("currentUser"))
        this.checkLocation()
            .then(roomNumbers => {
                rooms = roomNumbers;

                assets.forEach(asset => {
                    if (asset.account_Id === user.AccountId) {
                        if (asset.thumbnailImage !== null && asset.thumbnailImage !== "") {
                            asset.thumbnailImage = <img src={`data:image/;base64,${asset.thumbnailImage}`} style={{ height: "30px" }} alt="Thumbnail" />
                        }

                        if (rooms.includes(asset.room_Id)) {
                            data.push(asset)
                        }
                    }
                });
                this.setState({ data: data, isLoading: false })

            })
    }

    checkLocation = () => {
        //Check if you have site or room id, then get rooms numbers from either one

        return new Promise((resolve) => {
            if (this.props.location.state.site !== undefined) {
                dataService.getRoomsBySiteId(this.props.location.state.site)
                    .then(rooms => {
                        let roomNumbers = [];
                        var proms = rooms.map(room => {
                            return roomNumbers.push(room.id)
                        });

                        Promise.all(proms)
                            .then(
                                resolve(roomNumbers)
                            );
                    })
            } else if (this.props.location.state.room !== undefined) {
                dataService.getRoomById(this.props.location.state.room)
                    .then(room => {
                        dataService.getSiteById(room.site_Id)
                            .then(site => {
                                dataService.getRoomsBySiteId(site.id)
                                    .then(rooms => {

                                        let roomNumbers = [];
                                        var proms = rooms.map(room => {
                                            return roomNumbers.push(room.id)
                                        });

                                        Promise.all(proms)
                                            .then(
                                                resolve(roomNumbers)
                                            );
                                    })
                            })
                    })
            }
        })
    }

    handleAssetClick = (obj) => {
        obj.thumbnailImage = "";
        history.push({
            pathname: "/assetdetails",
            state: {
                site: this.props.location.state.site,
                room: this.props.location.state.room,
                asset: obj,
                update: true,
                siteAddress: this.props.location.state.siteAddress,
                siteName: this.props.location.state.siteName
            }
        })
    }

    render() {
        Mixpanel.track('Assets');
        const columns = this.getColumns();

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="asset-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<AssetList width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Assets
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {this.state.data.length > 0 ?
                        <React.Fragment>
                            <div className="table-div">
                                <Button className="new-asset" color="secondary" onClick={() => history.push({ pathname: "/assetdetails", state: { site: this.props.location.state.site, room: this.props.location.state.room, update: false, siteAddress: this.props.location.state.siteAddress, siteName: this.props.location.state.siteName } })}>Add new asset</Button>
                                <DataTable
                                    columns={columns}
                                    data={this.state.data}
                                    striped
                                    dense
                                    highlightOnHover
                                    pointerOnHover
                                    pagination
                                    paginationPerPage={15}
                                    paginationRowsPerPageOptions={[15, 30, 50, 100]}
                                    defaultSortField="assetCondition"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    onRowClicked={(obj) => this.handleAssetClick(obj)}
                                />
                            </div>
                            <Notes type="Assets" />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button className="new-asset" color="secondary"
                                onClick={() => history.push({
                                    pathname: "/assetdetails",
                                    state: {
                                        site: this.props.location.state.site,
                                        room: this.props.location.state.room,
                                        update: false,
                                        siteAddress: this.props.location.state.siteAddress,
                                        siteName: this.props.location.state.siteName
                                    }
                                })}>Add new asset</Button>
                            <div className="empty-alerts">NO ASSETS</div>
                        </React.Fragment>}
                </div>

        )
    }
}