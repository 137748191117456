import React, { Component } from 'react';
import { Route, Router, Redirect, Switch } from 'react-router-dom';
import { withSize } from 'react-sizeme';
import { history } from './services/history.service';
import { authService } from './services/auth.service';
import { cacheService } from './services/cache.service';
import { offlineClientTimout, twilioService } from './services/twilio.service';
import { Layout } from './components/Layout';
import Login from './components/login/Login';
import SitesMain from './components/sites/SitesMain';
import RoomsMain from './components/rooms/RoomsMain';
import Dashboard from './components/dashboard/Dashboard';
import Detail from './components/detail/Detail';
import MapView from './components/map/MapView';
import Alerts from './components/dashboard/alerts/Alerts';
import AlertDetails from './components/dashboard/alerts/AlertDetails';
import Assets from './components/dashboard/assets/Assets';
import AssetDetails from './components/dashboard/assets/AssetDetails';
import Defects from './components/dashboard/defects/Defects';
import DefectDetails from './components/dashboard/defects/DefectDetails';
import Contacts from './components/contacts/Contacts';
import ContactDetails from './components/contacts/ContactDetails';
import RecentEvents from './components/dashboard/events/RecentEvents';
import WorkHistory from './components/dashboard/workhistory/WorkHistory';
import TestSensors from './components/dashboard/testsensors/TestSensors';
import AdminLanding from './components/admin/AdminLanding';
import SiteAdmin from './components/admin/SiteAdmin';
import SpsAdmin from './components/admin/SpsAdmin';
import NetworkSecurity from './components/dashboard/networksecurity/NetworkSecurity';
import Terms from './components/login/Terms';
import Registration from './components/login/Registration';
// import PdfView from './components/pdf/PdfView';

import './components/styles/custom.scss';
import { intervalService } from './services/interval.service';
import { tileService } from './services/tile.service';

const withSizeHOC = withSize({ monitorHeight: true });
const ResponsiveDashboard = withSizeHOC(Dashboard);

const PrivateRoute = ({ component: Component, ...rest }) => {
    const currentUser = authService.currentUserValue;
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
    }

    // authorised so return component
    return <Component {...rest} />
}

export default class App extends Component {
    static displayName = App.name;

    constructor() {
        super();
        this.state = {
            currentUser: null,
            cacheState: false,
        };
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleUnload);

        authService.currentUser.subscribe(user => {
            this.setState({ currentUser: user });
        });

        cacheService.cacheLoaded.subscribe(cacheState => {
            if (cacheState.populated === true) {
                window.removeEventListener('beforeunload', this.handleUnload);

                const interval = 600000;
                //Runs the cache and tile refresh the first time
                if (localStorage.getItem("cacheInterval") === null) {
                    intervalService.setup(function() {
                        cacheService.updateCache();
                    }, interval);
                    intervalService.setup(function() {
                        tileService.createTileData();
                    }, interval);
                } else {
                    let lastRefresh = parseInt(localStorage.getItem("cacheInterval"));
                    //only run again if the current time is past the refresh
                    if(Date.now() > lastRefresh + interval + 10000) {
                        //Run once as past the interval
                        cacheService.updateCache();
                        tileService.createTileData();
                        
                        //Set the intervals again
                        intervalService.setup(function() {
                            cacheService.updateCache();
                        }, interval);
                        intervalService.setup(function() {
                            tileService.createTileData();
                        }, interval);
                    }
                }
            }
            this.setState({ cacheState: cacheState });
        });

        if (twilioService.device._status === "offline" && localStorage.getItem("currentUser") !== null) {
            twilioService.setupTwilio();
        }

        //Listen for visibility events, tell API twilio client has disconnected
        document.addEventListener("visibilitychange", () => {

            if (document.visibilityState === 'visible') {
                if (localStorage.getItem("TwilioIdentity") !== null) {
                    offlineClientTimout.stopTimer();
                } else {
                    twilioService.setupTwilio();
                }
            } else if (document.visibilityState === 'hidden') {
                offlineClientTimout.startTimer();
                //twilioService.registerOffline();
            } else {
                offlineClientTimout.startTimer();
            }
        });

        //Listen for an incoming connection
        twilioService.device.on('incoming', (conn) => {
            // window.focus(); //This doesn't work

            // console.log(conn.parameters)
            console.log("Incoming connection from " + conn.parameters.From);

            //Give option
            if (window.confirm("Incoming connection from " + conn.parameters.From)) {
                conn.accept();
            } else {
                conn.reject();
            }

            //Auto answer
            // conn.accept();
        });

    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleUnload);
    }

    handleUnload = () => {
        cacheService.deleteDb();
        authService.logout();
        history.push('/login');
    }

    logout() {
        cacheService.deleteDb();
        authService.logout();
        history.push('/login');
    }

    handleFilter = (alertLevel) => {
        this.setState({ alertLevel: alertLevel });
    }

    render() {

        const { currentUser, cacheState } = this.state;

        return (
            <Router history={history}>
                <Layout currentUser={currentUser} cacheState={cacheState} logout={() => this.logout()} filter={this.handleFilter}>
                    <Switch>
                        <Route path='/login' component={() => <Login currentUser={currentUser} cacheState={cacheState} />} />
                        <Route path='/register' component={Registration} />
                        <Route path='/terms' component={Terms} />
                        <Route path='/dashboard' component={ResponsiveDashboard} />
                        <Route path='/contacts' component={Contacts} />
                        <Route path='/map' component={MapView} />
                        <PrivateRoute path='/contactdetails' component={ContactDetails} />
                        <PrivateRoute path='/rooms/:id' component={(props) => <RoomsMain alert={this.state.alertLevel} id={props.computedMatch.params.id} />} />
                        <PrivateRoute path='/sites' component={() => <SitesMain alert={this.state.alertLevel} />} />
                        <PrivateRoute path='/detail' component={Detail} />
                        <PrivateRoute path='/alerts' component={Alerts} />
                        <PrivateRoute path='/alertdetails' component={AlertDetails} />
                        <PrivateRoute path='/assets' component={Assets} />
                        <PrivateRoute path='/assetdetails' component={AssetDetails} />
                        <PrivateRoute path='/defects' component={Defects} />
                        <PrivateRoute path='/defectdetails' component={DefectDetails} />
                        <PrivateRoute path='/recentevents' component={RecentEvents} />
                        <PrivateRoute path='/workhistory' component={WorkHistory} />
                        <PrivateRoute path='/testsensors' component={TestSensors} />
                        <PrivateRoute path='/admin' component={AdminLanding} />
                        <PrivateRoute path='/siteadmin' component={SiteAdmin} />
                        <PrivateRoute path='/spsadmin' component={SpsAdmin} />
                        <PrivateRoute path='/networksecurity' component={NetworkSecurity} />
                        {/* <PrivateRoute path='/pdfview' component={PdfView} /> */}
                        <PrivateRoute exact path='/' component={() => <SitesMain alert={this.state.alertLevel} />} />
                    </Switch>
                </Layout>
            </Router>
        );
    }
}
