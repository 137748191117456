import React from 'react'
import { Col, Row, Spinner } from 'reactstrap'
import { history } from '../../../services/history.service';

import { ReactComponent as NetworkSecurityIcon } from '../../../icons/NetworkSecurity.svg';
import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { Mixpanel } from '../../../Mixpanel';
import './NetworkSecurity.scss'

export default class NetworkSecurity extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        //Get users IP address and then do a lookup to find out geographical information
        const publicIp = require('public-ip');

        publicIp.v4().then(ip => {
            fetch('https://extreme-ip-lookup.com/json/' + ip)
                .then(response => response.json())
                .then(geo =>
                    this.setState({ ip: ip, geo: geo, gotData: true, isLoading: false })
                )
                .catch((e) => {
                    this.setState({ gotData: false, isLoading: false })
                })
        })
        .catch((e) => {
            this.setState({ gotData: false, isLoading: false })
        })
    }

    render() {
        Mixpanel.track('Network Security');
        const { ip, geo } = this.state;

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="network-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<NetworkSecurityIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Network Security
                    </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {this.state.gotData ?
                        <div className="network-body">
                            YOUR IP ADDRESS: {ip} <br /><br />
                          
                        </div>
                        :
                        <div className="network-body">
                            Unable to get IP or location data <br/><br/>

                            This could be your ad blocker!
                        </div>
                    }
                </div>

        )
    }
}