import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { connect } from 'twilio-video';
// import { twilioService } from '../../services/twilio.service';

import './Modal.scss';

const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzM0OGY3NWVmNTFjMmVhN2E3MWY3MTkzNjg3NjdiZGU0LTE2MTEwNzI0MDUiLCJpc3MiOiJTSzM0OGY3NWVmNTFjMmVhN2E3MWY3MTkzNjg3NjdiZGU0Iiwic3ViIjoiQUMwZTkwNmFhYmFhZmFmMTc0MzNjZTk1ZTBiZTJhZDNkMCIsImV4cCI6MTYxMTA3NjAwNSwiZ3JhbnRzIjp7ImlkZW50aXR5IjoiRGFzaGJvYXJkIHRlc3QiLCJ2aWRlbyI6e319fQ.9Sns3xyQ1h55PUqBU9OXP9OS77cg7vziguKcvxBQMIU";
let activeRoom;

export default class LiveFeed extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isLoading: false,
        }

    }

    componentDidMount() {
        
    }

    openRoom() {
        connect(token, {name: "new-room"}).then(room => {
            activeRoom = room;
            console.log("Successfully joined a room: " + activeRoom);
            activeRoom.on('participantConnected', participant => {
                console.log("A remote participant connected" + participant);
            })
        }, error => {
            console.error("Unable to connect to room: " + error.message)
        })
    }

    closeRoom() {
        // console.log(activeRoom)
        activeRoom.disconnect();
    }

    toggleModal(type) {
        this.setState({ isOpen: !this.state.isOpen, type: type });
    }


    render() {

        return(
            <Modal size="lg" isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="livefeed-modal">
                <ModalHeader toggle={() => this.toggleModal()}>
                    LIVE STREAM
                </ModalHeader>
                <ModalBody>
                 {/* <Button onClick={() => this.openRoom()}>OPEN ROOM</Button>
                 <Button onClick={() => this.closeRoom()}>CLOSE ROOM</Button> */}
                </ModalBody>
            </Modal>
        )
    }
}