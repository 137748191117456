import { apiService } from './api.service';

export const eventsService = {
    newEvent,
}

//Creates an event object to pass into the database
function newEvent(message, type, change) {
    return new Promise((resolve) => {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        let toDatabase = {
            timeLogged: new Date(),
            message: message,
            type: type,
            change: change,
            accountId: user.AccountId,
            username: user.username,
        }

        apiService.postData("/event", toDatabase)
            .then((event) => {
                resolve(event)
            })
    })
}