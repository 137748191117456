import React from "react";
import { Row, Col, Spinner, Input } from "reactstrap";
import { dataService } from "../../services/data.service";
// import { history } from '../../services/history.service';
import { processService } from "../../services/processing.service";
import { sortCards } from "../../services/sortcards.service";
import SiteCard from "./SiteCard";

import "./Sites.scss";

export default class SitesMain extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      error: false,
      rows: [],
      sites: undefined,
      sortBy: "A-Z",
    };
  }

  componentDidMount() {
    let cacheState = localStorage.getItem("cacheState");
    if (cacheState === null || cacheState.populated === "false") {
      // causes infinite loop crash
      // history.push("/login");
      // console.log("On sites without proper cacheState")
    }

    //If no button has been pressed or the all button has
    if (this.props.alert === undefined) {
      //Grab all sites and generate grid as normal
      dataService
        .getAllSites()
        .then((sites) => {
          this.setState({ flatCards: sites });
          this.generateGrid(sites);
        })
        .catch(() => {
          this.setState({ error: true });
        });
    } else {
      //Filter by alert value passed in before getting sites
      this.filterByAlert(this.props.alert).then((filtered) => {
        var maps = filtered.map((site) => {
          return dataService.getSiteById(site);
        });
        Promise.all(maps).then((sites) => {
          this.setState({ flatCards: sites });
          this.generateGrid(sites);
        });
      });
    }
  }

  filterByAlert = (alert) => {
    //This promise runs right at the end to return the filtered values
    return new Promise((resolve) => {
      return new Promise((resolve) => {
        //Loop over rooms for this site and then get the room card readings
        dataService.getAllSites().then((sites) => {
          return new Promise((resolve) => {
            var maps = sites.map((site, i) => {
              return processService.getSiteCardReadings(site);
            });

            Promise.all(maps).then((readings) => {
              resolve(readings);
            });
          }).then((readings) => {
            resolve(readings);
          });
        });
      }).then((sites) => {
        //Determine overall alert value and then return based on alert passed in
        var returns = sites.map((site) => {
          let overall = 0;
          if (site.some((sensor) => sensor.alertLevel > 1)) {
            overall = 2;
          } else if (site.some((sensor) => sensor.alertLevel === 1)) {
            overall = 1;
          }

          if (overall === alert) {
            return site;
          } else {
            return undefined;
          }
        });
        let filtered = [];
        Promise.all(returns).then((readings) => {
          readings.forEach((reading) => {
            if (reading !== undefined && reading.length > 0) {
              filtered.push(reading[0].id);
            }
          });
          resolve(filtered);
        });
      });
    }).then((filtered) => {
      return filtered;
    });
  };

  generateGrid = (flatCards, sortType) => {
    sortCards.sort(flatCards, sortType).then((flatCards) => {
      var rows = [];
      var cards = [];
      for (var i = 0; i < flatCards.length; i++) {
        cards.push(flatCards[i]);
        if (Number.isInteger((i + 1) / 4)) {
          //We need a new row
          rows.push(cards);
          cards = [];
        } else if (flatCards.length < 4 && i + 1 === flatCards.length) {
          rows.push(cards);
        }
      }
      var formattedCards = rows.reverse();

      this.setState({ isLoading: false, rows: formattedCards });
    });
  };

  handleSort(obj) {
    this.setState({ isLoading: true, sortBy: obj.target.value });

    this.generateGrid(this.state.flatCards, obj.target.value);
  }

    render() {
       
    const { error, isLoading, rows } = this.state;

    return (
      <div className="m-3">
        {error ? (
          <div>
            <h1>Error loading sites, please contact support</h1>
          </div>
        ) : isLoading ? (
          <div className="main-loading-div">
            <Spinner className="main-loading" />
          </div>
        ) : rows.length === 0 ? (
          <div className="empty-filter">NO MATCHING SITES FOR THIS FILTER</div>
        ) : (
          <React.Fragment>
            <Row className="tile-filter">
              <Col className="dropdowns">
                <span className="clr--blue bold">SORT BY</span>
                <Input
                  type="select"
                  value={this.state.sortBy}
                  onChange={(obj) => this.handleSort(obj)}
                >
                  <option>A-Z</option>
                  <option>Z-A</option>
                </Input>
              </Col>
            </Row>
            {rows.map((row, i) => {
              return (
                <Row key={i} noGutters>
                  {row.map((site, j) => {
                    return (
                      <Col
                        key={j}
                        xl={4}
                        lg={6}
                        md={6}
                        sm={12}
                        className="p-2 col-xxl-3"
                      >
                        <SiteCard site={site} alert={this.props.alert} />
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </React.Fragment>
        )}
      </div>
    );
  }
}
