export const datetimeformatterService = {
    formatTime,
    formatTimeNoSeconds,
    formatDate,
    formatDateTime,
    formatDateTimeNoSeconds,
    formatDayOfWeek,
    convertMilliseconds,
}

//Format: 12:00:00
function formatTime(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2);

    return myDateString;
}

//Format: 12:00
function formatTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

//Format: 01/01/1970
function formatDate(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear();

    return myDateString;
}

//Format: 01/01/1970 12:00:00
function formatDateTime(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2) + ':' + ('0' + (myDate.getSeconds())).slice(-2);

    return myDateString;
}

//Format: 01/01/1970 12:00
function formatDateTimeNoSeconds(myDate) {
    const myDateString = ('0' + myDate.getDate()).slice(-2) + '/' + ('0' + (myDate.getMonth() + 1)).slice(-2) + '/' + myDate.getFullYear() + " " + ('0' + myDate.getHours()).slice(-2) + ':' + ('0' + (myDate.getMinutes())).slice(-2);

    return myDateString;
}

function formatDayOfWeek(myDate) {
    let day;
    switch (myDate.getDay()) {
        case 0:
            day = "Sun"
            break;
        case 1:
            day = "Mon"
            break;
        case 2:
            day = "Tues"
            break;
        case 3:
            day = "Wed"
            break;
        case 4:
            day = "Thur "
            break;
        case 5:
            day = " Fri "
            break;
        case 6:
            day = "  Sat"
            break;

        default:
            break;
    }
    return day;
}

//Returns an object of day, hour, minute and seconds
function convertMilliseconds(milliseconds) {
    var day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;
    return {
        day: day,
        hour: hour,
        minute: minute,
        seconds: seconds
    };
}