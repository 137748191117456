import React from 'react';

export default class Terms extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }


    render() {

        return (
            <div className="terms-wrapper">
                <div className="terms-text">
                    <h1>Terms and Conditions</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pellentesque nisl turpis, sit amet suscipit metus mattis eget. Aliquam justo orci, sagittis nec elit ac, bibendum egestas orci. Fusce tempus sapien et diam aliquet, id commodo ante consectetur. Praesent eleifend est ut magna gravida malesuada. Proin lacinia dapibus dolor, vel lobortis neque ultricies ac. Etiam nec euismod nisl, vel lacinia tellus. Curabitur facilisis aliquam lacus, vitae finibus mi consequat vitae. Suspendisse ante ligula, convallis in augue quis, tincidunt porta turpis. Praesent suscipit odio ut augue tempor, eget aliquet nisi suscipit. Donec varius semper tincidunt. Integer risus augue, scelerisque sed laoreet ac, vulputate vel felis. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Ut ac gravida justo. Fusce tempus dolor vel ultrices condimentum. Nunc sed hendrerit nisi, non lacinia lectus. Nunc sed arcu sem. Aenean risus ex, auctor eu suscipit at, ultrices ut urna. Nulla volutpat magna ligula, id egestas ex auctor at. Morbi imperdiet nec mi quis vehicula. Pellentesque et ipsum felis. Duis sed purus in ante mattis sagittis consequat a risus. Morbi cursus urna sed volutpat faucibus. Etiam imperdiet nisi ut arcu placerat consequat.</p>
                    <h1>Privacy Policy</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pellentesque nisl turpis, sit amet suscipit metus mattis eget. Aliquam justo orci, sagittis nec elit ac, bibendum egestas orci. Fusce tempus sapien et diam aliquet, id commodo ante consectetur. Praesent eleifend est ut magna gravida malesuada. Proin lacinia dapibus dolor, vel lobortis neque ultricies ac. Etiam nec euismod nisl, vel lacinia tellus. Curabitur facilisis aliquam lacus, vitae finibus mi consequat vitae. Suspendisse ante ligula, convallis in augue quis, tincidunt porta turpis. Praesent suscipit odio ut augue tempor, eget aliquet nisi suscipit. Donec varius semper tincidunt. Integer risus augue, scelerisque sed laoreet ac, vulputate vel felis. Interdum et malesuada fames ac ante ipsum primis in faucibus.

Ut ac gravida justo. Fusce tempus dolor vel ultrices condimentum. Nunc sed hendrerit nisi, non lacinia lectus. Nunc sed arcu sem. Aenean risus ex, auctor eu suscipit at, ultrices ut urna. Nulla volutpat magna ligula, id egestas ex auctor at. Morbi imperdiet nec mi quis vehicula. Pellentesque et ipsum felis. Duis sed purus in ante mattis sagittis consequat a risus. Morbi cursus urna sed volutpat faucibus. Etiam imperdiet nisi ut arcu placerat consequat.</p>
                </div>
            </div>
        );
    }
}