import React from 'react';
import Toggle from 'react-toggle';
import { apiService } from '../../services/api.service';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { eventsService } from '../../services/events.service';

import { ReactComponent as Settings } from '../../icons/Settings.svg';

import "react-toggle/style.css";
import { settingsService } from '../../services/sensorsettings.service';

const timeIntervals = [{ minutes: "10 Minutes", ms: 600000 }, { minutes: "20 Minutes", ms: 1200000 }, { minutes: "30 Minutes", ms: 1800000 }, { minutes: "40 Minutes", ms: 2400000 }, { minutes: "50 Minutes", ms: 3000000 }, { minutes: "60 Minutes", ms: 3600000 }, { minutes: "90 Minutes", ms: 5400000 }, { minutes: "120 Minutes", ms: 7200000 }];

export default class AirQualityAlertRulesModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isOpen: false,
            nameConfirmation: "",
            selected: {
                amber: false,
                red: false,
            },
            data: {
                sensorName: "",
                amberPmOne: "",
                amberPmTwo: "",
                amberPmTen: "",
                redPmOne: "",
                redPmTwo: "",
                redPmTen: "",
            },
            checked: {
                amberPmOne: false,
                amberPmTwo: false,
                amberPmTen: false,
                redPmOne: false,
                redPmTwo: false,
                redPmTen: false,
                notifyAmber: false,
                notifyRed: false,
                phone: false,
                text: false,
                email: false,
                office: false,
            },
            disabled: {
                amberPmOne: true,
                amberPmTwo: true,
                amberPmTen: true,
                redPmOne: true,
                redPmTwo: true,
                redPmTen: true,
            },
            dropdown: {
                phone: "",
                text: "",
                email: "",
            },
            contacts: {
                all: [],
                phone: [],
                text: [],
                email: [],
            },
        }
    }

    componentDidMount() {
        //Grab current user
        const user = JSON.parse(localStorage.getItem("currentUser"));
        
        //Get all the contacts for this account for the dropdowns
        apiService.getData("/contact")
            .then(allContacts => {

                var contacts = allContacts.map(contact => {
                    if (contact.accountId === user.AccountId) {
                        return contact;
                    } else {
                        return undefined;
                    }
                })

                Promise.all(contacts)
                    .then(contacts => {
                        contacts = contacts.filter(contact => contact !== undefined);
                        const contactsAndDropdowns = settingsService.filterContacts(contacts);
                        this.setState({
                            contact: contactsAndDropdowns.contact,
                            dropdown: {
                                phone: contactsAndDropdowns.dropdown.phone,
                                text: contactsAndDropdowns.dropdown.text,
                                email: contactsAndDropdowns.dropdown.email,
                            }
                        })
                        this.getCurrentRule();
                    })
            })
    }

    getCurrentRule() {
        const { sensorDisplayInfo } = this.props;
        let amberPmOne, amberPmTwo, amberPmTen, redPmOne, redPmTwo, redPmTen, data;

        //Get the alert rule for the current sensor
        apiService.getData("/rule/sensor?sensorId=" + sensorDisplayInfo.sensorId).then(currentRule => {
            if (currentRule.length > 0) {
                currentRule.forEach(rule => {

                    //Check for amber or red, then split values into different PM values
                    if (rule.alertLevel === 1) {
                        const splits = rule.maxValue.split("|");

                        amberPmOne = splits[0];
                        amberPmTwo = splits[1];
                        amberPmTen = splits[2];
                    } else if (rule.alertLevel === 2) {
                        const splits = rule.maxValue.split("|");

                        redPmOne = splits[0];
                        redPmTwo = splits[1];
                        redPmTen = splits[2];
                    }
                });

                //Add all this to state so that current alert level values are in place
                data = {
                    sensorName: sensorDisplayInfo.name,
                    amberPmOne: amberPmOne,
                    amberPmTwo: amberPmTwo,
                    amberPmTen: amberPmTen,
                    redPmOne: redPmOne,
                    redPmTwo: redPmTwo,
                    redPmTen: redPmTen,
                }

                this.setState({ data: data, isLoading: false })
            } else {
                //If no alert rule for this sensor then just output the sensors name
                data = {
                    sensorName: sensorDisplayInfo.name,
                };
                this.setState({ isLoading: false })
            }
        })
    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleCheckedDisabled(obj) {
        //Toggles checked and disabled states
        const { disabled, checked } = this.state;
        disabled[`${obj.target.id}`] = !disabled[`${obj.target.id}`];
        checked[`${obj.target.id}`] = obj.target.checked;

        const selected = this.checkSelected();

        this.setState({ disabled: disabled, checked: checked, selected: selected, isChanged: true })
    }

    checkSelected() {
        const { disabled, selected } = this.state;

        //This function checks which alert level we're sending, depending on the disabled values
        if (disabled.amberPmOne === true && disabled.amberPmTwo === true && disabled.amberPmTen === true) {
            selected.amber = false;
        } else {
            selected.amber = true;
        }

        if (disabled.redPmOne === true && disabled.redPmTwo === true && disabled.redPmTen === true) {
            selected.red = false;
        } else {
            selected.red = true;
        }

        return selected;
    }

    handleChecked(obj) {
        //Toggles check boxes
        const { checked } = this.state;
        checked[`${obj.target.id}`] = obj.target.checked;

        this.setState({ checked: checked, isChanged: true })
    }

    handleValueChange(obj) {
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = parseInt(obj.target.value);

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleIntervalDropdown = (obj) => {
        //Changes the dropdown value but also calls the the passed in function to get the detail page to update
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = parseInt(obj.target.value);

        this.props.changeInterval(parseInt(obj.target.value));

        this.setState({ dropdown: dropdown})
    }

    updateName() {
        this.setState({ nameConfirmation: "Working..." });
        settingsService.updateName(this.props.sensorDisplayInfo, this.state.data.sensorName).then(result => {
            if (result === true) {
                this.setState({ nameConfirmation: "Done!" })
            } else {
                this.setState({ nameConfirmation: "Failed, please try again shortly" })
            }
        })
    }

    saveAlertRule() {
        const { sensorDisplayInfo } = this.props;
        const { data, selected, disabled, checked, dropdown } = this.state;

        const user = JSON.parse(localStorage.getItem("currentUser"))
        let toDatabase = [];

        //Checks if saving amber and/or red alert rules
        //Only adds the value to the rule if the box wasn't disabled, else adds a -
        if (selected.amber === true) {
            let max = [], phone, text, email;

            if (disabled.amberPmOne === false) {
                if (data.amberPmOne === "") { data.amberPmOne = "-"; }
                max = data.amberPmOne;
            } else {
                max = "-"
            }

            if (disabled.amberPmTwo === false) {
                if (data.amberPmTwo === "") { data.amberPmTwo = "-"; }
                max = max + "|" + data.amberPmTwo;
            } else {
                max = max + "|-"
            }

            if (disabled.amberPmTen === false) {
                if (data.amberPmTen === "") { data.amberPmTen = "-"; }
                max = max + "|" + data.amberPmTen;
            } else {
                max = max + "|-"
            }

            if (checked.notifyAmber === true) {
                if (checked.phone === true) {
                    phone = dropdown.phone;
                }

                if (checked.text === true) {
                    text = dropdown.text;
                }

                if (checked.email === true) {
                    email = dropdown.email;
                }
            }

            toDatabase.push({
                sensorId: sensorDisplayInfo.sensorId,
                alertLevel: 1,
                maxValue: max,
                message: "Outside of parameters",
                phone: phone,
                text: text,
                email: email,
                account_Id: user.AccountId,
            })
        }

        if (selected.red === true) {
            let max = [], phone, text, email;

            if (disabled.redPmOne === false) {
                if (data.redPmOne === "") { data.redPmOne = "-"; }
                max = data.redPmOne;
            } else {
                max = "-"
            }

            if (disabled.redPmTwo === false) {
                if (data.redPmTwo === "") { data.redPmTwo = "-"; }
                max = max + "|" + data.redPmTwo;
            } else {
                max = max + "|-"
            }

            if (disabled.redPmTen === false) {
                if (data.redPmTen === "") { data.redPmTen = "-"; }
                max = max + "|" + data.redPmTen;
            } else {
                max = max + "|-"
            }

            if (checked.notifyRed === true) {
                if (checked.phone === true) {
                    phone = dropdown.phone;
                }

                if (checked.text === true) {
                    text = dropdown.text;
                }

                if (checked.email === true) {
                    email = dropdown.email;
                }
            }

            toDatabase.push({
                sensorId: sensorDisplayInfo.sensorId,
                alertLevel: 2,
                maxValue: max,
                message: "Outside of parameters",
                phone: phone,
                text: text,
                email: email,
                account_Id: user.AccountId,
            })
        }

        //Checks that we are saving at least one type of alert
        if (toDatabase.length > 0) {
            //POST the rule, then create the new event and then close the modal
            apiService.postData("/rule", toDatabase)
                .then(() => {
                    const message = "New " + sensorDisplayInfo.type + " alert rule";
                    eventsService.newEvent(message, "Rules", "Create")
                        .then(() => {
                            this.toggleModal();
                        })
                })
        }
    }

    render() {
        const { sensorDisplayInfo } = this.props;
        const { checked, contact } = this.state;

        return (
            this.state.isLoading ?
                ""
                :
                <React.Fragment>
                    <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="alert-rules-modal">
                        <ModalHeader toggle={() => this.toggleModal()}>

                            <div className="icon"><Settings width={35} /></div>
                            <div className="title">{sensorDisplayInfo.type} SETTINGS</div>
                        </ModalHeader>
                        <ModalBody>
                        <strong><span>PLEASE SELECT YOUR SETTINGS FOR</span> <br />
                            {sensorDisplayInfo.type}</strong> <br />
                            <Label>Name: </Label> <br />
                            <div className="sensor-name">
                                <Input type="text" id="sensorName" value={this.state.data.sensorName} onChange={(obj) => this.handleValueChange(obj)} />
                                <Button color="primary" onClick={() => this.updateName()} >UPDATE NAME</Button> 
                                <Label className="sensor-name-confirmation">{this.state.nameConfirmation}</Label>
                            </div>
                            <hr />
                            <Label>AMBER ALERT HIGHER THAN ({sensorDisplayInfo.unit}): </Label> <br />
                            <Label className="pm-low smaller-font" >PM<sub>1</sub></Label>
                            <Label className="pm-high smaller-font" >PM<sub>2.5</sub></Label>
                            <Label className="pm-high smaller-font" >PM<sub>10</sub></Label>
                            <div className="input-toggle">
                                <Input type="number" id="amberPmOne" className="alert-input" value={this.state.data.amberPmOne} {...(this.state.disabled.amberPmOne ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="amberPmOne" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.amberPmOne} />
                                <Input type="number" id="amberPmTwo" className="alert-input" value={this.state.data.amberPmTwo} {...(this.state.disabled.amberPmTwo ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="amberPmTwo" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.amberPmTwo} />
                                <Input type="number" id="amberPmTen" className="alert-input" value={this.state.data.amberPmTen} {...(this.state.disabled.amberPmTen ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="amberPmTen" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.amberPmTen} />
                            </div> <br />
                            <Label for="redValue">RED ALERT HIGHER THAN ({sensorDisplayInfo.unit}): </Label> <br />
                            <Label className="pm-low smaller-font" >PM<sub>1</sub></Label>
                            <Label className="pm-high smaller-font" >PM<sub>2.5</sub></Label>
                            <Label className="pm-high smaller-font" >PM<sub>10</sub></Label>
                            <div className="input-toggle">
                                <Input type="number" id="redPmOne" className="alert-input" value={this.state.data.redPmOne} {...(this.state.disabled.redPmOne ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="redPmOne" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.redPmOne} />
                                <Input type="number" id="redPmTwo" className="alert-input" value={this.state.data.redPmTwo} {...(this.state.disabled.redPmTwo ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="redPmTwo" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.redPmTwo} />
                                <Input type="number" id="redPmTen" className="alert-input" value={this.state.data.redPmTen} {...(this.state.disabled.redPmTen ? { disabled: true } : {})} onChange={(obj) => this.handleValueChange(obj)} />
                                <Toggle id="redPmTen" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.redPmTen} />
                            </div><br />


                            NOTIFY FOR:
                            <div className="alert-to">
                                <FormGroup check className="amber-red-checkboxes">
                                    <div className="amber-check"><Input type="checkbox" id="notifyAmber" checked={checked.amber} onChange={(obj) => this.handleChecked(obj)} /> AMBER</div>
                                    <div className="red-check"><Input type="checkbox" id="notifyRed" checked={checked.red} onChange={(obj) => this.handleChecked(obj)} /> RED</div>
                                </FormGroup>
                            </div>
                            ALERT TO:
                            <div className="alert-to">
                                <FormGroup check className="checkboxes">
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="phone" className="checkbox" checked={checked.phone} onChange={(obj) => this.handleChecked(obj)} /> PHONE
                                        <Input type="select" id="phone" className="select" value={this.state.dropdown.phone} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.phone ? { disabled: true } : {})}>
                                            {contact.phone.map(phone => {
                                                return (
                                                    <option key={phone.id} value={phone.id}>{phone.firstName + " " + phone.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="text" className="checkbox" checked={checked.text} onChange={(obj) => this.handleChecked(obj)} /> TEXT
                                        <Input type="select" id="text" className="select" value={this.state.dropdown.text} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.text ? { disabled: true } : {})}>
                                            {contact.text.map(text => {
                                                return (
                                                    <option key={text.id} value={text.id}>{text.firstName + " " + text.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="email" className="checkbox" checked={checked.email} onChange={(obj) => this.handleChecked(obj)} /> EMAIL
                                        <Input type="select" id="email" className="select" value={this.state.dropdown.email} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.email ? { disabled: true } : {})}>
                                            {contact.email.map(email => {
                                                return (
                                                    <option key={email.id} value={email.id}>{email.firstName + " " + email.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                            <Button color="primary" className="save-button" onClick={() => this.saveAlertRule()}>SAVE ALERT RULE</Button>
                            <hr />
                            <div>
                                Please select a reading interval
                                <Input type="select" id="interval" className="select" value={this.state.dropdown.interval} onChange={obj => this.handleIntervalDropdown(obj)} >
                                    {timeIntervals.map((interval, i) => {
                                        return (
                                            <option key={i} value={interval.ms}>{interval.minutes}</option>
                                        )
                                    })}
                                </Input>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" className="cancel-button" onClick={() => this.toggleModal()}>CANCEL</Button>
                        </ModalFooter>
                    </Modal>
                </React.Fragment>
        );
    }
}