/// <reference path="createeditaccount.js" />
import React from 'react';
import { Spinner } from 'reactstrap';
import { history } from '../../services/history.service';
import  AccountForm   from './CreateAccount';




import './Admin.scss';

export default class SpsAdmin extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.checkAdmin()) {
            //carry on
            this.setState({ isLoading: false });
        } else {
            history.goBack();
        }
    }

    checkAdmin() {
        const user = JSON.parse(localStorage.getItem("currentUser"))

        if (user.role === "Admin") {
            return true;
        } else {
            return false;
        }
    }


    render() {


        return (

            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                   
                </div>
                :
                <div className="sps-admin">
                  
                    <AccountForm/>

                </div>
        )
    }
}