import React from 'react';

export default class DetailAverage extends React.Component {

    //A simple class that creates the average detail box that recieves a value calculated in detail
    render () {
        
        const { type, value } = this.props;
        var styles;
        
        return (
            <div className="average" style={styles}>
                <span>AVERAGE <br />
                {type}</span>
                <strong>{value}</strong> 
            </div>
        )
    }
}