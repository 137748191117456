import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { datetimeformatterService } from "../../../services/datetimeformatter.service";
import { dataService } from "../../../services/data.service";

// import Humidity from '../../../icons/Humidity.svg';
// import Temperature from '../../../icons/Temperature.svg';
// import L8 from '../../../icons/L8Sensor.svg';
// import Lux from '../../../icons/Lux.svg';
// import AirQuality from '../../../icons/AirQuality.svg';
import { ReactComponent as MoveHandle } from "../../../icons/MoveHandle.svg";

import { ReactComponent as Humidity } from "../../../icons/42/Humidity42.svg";
import { ReactComponent as Temperature } from "../../../icons/42/Temperature42.svg";
import { ReactComponent as L8 } from "../../../icons/42/L842.svg";
import { ReactComponent as Lux } from "../../../icons/42/LUX42.svg";
import { ReactComponent as AirQuality } from "../../../icons/42/AirQuality42.svg";
// import MoveHandle from '../../../icons/42/MoveHandle42.svg';

import Rollover from "./Rollover";

export default class GraphTile extends React.Component {
  constructor() {
    super();

    this.state = {
      graph: undefined,
      isLoading: true,
      isData: false,
      currentReading: {
        value: "",
        unit: "",
      },
      styles: {
        colours: {
          header: "",
          body: "",
        },
        icon: "",
      },
    };
  }

  componentDidMount() {
    //Get readings, check that there are any readings, sort by date
    dataService
      .getReadingsBySensorId(this.props.sensorDisplayInfo.sensorId)
      .then((readings) => {
        if (readings.length > 0) {
          readings.sort(function (a, b) {
            var dateA = new Date(a.readingTime),
              dateB = new Date(b.readingTime);
            return dateA - dateB;
          });
          this.createGraphs(readings);
        } else {
          let noData = <div className="no-data">INSUFFICIENT DATA</div>;
          this.setState({ graph: noData, isLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: true });
      });
  }

  getDetailComponents = () => {
    const { sensorDisplayInfo } = this.props;
    let icon,
      size = 35;

    switch (sensorDisplayInfo.type) {
      case "HUMIDITY":
        icon = <Humidity width={size} height={size} />;
        break;
      case "TEMPERATURE":
        icon = <Temperature width={size} height={size} />;
        break;
      case "L8":
        icon = <L8 width={size} height={size} />;
        break;
      case "LIGHT":
        icon = <Lux width={size} height={size} />;
        break;
      case "SOUND":
        break;
      case "AIR QUALITY":
        icon = <AirQuality width={size} height={size} />;
        break;
      default:
        break;
    }
    return icon;
  };

  splitSensorUp = (data) => {
    let splits = [];
    data.forEach((reading, i) => {
      if (reading.type === this.props.sensorDisplayInfo.type) {
        splits.push(reading);
      }
    });
    return splits;
  };

  processGraphData(processedData) {
    let data = [];

    //Split an array with two types into one array that matches styles.name
    let splitReadings = this.splitSensorUp(processedData);
    const length = splitReadings.length;

    //Creates an array of graph data based on the sensor name matching the style name from dashboard
    splitReadings.forEach((reading, i) => {
      let time = datetimeformatterService.formatDayOfWeek(reading.readingTime);

      data.push({ name: time, value: parseFloat(reading.value) });
      if (i === length - 1) {
        this.setState({
          currentReading: { value: reading.value, unit: reading.unit },
        });
      }
    });
    return data;
  }

  createGraphs(processedData) {
    const graphData = this.processGraphData(processedData);

    let graph = (
      <ResponsiveContainer height="78%">
        <AreaChart
          data={graphData}
          margin={{ top: 0, right: 25, bottom: 5, left: -15 }}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="25%" stopColor="#ffff" stopOpacity={0.8} />
              <stop offset="75%" stopColor="#ffff" stopOpacity={0.2} />
            </linearGradient>
          </defs>

          <Area
            type="monotone"
            dataKey="value"
            stroke="#ffff"
            fill="url(#gradient)"
          />
          <CartesianGrid vertical={false} opacity={0.5} />
          <XAxis dataKey="name" stroke="#fff" minTickGap={7} />
          <YAxis stroke="#fff" scale="linear" />
        </AreaChart>
      </ResponsiveContainer>
    );

    this.setState({
      graph: graph,
      readings: processedData,
      isLoading: false,
      isData: true,
    });
  }

  render() {
    let icon = this.getDetailComponents(this.state.readings);

    const { sensorDisplayInfo } = this.props;

    let headerStyles = {
      backgroundColor: sensorDisplayInfo.headColour,
    };

    let bodyStyles = { backgroundColor: "" };

    //Assigns background colour depending on alert level
    if (
      this.state.isData === true &&
      this.state.readings[this.state.readings.length - 1].alertLevel === 1
    ) {
      bodyStyles.backgroundColor = "#F7931E";
    } else if (
      this.state.isData === true &&
      this.state.readings[this.state.readings.length - 1].alertLevel > 1
    ) {
      bodyStyles.backgroundColor = "#FF0000";
    } else {
      bodyStyles.backgroundColor = sensorDisplayInfo.bodyColour;
    }

    return (
      <div className="graph-tile">
        <Col lg={12} className="header-wrapper" style={headerStyles}>
          <Row className="text-center header">
            <Col lg={1} className="icon">
              {icon}
            </Col>
            <Col lg={10} className="title">
              {sensorDisplayInfo.type} <br />
              <span className="user-defined-name">
                {sensorDisplayInfo.name.toUpperCase()}
              </span>
            </Col>
            <Col lg={1} className="drag-handle">
              <MoveHandle width={15} />
            </Col>
          </Row>
        </Col>
        {this.state.isLoading ? (
          <Col lg={12} className="body" style={bodyStyles}>
            <div className="tile-loading">
              <Spinner className="tile-spinner" />
            </div>
          </Col>
        ) : this.state.isData ? (
          <Link
            to={{
              pathname: "/detail",
              state: {
                sensorDisplayInfo: sensorDisplayInfo,
                roomName: sensorDisplayInfo.roomName,
                siteAddress: this.props.siteAddress,
                siteName: this.props.siteName,
              },
            }}
          >
            <Col lg={12} className="body bottomGraph" style={bodyStyles}>
              <div className="current-reading">
                {this.state.currentReading.value}{" "}
                <span className="current-reading-unit">
                  {this.state.currentReading.unit}
                </span>
              </div>
              {this.state.graph}
              <Rollover />
            </Col>
          </Link>
        ) : (
          <Col lg={12} className="body bottomGraph" style={bodyStyles}>
            <div className="current-reading">{this.state.currentReading}</div>
            {this.state.graph}
          </Col>
        )}
      </div>
    );
  }
}
