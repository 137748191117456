import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import { apiService } from "../../../services/api.service";
import Rollover from "./Rollover";

const alertColors = ["#8CC63F", "#F7931E", "red"];

export default class OtherTile extends React.Component {
  constructor() {
    super();

    this.state = {
      data: "",
      alert: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    this.splitSensor(this.props.data);
  }

  getAlertLevels(reading) {
    let alertLevels = [];

    //Call API to get individual air quality alert levels
    return apiService
      .getData(
        "/alert/aircheck?SensorId=" + this.props.sensorId + "&Value=" + reading
      )
      .then((alerts) => {
        alertLevels = alerts.split("|");

        //If no rules set then green light them all
        if (alertLevels[0] === "No rules found for this sensor!") {
          alertLevels = [0, 0, 0];
          return alertLevels;
        } else {
          return alertLevels;
        }
      });
  }

  splitSensor = (data) => {
    //Split most recent reading into 3 different PM readings
    const splitValues = data[data.length - 1].value.split("|");

    let airQuality = [];

    airQuality.push(splitValues[0]);
    airQuality.push(splitValues[1]);
    airQuality.push(splitValues[2]);

    const alertLevel = data[data.length - 1].alertLevel;
    let alert = "";
    let styles = "";

    if (alertLevel === 0) {
      alert = "GOOD";
      styles = "#8CC63F";
    } else if (alertLevel === 1) {
      alert = "MODERATE";
      styles = "#F7931E";
    } else if (alertLevel > 1) {
      alert = "BAD";
      styles = "#FF0000";
    }

    this.getAlertLevels(data[data.length - 1].value).then((alertLevels) => {
      this.setState({
        data: airQuality,
        alert: alert,
        alertLevels: alertLevels,
        styles: styles,
        isLoading: false,
      });
    });
  };

  render() {
    return this.state.isLoading ? (
      <div className="tile-loading">
        <Spinner className="tile-spinner" />
      </div>
    ) : (
      <Col lg={12} className="airquality">
        <Row noGutters className="text-center">
          <Col className="airquality-reading">
            PM<sub>1</sub>
            <sup
              style={{
                fontSize: "13pt",
                color: alertColors[parseInt(this.state.alertLevels[0])],
              }}
            >
              •
            </sup>{" "}
            <br />
            <strong>{this.state.data[0]}</strong> <br />
            µg/m³
          </Col>
          <Col className="airquality-reading">
            PM<sub>2.5</sub>
            <sup
              style={{
                fontSize: "13pt",
                color: alertColors[parseInt(this.state.alertLevels[1])],
              }}
            >
              •
            </sup>{" "}
            <br />
            <strong>{this.state.data[1]}</strong> <br />
            µg/m³
          </Col>
          <Col className="airquality-reading">
            PM<sub>10</sub>
            <sup
              style={{
                fontSize: "13pt",
                color: alertColors[parseInt(this.state.alertLevels[2])],
              }}
            >
              •
            </sup>{" "}
            <br />
            <strong>{this.state.data[2]}</strong> <br />
            µg/m³
          </Col>
        </Row>
        <Row className="text-center">
          <Col
            lg={{ offset: 1, size: 10 }}
            className="airquality-alert"
            style={{ backgroundColor: this.state.styles }}
          >
            <h1>{this.state.alert}</h1>
          </Col>
        </Row>
      </Col>
    );
  }
}
