import React from 'react';
import imageCompression from 'browser-image-compression';
import { history } from '../../../services/history.service';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { apiService } from '../../../services/api.service';
import { eventsService } from '../../../services/events.service';
import { cacheService } from '../../../services/cache.service';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { Mixpanel } from '../../../Mixpanel';
import { ReactComponent as  CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as  CurrentDefects } from '../../../icons/CurrentDefects.svg';

import './Defects.scss';

export const conditions = ["Open", "Closed"];

export default class DefectDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            file: null,
            button: "secondary",
            errorText: "",
            uploadText: "Optionally upload a picture of the defect here",
            rooms: [],
            assets: [],
            assetDropdown: [],
            imageChanged: false,
            isChanged: false,
            disabled: true,
            dropdown: {
                assetName: "",
                defectStatus: "",
                defectRoom: "",
            },
            data: {
                defectName: "",
                defectDescription: "",
            },
            validate: {
                defectNameState: "",
                defectDescriptionState: "",
            }
        }
    }

    componentDidMount() {
        const { site, room } = this.props.location.state;

        if (site !== undefined) {
            dataService.getRoomsBySiteId(site)
                .then(rooms => {
                    var roomNames = rooms.map(room => {
                        return room.name
                    });

                    Promise.all(roomNames)
                        .then(roomNames => {
                            this.setInitialStates(this.props.location.state, rooms, roomNames)
                        });
                })
        } else if (room !== undefined) {
            //get room by room id and then get ther site id, set that to state
            dataService.getRoomById(room)
                .then(room => {
                    dataService.getRoomsBySiteId(room.site_Id)
                        .then(rooms => {
                            var roomNames = rooms.map(room => {
                                return room.name
                            });

                            Promise.all(roomNames)
                                .then(roomNames => {
                                    this.setInitialStates(this.props.location.state, rooms, roomNames)
                                });
                        })
                })
        }
    }

    setInitialStates(props, rooms, roomNames) {
        const { defect, update } = props;

        dataService.getAllAssets()
            .then(assets => {
                const user = JSON.parse(localStorage.getItem("currentUser"))
                var assetNames = assets.map(asset => {
                    //Checks if the assets belong to this user and if they are in this site
                    if (asset.account_Id === user.AccountId && rooms.some(room => room.id === asset.room_Id)) {
                        return asset.name
                    } else {
                        return undefined;
                    }
                });

                Promise.all(assetNames)
                    .then((assetNames) => {
                        assetNames = assetNames.filter(asset => asset !== undefined);
                        //Check if new defect or updating
                        if (update === false) {
                            //Creating a new defect
                            this.setState({
                                rooms: rooms,
                                assets: assets,
                                disabled: false,
                                assetDropdown: assetNames,
                                dropdownOptions: roomNames,

                                dropdown: {
                                    defectRoom: rooms[0].name,
                                    defectStatus: conditions[0],
                                    assetName: assetNames[0],
                                },

                                isLoading: false
                            })
                        } else if (update === true) {
                            //Updating a defect
                            //Get picture in usuable format
                            let file = undefined, image = undefined;
                            if (defect.image !== undefined && defect.image !== "" && defect.image !== null) {
                                image = defect.image;
                                file = `data:image/;base64,${defect.image}`;
                            }

                            this.setState({
                                rooms: rooms,
                                assets: assets,
                                assetDropdown: assetNames,
                                dropdownOptions: roomNames,
                                image: image,
                                file: file,

                                dropdown: {
                                    assetName: defect.assetName,
                                    defectStatus: defect.defectStatus,
                                    defectRoom: defect.location,
                                },
                                data: {
                                    defectName: defect.name,
                                    defectDescription: defect.description,
                                },

                                isLoading: false
                            })
                        }
                    })
            })
    }

    handleOpenModal = (modal) => {
        this.refs[modal].toggleModal();
    }

    handleTextChange = (obj) => {
        this.handleValid(obj)
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleValid = (obj) => {
        const { validate } = this.state;
        if (obj.target.value.length > 0) {
            validate[`${obj.target.id}State`] = "valid";
        } else if (obj.target.value.length < 1) {
            validate[`${obj.target.id}State`] = "invalid";
        }

        this.setState({ validate: validate })
    }

    loadFile = (event) => {

        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
                this.setState({ file: URL.createObjectURL(event.target.files[0]), image: event.target.files[0], uploadText: "Optionally upload a picture of the defect here", imageChanged: true, isChanged: true });
            } else {
                this.setState({ file: null, image: undefined, uploadText: "Only PNG and JPEG files supported!" });
            }

        } else {
            this.setState({ file: null, image: undefined });
        }
    };

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = obj.target.value;

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleCancel = () => {
        if (this.state.isChanged) {
            this.handleOpenModal("confirm-no-change");
        } else {
            history.goBack();
        }
    }

    handleSave = () => {
        if (this.state.isChanged === true) {
            this.setState({ isLoading: true })
            this.saveDetails();
        } else if (this.state.isChanged === false) {
            this.setState({ button: "danger", errorText: "You've not made any changes!" });
        }
    }

    allowEdit = () => {
        this.setState({
            validate: {
                defectNameState: "valid",
                defectDescriptionState: "valid",
            },
            disabled: false
        })
    }


    getBase64(file, cb) {

        // console.log('originalFile instanceof Blob', file instanceof Blob); // true
        // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

        var options = {
            maxSizeMB: 0.25,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        //Run compression library function, before converting to base64 and returning to be posted
        imageCompression(file, options)
            .then(function (compressedFile) {
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                let reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = function () {
                    cb(reader.result);
                }
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }

    saveDetails = () => {
        const { data, validate, dropdown } = this.state, user = JSON.parse(localStorage.getItem("currentUser"));
        let toDatabase = [];

        //Checks to see if every required field is valid
        if (Object.values(validate).every(field => field === "valid")) {
            const roomIdIndex = this.state.rooms.findIndex(room => room.name === dropdown.defectRoom);
            const assetIdIndex = this.state.assets.findIndex(asset => asset.name === dropdown.assetName);

            toDatabase = {
                name: data.defectName,
                description: data.defectDescription,
                location: dropdown.defectRoom,
                defectStatus: dropdown.defectStatus,
                accountId: user.AccountId,
                assetName: dropdown.assetName,
                asset_Id: this.state.assets[assetIdIndex].id,
                room_Id: this.state.rooms[roomIdIndex].id
            }

            if (this.state.image !== undefined && this.state.imageChanged === true) {
                this.getBase64(this.state.image, (result) => {
                    const splits = result.split("base64,");

                    toDatabase.image = splits[1];

                    if (this.props.location.state.update === false) {
                        this.postToDatabase(toDatabase);
                    } else if (this.props.location.state.update === true) {
                        this.patchToDatabase(toDatabase);
                    }
                })
            } else {
                if (this.props.location.state.update === false) {
                    this.postToDatabase(toDatabase);
                } else if (this.props.location.state.update === true) {
                    this.patchToDatabase(toDatabase);
                }
            }

            this.setState({ button: "secondary", errorText: "" });

        } else {
            this.setState({ button: "danger", errorText: "Please fill in all mandatory fields!", isLoading: false });
        }
    }

    postToDatabase = (newdefect) => {
        apiService.postData("/defect", newdefect)
            .then(() => {
                cacheService.updateDefects()
                    .then(() => {
                        const message = "New defect: " + newdefect.name + " - " + newdefect.description;
                        eventsService.newEvent(message, "Defects", "Create")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }

    patchToDatabase = (defect) => {
        defect.id = this.props.location.state.defect.id;
        apiService.patchData("/defect", defect)
            .then(() => {
                cacheService.updateDefects()
                    .then(() => {
                        const message = "Updated defect: " + defect.name + " - " + defect.description;
                        eventsService.newEvent(message, "Defects", "Update")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }

    deleteFromDatabase = (id) => {
        this.setState({ isLoading: true })
        apiService.deleteData("/defect?id=" + id)
            .then(() => {
                cacheService.updateDefects()
                    .then(() => {
                        const message = "Remove defect: " + this.state.data.defectName + " - " + this.state.data.defectDescription;
                        eventsService.newEvent(message, "Defects", "Delete")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }


    render() {
        Mixpanel.track('Defect Details');
        const { dropdownOptions, assetDropdown } = this.state;

        return (
            this.state.isLoading ?
                ""
                :
                <div className="defect-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<CurrentDefects width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Defects
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => this.handleCancel()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <Form className="form">
                        <Row>
                            <Col>
                                <div className="image-wrapper">
                                    <img className="image" id="defectPictureOutput" alt="" src={this.state.file} />
                                </div>
                                <FormGroup>
                                    <Label for="defectPicture">File</Label>
                                    <Input type="file" accept="image/jpeg, image/png" name="file" id="defectPicture" onChange={this.loadFile} {...(this.state.disabled ? { disabled: true } : {})} />
                                    <FormText color="muted">
                                        {this.state.uploadText}
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col className="input-col">
                                <Row className="delete-row">
                                    <div className="edit-delete">
                                        {this.props.location.state.update ?
                                            <Button className="delete-button" color="secondary" onClick={() => this.handleOpenModal("confirm-delete")}>Remove Defect</Button>
                                            :
                                            ""}
                                        <span> </span>
                                        {this.state.disabled ?
                                            <Button className="edit-button" color="secondary" onClick={() => this.allowEdit()}>Edit Defect</Button> : ""}
                                    </div>
                                </Row>
                                <FormGroup>
                                    <Label className="defect-label" for="defectName" >Name*</Label>
                                    <Input type="text" name="name" id="defectName" placeholder="Defect Name" value={this.state.data.defectName} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} valid={this.state.validate.defectNameState === "valid"} invalid={this.state.validate.defectNameState === "invalid"} />
                                    {/* <FormFeedback invalid="true">
                                        Please enter an defect name
                                    </FormFeedback> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="defect-label" for="defectDescription">Description*</Label>
                                    <Input type="textarea" name="decription" id="defectDescription" placeholder="Description" value={this.state.data.defectDescription} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} valid={this.state.validate.defectDescriptionState === "valid"} invalid={this.state.validate.defectDescriptionState === "invalid"} />
                                    {/* <FormFeedback invalid="true">
                                        Please enter a description of the defect
                                    </FormFeedback> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="defect-label" for="defectRoom" >Room*</Label>
                                    <Input type="select" name="room" id="defectRoom" value={this.state.dropdown.defectRoom} onChange={obj => this.handleDropdown(obj)} {...(this.state.disabled ? { disabled: true } : {})}>
                                        {dropdownOptions.map((option, i) => {
                                            return (
                                                <option key={i}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="defect-label" for="asset" >Asset*</Label>
                                    <Input type="select" name="asset" id="assetName" value={this.state.dropdown.assetName} onChange={obj => this.handleDropdown(obj)} {...(this.state.disabled ? { disabled: true } : {})}>
                                        {assetDropdown.map((option, i) => {
                                            return (
                                                <option key={i}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="defect-label" for="defectStatis" >Status*</Label>
                                    <Input type="select" name="status" id="defectStatus" value={this.state.dropdown.defectStatus} onChange={obj => this.handleDropdown(obj)}>
                                        {conditions.map((option, i) => {
                                            return (
                                                <option key={i}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col xs={10}>
                            </Col>
                            <Col xs={2}>
                            <Button className="cancel-button" color={this.state.button} onClick={() => this.handleSave()}>Update</Button>
                                <FormText>
                                    {this.state.errorText}
                                </FormText>
                            </Col>
                        </Row>
                    </Form>
                    <ConfirmationModal titleText="Delete Defect?" buttonText="Delete" bodyText="delete this defect" className="confirm-delete-defect" function={() => this.deleteFromDatabase(this.props.location.state.defect.id)} ref="confirm-delete" />
                    <ConfirmationModal titleText="Get rid of changes?" buttonText="Confirm" bodyText="go back without saving your changes" className="confirm-nochanges-defect" function={() => history.goBack()} ref="confirm-no-change" />
                </div>
        )
    }
}