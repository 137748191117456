import React from 'react';
import { dataService } from '../../../services/data.service';
import { Col, Row, Spinner } from 'reactstrap';

import { ReactComponent as  EnergyEff } from '../../../icons/EnergyEff.svg';
import { ReactComponent as  MoveHandle } from '../../../icons/MoveHandle.svg';
import { ReactComponent as  EnergyBackground } from '../../../icons/EnergyBackground.svg';
import { ReactComponent as  EnergyColour } from '../../../icons/EnergyColour.svg';
import { ReactComponent as  EnergyDial } from '../../../icons/EnergyDial.svg';


export default class EnergyEfficiencyTile extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            roomHasMotion: false,
            percentage: "",
            room: "",
        }
    }

    componentDidMount() {
        this.getReadings(this.props.sensors);
    }

    getReadings = (sensors) => {
        //Get readings for light and motion sensor
        dataService.getReadingsBySensorId(sensors.light.sensorId)
            .then((lightReadings) => {
                dataService.getReadingsBySensorId(sensors.motion.sensorId)
                    .then((motionReadings) => {
                        lightReadings.sort(function (a, b) {
                            var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                            return dateA - dateB;
                        });

                        motionReadings.sort(function (a, b) {
                            var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                            return dateA - dateB;
                        });

                        //Get data for the room these sensors belong to
                        dataService.getRoomById(sensors.light.roomId)
                            .then(room => {
                                this.calculateEfficiency(lightReadings, motionReadings, room);
                            })
                    })
            })
            .catch((error) => {
                this.setState({ error: true });
            })
    }

    calculateEfficiency = (luxReadings, pirReadings, room) => {

        //Get segments of motion detected
        let start = null, end = null, endChanged = false, luxIndex = 0, onPoints = 0, offPoints = 0;
        if (pirReadings.length > 1) {

            for (let i = 0; i < pirReadings.length; i++) {
                //Beginning edge cases
                if (pirReadings[i].value === "True" && i === 0) {
                    start = 0;
                } else if (pirReadings[i].value === "True" && i === 1) {
                    start = 1;
                }

                //If there was a gap before the current reading then make start here
                if (pirReadings[i] !== undefined && pirReadings[i - 1] !== undefined && pirReadings[i - 2] !== undefined) {
                    if (pirReadings[i].value === "True" && pirReadings[i - 1].value === "False" && pirReadings[i - 2].value === "False") {
                        start = i;
                    }
                }

                //This is the end of a section of motion
                if (pirReadings[i] !== undefined && pirReadings[i + 1] !== undefined && pirReadings[i + 2] !== undefined) {
                    if (pirReadings[i].value === "True" && pirReadings[i + 1].value === "False" && pirReadings[i + 2].value === "False") {
                        end = i;
                        endChanged = true;
                    }
                }

                //Once it reaches the end of the array, check if it needs to end
                if (pirReadings[i + 1] === undefined && pirReadings[i].value === "True") {
                    end = i;
                    endChanged = true;
                } else if (pirReadings[i + 1] === undefined && pirReadings[i - 1].value === "True") {
                    end = i - 1;
                    endChanged = true;
                }

                //Get the dates from milliseconds from start and end, end-start and add to running total 
                if (endChanged === true && end > start) {

                    let startTime = pirReadings[start].readingTime;
                    let endTime = pirReadings[end].readingTime;

                    startTime = startTime.getTime();
                    endTime = endTime.getTime();

                    while (1) {

                        //Need to check when current reading is greater than or equal to endtime then break
                        if (luxReadings[luxIndex] !== undefined) {
                            let currentTime = luxReadings[luxIndex].readingTime;
                            currentTime = currentTime.getTime();

                            if (luxReadings[luxIndex].value > 5) {
                                if (currentTime > startTime && currentTime <= endTime) {
                                    onPoints++;
                                } else {
                                    offPoints++;
                                }
                            }
                            if (currentTime >= endTime) {
                                break;
                            }
                            luxIndex++;
                        } else {
                            break;
                        }
                    }

                    endChanged = false;
                }
            }
        }

        let percentage = (onPoints / (onPoints + offPoints)) * 100;

        //Must check for edge cases, if one or both have no readings
        const lux = luxReadings.filter(reading => reading.value > 5);
        const pir = pirReadings.filter(reading => reading.value === "True");
        
        if (onPoints === 0 && offPoints === 0) {
            percentage = 100;
        }

        if (lux.length === 0 && pir.length === 0) {
            percentage = 100;
        } else if (pir.length > 0 && lux.length === 0) {
            percentage = 100;
        } else if (lux.length > 0 && pir.length === 0) {
            percentage = 0;
        }

        percentage = percentage.toFixed(2);

        this.setState({ percentage: percentage, roomHasMotion: true, room: room, isLoading: false })
    }

    render() {
        // if (this.state.roomHasMotion === true) {

        // const alert = this.state.latest.alertLevel

        let value = parseFloat(this.state.percentage);
        let bgColour;

        if (value > 80) {
            //Green
            bgColour = "#8CC63F";
        } else if (value >= 58) {
            //Amber
            bgColour = "#F4A105";
        } else if (value < 58) {
            //Red
            bgColour = "#FF0000";
        }
        
        //Rotate the dial SVG a certain amount based of percentage
        let transform = "translate(0, -106.2%) rotate(" + (value/100) + "turn)"

        value = Math.round(value);

        const style = {
            transform: transform,
        }

        let roomName = ""
        if (this.state.room.name !== undefined) {
            roomName = this.state.room.name;
        }
        

        return (
            <div className="graph-tile">
                <Col lg={12} className="header-wrapper" style={{ backgroundColor: "#F6931D" }}>
                    <Row className="text-center header">
                        <Col lg={1} className="icon">
                            {<EnergyEff />}
                        </Col>
                        <Col lg={10} className="title">
                            ENERGY EFFICIENCY<br />
                            <span className="user-defined-name">{roomName.toUpperCase()}</span>
                        </Col>
                        <Col lg={1} className="drag-handle">
                            <MoveHandle width={15} />
                        </Col>
                    </Row>
                </Col>
                {this.state.isLoading ?
                    <Col lg={12} className="body" style={{ backgroundColor: "#8CC63F" }}>
                        <div className="tile-loading">
                            <Spinner className="tile-spinner" />
                        </div>
                    </Col>
                    :
                    <Col lg={12} className="body" style={{ backgroundColor: bgColour }}>
                        <div className="energy-efficiency-tile" >
                            <div className="efficiency-bar-tile" >
                                <div className="inner">
                                <EnergyBackground className="background" />
                                    <div className="inner__2">
                                    <EnergyColour className="colour" />
                                <EnergyDial className="needle" style={style} />
                                </div>
                                <div className="value" >{value}<span className="energy-unit">%</span></div>
                              </div>
                            </div>
                        </div>
                    </Col>
                }
            </div>
        )
    }
}