import React from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { ReactComponent as AlarmIcon } from '../../icons/Alarm.svg';

import './Modal.scss';


export default class EmergencyModal extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isLoading: false,
        }
    }

    toggleModal(type) {
        this.setState({ isOpen: !this.state.isOpen, type: type });
    }

    handleType() {
        let { type } = this.state, displayText = "";

        //Checks for the type to fill in the text
        if (type !== undefined) {
            if (type === "alarms") {
                displayText = <span>RAISE<br/>ALL SPS ALARMS</span>
            } else if (type === "999") {
                displayText = <span>CONTACT '999'<br/> EMERGENCY SERVICES</span>
            }
        }

        return displayText;
    }

    render() {

        let displayText = this.handleType();

        return(
            <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="emergency-modal">
                <ModalHeader toggle={() => this.toggleModal()}>
                    <div className="icon"><AlarmIcon width={35} /></div>
                    <div className="title">EMERGENCY</div>
                </ModalHeader>
                <ModalBody>
                    {displayText} <br /> <br />
                    <Button className="emergency-confirm" color="danger" size="lg">CONFIRM</Button>
                </ModalBody>
            </Modal>
        )
    }
}