import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

import './styles/Layout.scss';
import './styles/react-grid.css';
import './styles/react-resizeable.css';

export class Layout extends Component {

    render() {

        const { currentUser, cacheState } = this.props;

        //If user is logged in, offset the content for nav etc
        let styles, account;

        if (cacheState.populated === true && currentUser !== null) {
            styles = "content-offset";
            account = currentUser.Name;
        } else {
            styles = "";
            account = "";
        }

        return (
            <div>
                { cacheState.populated === true && currentUser && <NavMenu account={account} logout={() => this.props.logout()} filter={this.props.filter} />}
                <Container fluid className={styles}>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
