import React from 'react';
import DataTable from 'react-data-table-component';
import { datetimeformatterService } from '../../../services/datetimeformatter.service';

import Battery from '../Battery';
import Signal from '../Signal';

export default class DetailTable extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            interval: 600000,
        }
    }

    componentDidMount() {
        let { readings } = this.props;
        const displayReadings = this.formatReadings(readings);

        this.setState({ displayReadings: displayReadings, isLoading: false })
    }

    formatReadings = (data) => {
        //Get a copy of the readings with a different pointer so we don't affect other sections
        let readings = JSON.parse(JSON.stringify(data));

        readings.forEach(reading => {
            reading = this.parseReading(reading);
        });

        readings.reverse();

        return readings;
    }

    parseReading(reading) {
        //Get the date into a useable format, split out the readings and convert the signal and battery values into icons
        reading.readingTime = datetimeformatterService.formatDateTime(new Date(reading.readingTime));
        let splitValues = reading.value.split('|');
        reading.value = splitValues[0] + "  |  " + splitValues[1] + "  |  " + splitValues[2] + "  ";
        reading.signalStrength = <Signal width={20} value={reading.signalStrength} />;
        reading.batteryLevel = <Battery width={30} value={reading.batteryLevel} />;

        return reading;
    }

    handleInterval() {
        this.setState({ isLoading: true })

        let { interval, readings } = this.props;

        //Get a copy of the readings array and reverse it
        let displayReadings = JSON.parse(JSON.stringify(readings));
        displayReadings.reverse()

        //Default 10 minutes
        if (interval === undefined || interval === 600000) {
            displayReadings.forEach(reading => {
                reading = this.parseReading(reading);
            });

            this.setState({ displayReadings: displayReadings, interval: interval, isLoading: false })
        } else {
            let intervalReadings = [], prevReading;

            //Interval margin of error
            let intervalMargin = interval - 200;

            //go through each reading and check if it's in the interval range
            displayReadings.forEach(reading => {
                //get ms value of current reading
                var currentReading = new Date(reading.readingTime).getTime();

                //Get first reading else check within interval range
                if (intervalReadings.length === 0) {
                    prevReading = new Date(reading.readingTime).getTime();

                    reading = this.parseReading(reading);

                    intervalReadings.push(reading)

                } else if (currentReading <= (prevReading - intervalMargin)) {
                    prevReading = new Date(reading.readingTime).getTime();

                    reading = this.parseReading(reading);

                    intervalReadings.push(reading)
                }
            });

            this.setState({ displayReadings: intervalReadings, interval: interval, isLoading: false })
        }
    }

    getColumns() {
        let units = <span>PM<sub>1</sub> | PM<sub>2.5</sub> | PM<sub>10</sub> {(this.props.readings[0].unit)}</span>

        const columns = [
            {
                name: units,
                selector: 'value',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Date/Time',
                selector: 'readingTime',
                sortable: false,
                grow: 2,
            },
            {
                name: 'Signal',
                selector: 'signalStrength',
                sortable: false,
                grow: 1,
            },
            {
                name: "Battery",
                selector: "batteryLevel",
                sortable: false,
                grow: 1,
            }
        ];

        return columns;
    }

    render() {
        const { displayReadings, isLoading } = this.state;
        const columns = this.getColumns();

        if (this.props.interval !== this.state.interval && isLoading === false) {
            this.handleInterval();
        }

        return (

            this.state.isLoading ?
                ""
                :
                <div className="readings-table-body">
                    <DataTable
                        columns={columns}
                        data={displayReadings}
                        striped
                        dense
                        highlightOnHover
                        pagination
                        noHeader
                        fixedHeader
                        paginationPerPage={12}
                        paginationRowsPerPageOptions={[12, 30, 50, 100, 500]}
                    />
                </div>
        );
    }
}