import React from 'react';
import { ReactComponent as PIR } from '../../../icons/PIR.svg';

export default class DetailActivations extends React.Component {

    render () {
        //Very simple class to output total activations
        return (
            <div className="activations">
                <h3>ACTIVATIONS</h3>
                <p><strong>{this.props.value}</strong></p>
                <PIR width = {80}/>
            </div>
        )
    }
}