import React from "react";
import { Col, Row } from "reactstrap";

import { ReactComponent as DialBackground } from "../../../icons/DialBackground.svg";
import { ReactComponent as DialColour } from "../../../icons/DialColour.svg";
import { ReactComponent as DialNeedle } from "../../../icons/DialNeedle.svg";

import "react-circular-progressbar/dist/styles.css";

export default class OtherTile extends React.Component {
  constructor() {
    super();

    this.state = {
      isLoading: true,
      data: "",
      unit: "",
      latest: "",
      change: "",
    };
  }

  componentDidMount() {
    const split = this.splitSensor(this.props.data);

    let latest = split[split.length - 1];
    const splits = latest.value.split("|");
    latest.value = splits[0];
    const unit = latest.unit.toUpperCase();

    const change = this.increase(split, latest);

    this.setState({
      unit: unit,
      latest: latest,
      change: change,
      isLoading: false,
    });
  }

  splitSensor = (data) => {
    let splits = [];
    data.forEach((reading, i) => {
      if (reading.type === this.props.type) {
        splits.push(reading);
      }
    });
    return splits;
  };

  //A function to find the difference between the earliest reading and the latest one
  increase(split, latest) {
    let oldest = split[0];
    const splits = oldest.value.split("|");
    oldest.value = splits[0];

    const newest = parseFloat(latest.value),
      old = parseFloat(oldest.value);

    const diff = old - newest;

    return diff;
  }

  render() {
    if (this.state.isLoading === false) {
      const alert = this.state.latest.alertLevel;

      //turn values for SVG
      //greenHigh = 0.045, amberHigh = 0.184, redHigh = 0.868
      let greenLow = -0.048,
        greenDiff = 0.00093,
        amberLow = 0.054,
        amberDiff = 0.0013,
        redLow = 0.19,
        redDiff = 0.00678,
        percent = 1,
        low,
        onePerc;

      const { value, unit } = this.state.latest;

      let alertOneLow = 10,
        alertOneHigh = 30,
        alertTwoLow = 31,
        alertTwoHigh = 100,
        diff;

      if (alert === 0) {
        //Green
        low = greenLow;
        onePerc = greenDiff;

        percent = (value / alertOneLow) * 100;

        if (percent > 100) {
          percent = 100;
        } else if (percent < 0) {
          percent = 0;
        }
      } else if (alert === 1) {
        //Amber
        low = amberLow;
        onePerc = amberDiff;
        let temp = value - alertOneLow;

        diff = alertOneHigh - alertOneLow;
        percent = (temp / diff) * 100;

        if (percent > 100) {
          percent = 100;
        } else if (percent < 0) {
          percent = 0;
        }
      } else if (alert > 1) {
        //Red
        low = redLow;
        onePerc = redDiff;
        let temp = value - alertTwoLow;

        diff = alertTwoHigh - alertTwoLow;
        percent = (temp / diff) * 100;

        if (percent > 100) {
          percent = 100;
        } else if (percent < 0) {
          percent = 0;
        }
      }

      //Rotate the dial SVG by a certain amount depening on the math above
      let transform =
        "translate(0, -184%) rotate(" +
        (low + onePerc * percent) +
        "turn) scale(1.1)";

      const styles = {
        transform: transform,
      };

      return (
        <React.Fragment>
          <div className="co-progress otherCircle">
            <div className="inner">
              <DialBackground className="background" />
              <DialColour className="colour" />
              <DialNeedle className="needle" style={styles} />
            </div>
          </div>
          <div className="value">
            <strong>{value}</strong>
            <br />
            {unit.toUpperCase()}
          </div>
          {this.state.change >= 0 ? (
            <div className="co-change">
              ▲ {this.state.change} INCREASE IN THE LAST 7 DAYS
            </div>
          ) : (
            <div className="co-change">
              ▼ {this.state.change} decrease in the last 7 days
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return "";
    }
  }
}
