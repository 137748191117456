import { datetimeformatterService } from '../../../services/datetimeformatter.service';

export function CalcPirValues(readings) {

    let tableValues = JSON.parse(JSON.stringify(readings));
    let hourStart, dayStart, current, hourAway, dayAway, hourStartIndex, dayStartIndex, hourEndIndex, dayEndIndex, hours = [], days = [], graphValue, graphValues = [];

    readings.forEach((reading, i) => {
        //Graph data and table data
        if (reading.value === "True") {
            tableValues[i].value = "Motion Detected";
            graphValue = 1;
        } else if (reading.value === "False") {
            tableValues[i].value = "No Motion Detected";
            graphValue = 0;
        }
        let time = datetimeformatterService.formatDateTimeNoSeconds(reading.readingTime);
        graphValues.push({ name: time, Motion: graphValue });

        //Grab hourly chunks
        current = reading.readingTime.getTime();
        //if hourStart is undefined then current = hourStart
        if (hourStart === undefined) {
            hourStart = current;
            hourStartIndex = i;
            hourAway = hourStart + 3600000;
        } else if (hourStart !== undefined) {
            //Once an hour has passed, grab the indexes of the hourStart and end of an hour
            if (current > hourAway) {
                hourEndIndex = i;

                const hourTemp = { hourStart: hourStartIndex, hourEnd: hourEndIndex };
                hours.push(hourTemp);

                hourStart = current;
                hourStartIndex = i;
                hourAway = hourStart + 3600000;
            }
        }

        //Grab days
        if (dayStart === undefined) {
            dayStart = current;
            dayStartIndex = i;
            dayAway = dayStart + 86400000;
        } else if (dayStart !== undefined) {
            //Once a day has passed, grab the indexes of the start and end of an day
            if (current > dayAway) {
                dayEndIndex = i;

                const dayTemp = { dayStart: dayStartIndex, dayEnd: dayEndIndex };
                days.push(dayTemp);

                dayStart = current;
                dayStartIndex = i;
                dayAway = dayStart + 86400000;
            }
        }

    });

    //Loop over an whole hour of readings and get hourlyActivations
    let hourlyActivations = [];
    hours.forEach(hour => {
        let temp = [];
        for (let i = hour.hourStart; i <= hour.hourEnd; i++) {
            temp.push(readings[i]);
        }
        let tempActivations = temp.filter((reading) => (reading.value === "True")).length;
        hourlyActivations.push(tempActivations);
    });

    //Loop over a day of readings and get daily activations
    let dailyActivations = [];
    days.forEach(day => {
        let dayTemp = [];
        for (let i = day.dayStart; i <= day.dayEnd; i++) {
            dayTemp.push(readings[i]);
        }
        let tempDayActivations = dayTemp.filter((reading) => (reading.value === "True")).length;
        dailyActivations.push(tempDayActivations);
    })

    //Get the date for the most activations
    const busiestDay = dailyActivations.indexOf(Math.max(...dailyActivations));    
    const busiestDate = datetimeformatterService.formatDate(readings[days[busiestDay].dayStart].readingTime);


    //Calculate the hourly mean
    const length = hourlyActivations.length;
    const total = hourlyActivations.reduce((a, b) => a + b, 0);
    let hourlyAverage = total / length;
    hourlyAverage = Math.floor(hourlyAverage);

    //Total activations number
    const activations = readings.filter((reading) => (reading.value === "True")).length;

    tableValues.reverse();

    let pirArrays = [];
    pirArrays.push(graphValues, tableValues, activations, hourlyAverage, busiestDate);
    
    return pirArrays;
}