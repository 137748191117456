import React from 'react';
import Toggle from 'react-toggle';
import { apiService } from '../../services/api.service';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label } from 'reactstrap';
import { eventsService } from '../../services/events.service';

import { ReactComponent as Settings } from '../../icons/Settings.svg';

import "react-toggle/style.css";
import { settingsService } from '../../services/sensorsettings.service';

const timeIntervals = [{ minutes: "All", ms: 600000 }, { minutes: "20 Minutes", ms: 1200000 }, { minutes: "30 Minutes", ms: 1800000 }, { minutes: "40 Minutes", ms: 2400000 }, { minutes: "50 Minutes", ms: 3000000 }, { minutes: "60 Minutes", ms: 3600000 }, { minutes: "90 Minutes", ms: 5400000 }, { minutes: "120 Minutes", ms: 7200000 }];

export default class MotionAlertRulesModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isOpen: false,
            nameConfirmation: "",
            selected: {
                amber: false,
                red: false,
            },
            data: {
                sensorName: "",
                amberLow: "",
                amberHigh: "",
                redLow: "",
                redHigh: "",
            },
            checked: {
                amberLow: false,
                amberHigh: false,
                redLow: false,
                redHigh: false,
                notifyAmber: false,
                notifyRed: false,
                phone: false,
                text: false,
                email: false,
                office: false,
            },
            disabled: {
                amberLow: true,
                amberHigh: true,
                redLow: true,
                redHigh: true,
            },
            dropdown: {
                phone: "",
                text: "",
                email: "",
            },
            contact: {
                all: [],
                phone: [],
                text: [],
                email: [],
            },
        }
    }

    componentDidMount() {
        //Grab current user
        const user = JSON.parse(localStorage.getItem("currentUser"));

        //Get all the contacts for this account for the dropdowns
        apiService.getData("/contact")
            .then(allContacts => {

                var contacts = allContacts.map(contact => {
                    if (contact.accountId === user.AccountId) {
                        return contact;
                    } else {
                        return undefined;
                    }
                })

                Promise.all(contacts)
                    .then(contacts => {
                        contacts = contacts.filter(contact => contact !== undefined);
                        const contactsAndDropdowns = settingsService.filterContacts(contacts);
                        this.setState({
                            contact: contactsAndDropdowns.contact,
                            dropdown: {
                                phone: contactsAndDropdowns.dropdown.phone,
                                text: contactsAndDropdowns.dropdown.text,
                                email: contactsAndDropdowns.dropdown.email,
                            }
                        })
                        this.getCurrentRule();
                    })
            })
    }

    getCurrentRule() {
        const { sensorDisplayInfo } = this.props;
        let amberLow, amberHigh, redLow, redHigh, data;

        //Get the alert rule for the current sensor
        apiService.getData("/rule/sensor?sensorId=" + sensorDisplayInfo.sensorId).then(currentRule => {
            if (currentRule.length > 0) {
                currentRule.forEach(rule => {

                    //Check for amber or red alert and add the values in
                    if (rule.alertLevel === 1) {
                        amberLow = rule.startTime;
                        amberHigh = rule.endTime;
                    } else if (rule.alertLevel === 2) {
                        redLow = rule.startTime;
                        redHigh = rule.endTime;
                    }
                });

                //Add all this to state so that current alert level values are in place
                data = {
                    sensorName: sensorDisplayInfo.name,
                    amberLow: amberLow,
                    amberHigh: amberHigh,
                    redLow: redLow,
                    redHigh: redHigh
                }

                this.setState({ data: data, isLoading: false })
            } else {
                //If no alert rule for this sensor then just output the sensors name
                data = {
                    sensorName: sensorDisplayInfo.name,
                };

                this.setState({ isLoading: false })
            }
        })
    }

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    handleCheckedDisabled(obj) {
        //Toggles checked and disabled states
        const { disabled, checked } = this.state;

        if (obj.target.id === "amber") {
            disabled.amberLow = !disabled.amberLow;
            disabled.amberHigh = !disabled.amberHigh;
            checked.amberLow = obj.target.checked;
            checked.amberHigh = obj.target.checked;
        } else if (obj.target.id === "red") {
            disabled.redLow = !disabled.redLow;
            disabled.redHigh = !disabled.redHigh;
            checked.redLow = obj.target.checked;
            checked.redHigh = obj.target.checked;
        }

        const selected = this.checkSelected();

        this.setState({ disabled: disabled, checked: checked, selected: selected, isChanged: true })
    }

    checkSelected() {
        const { disabled, selected } = this.state;

        //This function checks which alert level we're sending, depending on the disabled values
        if (disabled.amberLow === true && disabled.amberHigh === true) {
            selected.amber = false;
        } else {
            selected.amber = true;
        }

        if (disabled.redLow === true && disabled.redHigh === true) {
            selected.red = false;
        } else {
            selected.red = true;
        }

        return selected;
    }

    handleChecked(obj) {
        //Toggles check boxes
        const { checked } = this.state;
        checked[`${obj.target.id}`] = obj.target.checked;

        this.setState({ checked: checked, isChanged: true })
    }

    handleValueChange(obj) {
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = parseInt(obj.target.value);

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleIntervalDropdown = (obj) => {
        //Changes the dropdown value but also calls the the passed in function to get the detail page to update
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = parseInt(obj.target.value);

        this.props.changeInterval(parseInt(obj.target.value));

        this.setState({ dropdown: dropdown})
    }

    handleDateUpdate(obj) {
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true });
    }

    updateName() {
        this.setState({ nameConfirmation: "Working..." });
        settingsService.updateName(this.props.sensorDisplayInfo, this.state.data.sensorName).then(result => {
            if (result === true) {
                this.setState({ nameConfirmation: "Done!" })
            } else {
                this.setState({ nameConfirmation: "Failed, please try again shortly" })
            }
        })
    }

    saveAlertRule() {
        const { sensorDisplayInfo } = this.props;
        const { data, selected, disabled, checked, dropdown } = this.state;
        const user = JSON.parse(localStorage.getItem("currentUser"))
        let toDatabase = [];

        //First checks if amber has been selected
        if (selected.amber === true) {
            let min, max, phone, text, email;

            //Adds the data that isn't disabled
            disabled.amberLow ? min = "" : min = data.amberLow;
            disabled.amberHigh ? max = "" : max = data.amberHigh;

            //Checks all the methods of communication and adds those that aren't disabled
            if (checked.notifyAmber === true) {
                if (checked.phone === true) {
                    phone = dropdown.phone;
                }

                if (checked.text === true) {
                    text = dropdown.text;
                }

                if (checked.email === true) {
                    email = dropdown.email;
                }
            }

            //Adds to an array of rules to push
            toDatabase.push({
                sensorId: sensorDisplayInfo.sensorId,
                alertLevel: 1,
                startTime: min,
                endTime: max,
                message: "Outside of parameters",
                phone: phone,
                text: text,
                email: email,
                account_Id: user.AccountId,
            })
        }

        //Same as above, but for red alert
        if (selected.red === true) {
            let min, max, phone, text, email;
            disabled.redLow ? min = "" : min = data.redLow;
            disabled.redHigh ? max = "" : max = data.redHigh;

            if (checked.notifyRed === true) {
                if (checked.phone === true) {
                    phone = dropdown.phone;
                }

                if (checked.text === true) {
                    text = dropdown.text;
                }

                if (checked.email === true) {
                    email = dropdown.email;
                }
            }

            toDatabase.push({
                sensorId: sensorDisplayInfo.sensorId,
                alertLevel: 2,
                startTime: min,
                endTime: max,
                message: "Outside of parameters",
                phone: phone,
                text: text,
                email: email,
                account_Id: user.AccountId,
            })
        }

        //If there is data to go, then post the new rule, add an event and close the modal
        if (toDatabase.length > 0) {
            apiService.postData("/rule", toDatabase)
                .then(() => {
                    const message = "New " + sensorDisplayInfo.type + " alert rule";
                    eventsService.newEvent(message, "Rules", "Create")
                        .then(() => {
                            this.toggleModal();
                        })
                })
        }
    }

    render() {
        const { sensorDisplayInfo } = this.props;
        const { checked, contact } = this.state;

        return (
            this.state.isLoading ?
                ""
                :
                <React.Fragment>
                    <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="alert-rules-modal">
                        <ModalHeader toggle={() => this.toggleModal()}>

                            <div className="icon"><Settings width={35} /></div>
                            <div className="title">{sensorDisplayInfo.type} SETTINGS</div>
                        </ModalHeader>
                        <ModalBody>
                        <strong><span>PLEASE SELECT YOUR SETTINGS FOR</span> <br />
                            {sensorDisplayInfo.type}</strong> <br />
                            <Label>Name: </Label> <br />
                            <div className="sensor-name">
                                <Input type="text" id="sensorName" value={this.state.data.sensorName} onChange={(obj) => this.handleValueChange(obj)} />
                                <Button color="primary" onClick={() => this.updateName()} >UPDATE NAME</Button> 
                                <Label className="sensor-name-confirmation">{this.state.nameConfirmation}</Label>
                            </div>
                            <hr />
                            <React.Fragment>
                                <Label>AMBER ALERT BETWEEN: </Label> <br />
                                <Label className="low-value-motion smaller-font" for="amberLow">START TIME {sensorDisplayInfo.unit}</Label>
                                <Label className="high-value-motion smaller-font" for="amberHigh">FINISH TIME {sensorDisplayInfo.unit}</Label>
                                <div className="input-toggle">
                                    <Toggle id="amber" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.amberLow} />
                                    <Input type="time" id="amberLow" className="alert-time" value={this.state.data.amberLow} {...(this.state.disabled.amberLow ? { disabled: true } : {})} onChange={(obj) => this.handleDateUpdate(obj)} />
                                    <Input type="time" id="amberHigh" className="alert-time" value={this.state.data.amberHigh} {...(this.state.disabled.amberHigh ? { disabled: true } : {})} onChange={(obj) => this.handleDateUpdate(obj)} />
                                </div> <br />
                                <Label for="redValue">RED ALERT BETWEEN: </Label> <br />
                                <Label className="low-value-motion smaller-font" for="redLow">START TIME {sensorDisplayInfo.unit}</Label>
                                <Label className="high-value-motion smaller-font" for="redHigh">FINISH TIME {sensorDisplayInfo.unit}</Label>
                                <div className="input-toggle">
                                    <Toggle id="red" onChange={(obj) => this.handleCheckedDisabled(obj)} icons={false} defaultChecked={this.state.checked.redLow} />
                                    <Input type="time" id="redLow" className="alert-time" value={this.state.data.redLow} {...(this.state.disabled.redLow ? { disabled: true } : {})} onChange={(obj) => this.handleDateUpdate(obj)} />
                                    <Input type="time" id="redHigh" className="alert-time" value={this.state.data.redHigh} {...(this.state.disabled.redHigh ? { disabled: true } : {})} onChange={(obj) => this.handleDateUpdate(obj)} />
                                </div> <br />
                            </React.Fragment>

                            NOTIFY FOR:
                            <div className="alert-to">
                                <FormGroup check className="amber-red-checkboxes">
                                    <div className="amber-check"><Input type="checkbox" id="notifyAmber" checked={checked.amber} onChange={(obj) => this.handleChecked(obj)} /> AMBER</div>
                                    <div className="red-check"><Input type="checkbox" id="notifyRed" checked={checked.red} onChange={(obj) => this.handleChecked(obj)} /> RED</div>
                                </FormGroup>
                            </div>
                            ALERT TO:
                            <div className="alert-to">
                                <FormGroup check className="checkboxes">
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="phone" className="checkbox" checked={checked.phone} onChange={(obj) => this.handleChecked(obj)} /> PHONE
                                        <Input type="select" id="phone" className="select" value={this.state.dropdown.phone} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.phone ? { disabled: true } : {})}>
                                            {contact.phone.map(phone => {
                                                return (
                                                    <option key={phone.id} value={phone.id}>{phone.firstName + " " + phone.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="text" className="checkbox" checked={checked.text} onChange={(obj) => this.handleChecked(obj)} /> TEXT
                                        <Input type="select" id="text" className="select" value={this.state.dropdown.text} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.text ? { disabled: true } : {})}>
                                            {contact.text.map(text => {
                                                return (
                                                    <option key={text.id} value={text.id}>{text.firstName + " " + text.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                    <div className="toggle-select">
                                        <Input type="checkbox" id="email" className="checkbox" checked={checked.email} onChange={(obj) => this.handleChecked(obj)} /> EMAIL
                                        <Input type="select" id="email" className="select" value={this.state.dropdown.email} onChange={obj => this.handleDropdown(obj)} {...(!this.state.checked.email ? { disabled: true } : {})}>
                                            {contact.email.map(email => {
                                                return (
                                                    <option key={email.id} value={email.id}>{email.firstName + " " + email.lastName}</option>
                                                )
                                            })}
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                            <Button color="primary" className="save-button" onClick={() => this.saveAlertRule()}>SAVE ALERT RULE</Button>
                            <hr />
                            <div>
                                Please select a reading interval
                                <Input type="select" id="interval" className="select" value={this.state.dropdown.interval} onChange={obj => this.handleIntervalDropdown(obj)} >
                                    {timeIntervals.map((interval, i) => {
                                        return (
                                            <option key={i} value={interval.ms}>{interval.minutes}</option>
                                        )
                                    })}
                                </Input>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" className="cancel-button" onClick={() => this.toggleModal()}>CANCEL</Button>
                        </ModalFooter>
                    </Modal>
                </React.Fragment>
        );
    }
}