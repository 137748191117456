import React from 'react';
import { Row, Col, Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { datetimeformatterService } from '../../services/datetimeformatter.service';
import { history } from '../../services/history.service';
import { dataService } from '../../services/data.service';
import { apiService } from '../../services/api.service';
import { processService } from '../../services/processing.service';
import { CalcPirValues } from './DetailPir/DetailPirCalculations';
import { CalcAirQualityValues } from './DetailAirQuality/DetailAirQualityCalculations';

import DetailGraph from './DetailGraph';
import DetailSingleLiveReading from './DetailSingleLiveReading';
import DetailTable from './DetailTable';
import DetailSensorHealth from './DetailSensorHealth';
import DetailHighLow from './DetailHighLow';
import DetailAverage from './DetailAverage';
import DetailActivations from './DetailPir/DetailActivations';

import DetailAirQualityGraph from './DetailAirQuality/DetailAirQualityGraph';
import DetailAirQualityLiveReading from './DetailAirQuality/DetailAirQualityLiveReading';
import DetailAirQualityHighLow from './DetailAirQuality/DetailAirQualityHighLow';
import DetailAirQualityAverage from './DetailAirQuality/DetailAirQualityAverage';
import DetailAirQualityTable from './DetailAirQuality/DetailAirQualityTable';

import DetailCoGraph from './DetailCo/DetailCoGraph';
import DetailCoChart from './DetailCo/DetailCoChart';

import DetailPirGraph from './DetailPir/DetailPirGraph';
import DetailPirTable from './DetailPir/DetailPirTable';
import DetailPirHourlyAverage from './DetailPir/DetailPirHourlyAverage';

import DetailLuxAdditional from './DetailLux/DetailLuxAdditional';
import DetailLuxLongestSegment from './DetailLux/DetailLuxLongestSegment';
import DetailPirMostActiveDay from './DetailPir/DetailPirMostActiveDay';
import DetailLuxTotal from './DetailLux/DetailLuxTotal';
import DetailEnergyEfficiency from './DetailLux/DetailEnergyEfficiency';

import AlertRulesModal from '../modals/AlertRulesModal';
import AirQualityAlertRulesModal from '../modals/AirQualityAlertRulesModal';
import MotionAlertRulesModal from '../modals/MotionAlertRulesModal';

import { ReactComponent as Humidity } from '../../icons/Humidity.svg';
import { ReactComponent as Temperature } from '../../icons/Temperature.svg';
import { ReactComponent as L8 } from '../../icons/L8Sensor.svg';
import { ReactComponent as Lux } from '../../icons/Lux.svg';
import { ReactComponent as CO } from '../../icons/CO.svg';
import { ReactComponent as PIR } from '../../icons/PIR.svg';
import { ReactComponent as AirQuality } from '../../icons/AirQuality.svg';
import { ReactComponent as WaterLevel } from '../../icons/WaterLevel.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as CalendarIcon } from '../../icons/Calendar.svg';
import { ReactComponent as Boiler } from '../../icons/Boilers.svg';
import { ReactComponent as AirHandler } from '../../icons/AirHandlingUnit.svg';
import { ReactComponent as Chiller } from '../../icons/Chillers.svg';
import { ReactComponent as WaterHeaterIcon } from '../../icons/WaterHeater.svg';
import { ReactComponent as Settings } from '../../icons/Settings.svg';

import './Detail.scss';
import DetailDownloadReadings from './DetailDownloadReadings';

export default class Detail extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            showDatePicker: false,
            sensorType: "",
            processedData: [],
            startdateValue: "",
            enddateValue: "",
            label: "",
            loadingMessage: "",
            interval: 600000,
        }
    }

    componentDidMount() {
        this.setState({ type: this.props.location.state.sensorDisplayInfo.type });

        //First get all readings for this sensor
        dataService.getReadingsBySensorId(this.props.location.state.sensorDisplayInfo.sensorId)
            .then((readings) => {
                //Check if there are any readings, if so then sort by date
                if (readings.length > 0) {
                    readings.sort(function (a, b) {
                        var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                        return dateA - dateB;
                    });

                    readings = this.splitSensorUp(readings)

                    //Separate functions for motion and air quality readings
                    if (this.state.type === "PIR") {
                        const pirValues = CalcPirValues(readings);

                        this.setState({ pirValues: pirValues });
                    }

                    if (this.state.type === "AIR QUALITY") {
                        const airQualityValues = CalcAirQualityValues(readings, this.props.location.state.sensorDisplayInfo.sensorId);

                        this.setState({ airQualityValues: airQualityValues });
                    }

                    let detailBody = this.getDetailComponents(readings);

                    this.setState({ processedData: readings, detailBody: detailBody, singleReading: readings[readings.length - 1], isLoading: false });
                } else {
                    let noData = <div className="no-data">INSUFFICIENT DATA</div>;
                    this.setState({ graph: noData, isLoading: false });
                }
            })
            .catch((error) => {
                console.log("catching error");
                console.log(error)
                this.setState({ error: true });
            })
    }

    splitSensorUp = (data) => {
        let splits = [];

        //Only takes the readings for the type we're looking at
        //This is used for something like temp/humidity where we have one sensor but split into two tiles
        data.forEach((reading, i) => {
            if (reading.type === this.state.type) {
                splits.push(reading);
            }
        });
        return (splits);
    }

    getDetailComponents = (readings) => {
        const { sensorDisplayInfo } = this.props.location.state;
        sensorDisplayInfo.alertLevel = readings[readings.length - 1].alertLevel;

        let detailBody = {
            icon: undefined,
            upperRight: undefined,
            lowerLeft: undefined,
            lowerRight: undefined,
        };
        let calcValues = [];

        //Switch case to determine which components go with which sensor types
        switch (sensorDisplayInfo.type) {
            case ("HUMIDITY"):
                calcValues = this.calcHighLowAverage(readings);
                detailBody.icon = <Humidity width={55} />;
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />
                detailBody.upperRight = <DetailHighLow peak type={sensorDisplayInfo.type} value={calcValues[0].value} date={calcValues[0].date} />;
                detailBody.lowerLeft = <DetailAverage type={sensorDisplayInfo.type} value={calcValues[2].value} colour="green" />;
                detailBody.lowerRight = <DetailHighLow type={sensorDisplayInfo.type} value={calcValues[1].value} date={calcValues[1].date} />;
                break;
            case ("TEMPERATURE"):
                calcValues = this.calcHighLowAverage(readings);
                detailBody.icon = <Temperature width={35} />
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailHighLow peak type={sensorDisplayInfo.type} value={calcValues[0].value} date={calcValues[0].date} />;
                detailBody.lowerLeft = <DetailAverage type={sensorDisplayInfo.type} value={calcValues[2].value} colour="green" />;
                detailBody.lowerRight = <DetailHighLow type={sensorDisplayInfo.type} value={calcValues[1].value} date={calcValues[1].date} />;
                break;
            case ("L8"):
                detailBody.icon = <L8 width={55} />
                calcValues = this.calcHighLowAverage(readings);
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailHighLow peak type={sensorDisplayInfo.type} value={calcValues[0].value} date={calcValues[0].date} />;
                detailBody.lowerLeft = <DetailAverage type={sensorDisplayInfo.type} value={calcValues[2].value} colour="green" />;
                detailBody.lowerRight = <DetailHighLow type={sensorDisplayInfo.type} value={calcValues[1].value} date={calcValues[1].date} />;
                break;
            case ("LIGHT"):
                detailBody.icon = <Lux width={45} />
                calcValues = this.calcHighLowAverage(readings);
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailLuxLongestSegment readings={readings} />
                detailBody.lowerLeft = <DetailEnergyEfficiency readings={readings} />
                detailBody.lowerRight = <DetailLuxTotal readings={readings} />
                break;
            case ("CO LEVEL"):
                detailBody.icon = <CO width={45} />
                calcValues = this.calcHighLowAverage(readings);
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailHighLow peak type={sensorDisplayInfo.type} value={calcValues[0].value} date={calcValues[0].date} />;
                detailBody.lowerLeft = <DetailAverage type={sensorDisplayInfo.type} value={calcValues[2].value} colour="green" />;
                detailBody.lowerRight = <DetailHighLow type={sensorDisplayInfo.type} value={calcValues[1].value} date={calcValues[1].date} />;
                break;
            case ("SOUND"):

                break;
            case ("AIR QUALITY"):
                detailBody.icon = <AirQuality width={45} />
                detailBody.liveReading = <DetailAirQualityLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />
                detailBody.upperRight = <DetailAirQualityAverage type={sensorDisplayInfo.type} values={this.state.airQualityValues} reading={readings[readings.length - 1]} sensorId={sensorDisplayInfo.sensorId} />
                detailBody.lowerLeft = <DetailAirQualityHighLow peak type={sensorDisplayInfo.type} values={this.state.airQualityValues[2]} />
                detailBody.lowerRight = <DetailAirQualityHighLow type={sensorDisplayInfo.type} values={this.state.airQualityValues[1]} />
                break;
            case ("WATER LEVEL"):
                detailBody.icon = <WaterLevel width={45} />
                calcValues = this.calcHighLowAverage(readings);
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailHighLow peak type={sensorDisplayInfo.type} value={calcValues[0].value} date={calcValues[0].date} />;
                detailBody.lowerLeft = <DetailAverage type={sensorDisplayInfo.type} value={calcValues[2].value} colour="green" />;
                detailBody.lowerRight = <DetailHighLow type={sensorDisplayInfo.type} value={calcValues[1].value} date={calcValues[1].date} />;
                break;
            case ("WATER ROPE"):
                detailBody.upperRight = <DetailActivations type={sensorDisplayInfo.type} value={readings} />;
                break;
            case ("PIR"):
                detailBody.icon = <PIR width={45} />
                detailBody.liveReading = <DetailSingleLiveReading reading={readings[readings.length - 1]} data={sensorDisplayInfo} />;
                detailBody.upperRight = <DetailActivations value={this.state.pirValues[2]} />;
                detailBody.lowerLeft = <DetailPirHourlyAverage value={this.state.pirValues[3]} />;
                detailBody.lowerRight = <DetailPirMostActiveDay value={this.state.pirValues[4]} />;
                break;
            default:
                break;
        }

        //Checks to see if it's an asset instead and changes the icon
        if (sensorDisplayInfo.isSensor === false) {
            const assetType = sensorDisplayInfo.assetType.toUpperCase();

            switch (assetType) {
                case "WATER HEATER":
                    detailBody.icon = <WaterHeaterIcon width={35} />;
                    break;
                case "BOILER":
                    detailBody.icon = <Boiler width={35} />;
                    break;
                case "CHILLER":
                    detailBody.icon = <Chiller width={35} />;
                    break;
                case "AIR HANDLING UNIT":
                    detailBody.icon = <AirHandler width={35} />;
                    break;
                default:
                    detailBody.icon = "";
                    break;
            }
        }
        return detailBody;
    }

    calcHighLowAverage = (data, time) => {
        let high = 0, low = 100000, avg, highDate, lowDate;
        var total = 0;
        for (var i = 0; i < data.length; i++) {
            //Convert current reading to float
            let currentValue = parseFloat(data[i].value)

            //Compare values to find high and low
            if (currentValue >= high) {
                high = currentValue;
                highDate = data[i].readingTime;
            }
            if (currentValue < low) {
                low = currentValue;
                lowDate = data[i].readingTime;
            }
            //Add up all the values to find the average
            total += currentValue;
        }

        //Get average to 2 decimal places
        avg = total / data.length;
        avg = avg.toFixed(2);
        avg = avg + " " + data[0].unit;
        high = high + " " + data[0].unit;
        low = low + " " + data[0].unit;

        //Formats for date and time, or just date
        if (time === true) {
            highDate = datetimeformatterService.formatDateTimeNoSeconds(highDate);
            lowDate = datetimeformatterService.formatDateTimeNoSeconds(lowDate);
        } else {
            highDate = datetimeformatterService.formatDate(highDate);
            lowDate = datetimeformatterService.formatDate(lowDate);
        }


        //Return object as well date
        let highLowAvg = [];
        highLowAvg[0] = { value: high, date: highDate }
        highLowAvg[1] = { value: low, date: lowDate }
        highLowAvg[2] = { value: avg, date: "" }

        return highLowAvg;
    }

    toggleShowDatePicker() {
        this.setState({ showDatePicker: !this.state.showDatePicker })
    }

    handleDateUpdate(event, elem) {
        this.setState({ [elem + 'dateValue']: event.target.value });
    }

    changeInterval = (interval) => {
        this.setState({ interval: interval });
    }

    getReadings = () => {
        //Comes from datepicker popout
        //Checks to make sure we have two dates
        if (this.state.startdateValue !== '' && this.state.enddateValue !== '') {
            const start = new Date(this.state.startdateValue).getTime();
            const end = new Date(this.state.enddateValue).getTime();
            
            //Makes sure the start date is before the end date
            if (start <= end) {
                //Sets loading message and sets the wheel to loading
                this.setState({ loadingMessage: "Loading your readings, please wait...", isLoading: true });

                //Do the api call for all the readings for that date range
                apiService.getData('/sensor/date?sensorId=' + this.state.singleReading.sensorId + '&startdate=' + this.state.startdateValue + '&enddate=' + this.state.enddateValue)
                    .then((readings) => {

                        //Check that there are some readings for that date range
                        if (readings.length > 0) {

                            //Process the readings like we would when we call them in normally
                            readings = processService.ProcessReading(readings);

                            //Sort by date
                            readings.sort(function (a, b) {
                                var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                                return dateA - dateB;
                            });

                            //For sensors with two tiles
                            readings = this.splitSensorUp(readings)

                            //Separate functions for motion and air quality readings
                            if (this.state.type === "PIR") {
                                const pirValues = CalcPirValues(readings, this.state.singleReading.sensorId);

                                this.setState({ pirValues: pirValues });
                            }

                            if (this.state.type === "AIR QUALITY") {
                                const airQualityValues = CalcAirQualityValues(readings);

                                this.setState({ airQualityValues: airQualityValues });
                            }

                            let detailBody = this.getDetailComponents(readings);

                            this.setState({ processedData: readings, detailBody: detailBody, singleReading: readings[readings.length - 1], showDatePicker: false, isLoading: false });
                        } else {
                            this.setState({ showDatePicker: false, isLoading: false });
                            alert("No data for that date range!");
                        }
                    })
            } else {
                alert("Please make sure start date is before end date!");
            }
        } else {
            alert("Please enter both dates!");
        }
    }

    handleBack = () => {
        history.goBack();
    }

    handleOpenModal = () => {
        const { sensorDisplayInfo } = this.props.location.state;
        let modal = "alert-rules";

        if (sensorDisplayInfo.type.toUpperCase() === "AIR QUALITY") {
            modal = "alert-rules-airquality"
        }

        if (sensorDisplayInfo.type.toUpperCase() === "PIR") {
            modal = "alert-rules-motion"
        }

        this.refs[modal].toggleModal();
    }

    render() {
        const { sensorDisplayInfo } = this.props.location.state;
        const { detailBody, isLoading, processedData, interval } = this.state;

        let headerStyles;

        if (sensorDisplayInfo.isSensor === true) {
            headerStyles = {
                backgroundColor: sensorDisplayInfo.headColour
            }
        } else {
            headerStyles = {
                backgroundColor: "#000000"
            }
        }

        return (
            <div className="detail-frame">
                <Row noGutters className="detail-header text-center" style={headerStyles}>
                    <Col lg={1} className="icon">
                        {detailBody !== undefined ?
                            detailBody.icon
                            :
                            ""}
                    </Col>
                    {sensorDisplayInfo.isSensor ?
                        <Col lg={9} className="title">
                            {sensorDisplayInfo.type} - {sensorDisplayInfo.sensorId} <br />
                            <span>{sensorDisplayInfo.name.toUpperCase()}</span>
                        </Col>
                        :
                        <Col lg={9} className="title">
                            {sensorDisplayInfo.assetType.toUpperCase()} - {sensorDisplayInfo.sensorId} <br />
                            <span>{sensorDisplayInfo.name.toUpperCase()}</span>
                        </Col>}
                    <Col lg={2} className="close-icon">
                        <div className="settings-wrapper" onClick={() => this.handleOpenModal()}>
                            <Settings width={40} />
                        </div>
                        <div className="close-icon-wrapper" onClick={() => this.handleBack()}>
                            <CloseIcon width={40} height={40} />
                        </div>
                    </Col>
                </Row>
                <div className="p-4">
                    {isLoading ?
                        <div className="loading-div-propagate">
                            <PropagateLoader size={30} /> <br /> <br />
                            <h1>{this.state.loadingMessage}</h1>
                        </div>
                        :
                        <React.Fragment>
                            <Row noGutters className="header mb-4">
                                <Col lg={12} className="asset-info">
                                    {
                                        sensorDisplayInfo.isSensor ?
                                            ""
                                            :
                                            <Row noGutters>
                                                <Col><div className="asset-info-details">MAKE<br /><strong>{sensorDisplayInfo.manufacturer} </strong></div></Col>
                                                <Col className="center-asset-info"><div className="asset-info-details">MODEL<br /><strong>{sensorDisplayInfo.model} </strong></div></Col>
                                                <Col><div className="asset-info-details">SERIAL NUMBER<br /><strong>{sensorDisplayInfo.serialNumber} </strong></div></Col>
                                            </Row>
                                    }
                                </Col>
                                <Col lg={12} className="live-reading">
                                    {detailBody.liveReading}
                                </Col>
                            </Row>
                            <Row noGutters className="info mb-2">
                                <Col lg={5}>
                                    <Row noGutters className="">
                                        <Col lg={6} className="sensor-health">
                                            <DetailSensorHealth reading={processedData[processedData.length - 1]} />
                                        </Col>
                                        <Col lg={6} className="upper-right">
                                            {detailBody.upperRight}
                                        </Col>
                                    </Row>
                                    <Row noGutters className="">
                                        <Col lg={6} className="lower-left">
                                            {detailBody.lowerLeft}
                                        </Col>
                                        <Col lg={6} className="lower-right">
                                            {detailBody.lowerRight}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={7} className="readings-table">
                                    <Row noGutters>
                                        <Col lg={6}>
                                            <h4>READINGS</h4>
                                        </Col>
                                        <Col lg={6} className="datepicker-wrapper">
                                            <u>{datetimeformatterService.formatDate(this.state.processedData[0].readingTime)}</u>&nbsp;-&nbsp;<u>{datetimeformatterService.formatDate(this.state.singleReading.readingTime)}</u>
                                        &nbsp; <CalendarIcon type="button" onClick={() => this.toggleShowDatePicker()} id="openPop" className="reading-modal-cal" width={35} height={35} />
                                            <Popover trigger="legacy" placement="bottom" isOpen={this.state.showDatePicker} target="openPop" toggle={() => this.toggleShowDatePicker()}>
                                                <PopoverHeader>Date Range</PopoverHeader>
                                                <PopoverBody>
                                                    Start Date: <input type="date" onChange={(e) => this.handleDateUpdate(e, "start")} className="form-control" />
                                                    <br />
                                                    End Date: <input type="date" onChange={(e) => this.handleDateUpdate(e, "end")} className="form-control" />
                                                    <br />
                                                    <Button onClick={() => this.getReadings()} className="btn sps-dk-rrb btn-block">Get Readings</Button>
                                                </PopoverBody>
                                            </Popover>
                                        </Col>
                                    </Row>
                                    <Row noGutters className="mb-3 pb-2 sensor-message-info">
                                        <Col lg={8}>
                                            Last message: {datetimeformatterService.formatDateTime(this.state.singleReading.readingTime)}
                                        </Col>
                                        <Col lg={4}>
                                            <DetailDownloadReadings data={processedData} type={sensorDisplayInfo.type} />
                                        </Col>
                                    </Row>
                                    {
                                        this.state.processedData[0].type !== "AIR QUALITY" ?
                                            this.state.processedData[0].type !== "PIR" ?
                                                <DetailTable interval={interval} readings={processedData} type={sensorDisplayInfo.type} />
                                                :
                                                <DetailPirTable interval={interval} readings={this.state.pirValues[1]} type={sensorDisplayInfo.type} />

                                            :
                                            <DetailAirQualityTable interval={interval} readings={processedData} type={sensorDisplayInfo.type} />
                                    }

                                </Col>
                            </Row>
                            {sensorDisplayInfo.type === "LIGHT" ?
                                <Row noGutters className="lux-additionals">
                                    <DetailLuxAdditional readings={processedData} calcValues={this.calcHighLowAverage(this.state.processedData, true)} />
                                </Row>
                                :
                                ""
                            }
                            <Row noGutters className="readings-graph">
                                <Col lg={9}>
                                    <h4>READINGS GRAPH</h4>
                                </Col>
                                <Col lg={3}>
                                    {datetimeformatterService.formatDate(this.state.processedData[0].readingTime) + " - " + datetimeformatterService.formatDate(this.state.singleReading.readingTime)}
                                </Col>
                                {
                                    this.state.processedData[0].type !== "AIR QUALITY" ?
                                        this.state.processedData[0].type !== "PIR" ?
                                            this.state.processedData[0].type !== "CO LEVEL" ?
                                                <DetailGraph data={this.state.processedData} type={sensorDisplayInfo.type} />
                                                :
                                                <DetailCoGraph data={this.state.processedData} type={sensorDisplayInfo.type} />
                                            :
                                            <DetailPirGraph data={this.state.pirValues[0]} type={sensorDisplayInfo.type} />
                                        :
                                        <DetailAirQualityGraph data={this.state.processedData} type={sensorDisplayInfo.type} />
                                }

                            </Row>

                            <Row noGutters className="co-table">
                                {
                                    this.state.type === "CO LEVEL" ?
                                        <DetailCoChart />
                                        :
                                        ""
                                }
                            </Row>
                            {
                                this.state.type !== "PIR" ?
                                    this.state.type !== "AIR QUALITY" ?
                                        <AlertRulesModal sensorDisplayInfo={sensorDisplayInfo} changeInterval={this.changeInterval} ref="alert-rules" />
                                        :
                                        <AirQualityAlertRulesModal sensorDisplayInfo={sensorDisplayInfo} changeInterval={this.changeInterval} ref="alert-rules-airquality" />
                                    :
                                    <MotionAlertRulesModal sensorDisplayInfo={sensorDisplayInfo} changeInterval={this.changeInterval} ref="alert-rules-motion" />
                            }
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}