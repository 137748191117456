import React from 'react';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Brush, CartesianGrid, Legend, LineChart, Line } from 'recharts';
import { datetimeformatterService } from '../../../services/datetimeformatter.service';

export default class DetailGraph extends React.Component {

    constructor() {
        super()

        this.state = {
            type: undefined,
            graph: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.setState({ type: this.props.type, isLoading: true });

        this.createGraphs(this.props.data);
    }

    createGraphs = (processedData) => {
        const graphData = this.processGraphData(processedData);

        let completeGraph =
            <ResponsiveContainer height="90%">
                <LineChart data={graphData} margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                    <XAxis dataKey="name" />
                    <YAxis />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Tooltip formatter={(label) => label + " ppm"} />
                    <Legend layout="horizontal" verticalAlign="top" align="center" />
                    <Line type="monotone" dataKey="CO" stroke="#85B6BA" dot={false} />
                    <Line type="monotone" dataKey="TWA" stroke="#C73F3E" dot={false} />
                    <Brush dataKey="name" height={30} stroke="#1A355C" />
                </LineChart>
            </ResponsiveContainer>

        this.setState({ graph: completeGraph, isLoading: false });
    }

    processGraphData = (processedData) => {
        let data = [];

        // Creates an array of graph data based on the sensor name matching the style name from dashboard
        processedData.forEach((reading, i) => {
            if (reading.type === this.props.type) {
                const splitValues = reading.value.split('|');
                let time = datetimeformatterService.formatDateTimeNoSeconds(reading.readingTime);

                data.push({ name: time, "CO": parseFloat(splitValues[0]), "TWA": parseFloat(splitValues[1]) });
            }
        })
        return data;
    }

    render() {
        return (
            this.state.isLoading ?
                ""
                :
                this.state.graph
        )
    }
}