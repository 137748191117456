//Local DB config
// export const apiUrl = "https://localhost:5001/v1";

//Development config
// export const apiUrl = "https://sps-dev-api.azurewebsites.net/v1";
// export const versionNumber = "1.9.2";

//Production config
//export const apiUrl = "https://sps-prod-api.azurewebsites.net/v1";
export const apiUrl = "https://api.smartplant.systems/v1";
export const versionNumber = "";

//MaxMind key
// R0GsKJQxjxTudBpb

export const config = {
    apiUrl,
    versionNumber,
}
