const sort = (flatCards, sortType) => {
    return new Promise(resolve => {

        //Catch it the first time
        if (sortType === undefined) {
            sortType = "A-Z"
        }
        
        //pick type of sorting
        switch (sortType) {
            case "A-Z":
                flatCards = flatCards.sort((a, b) => {
                    let nameA = a.name.toUpperCase();
                    let nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                break;
            case "Z-A":
                flatCards = flatCards.sort((a, b) => {
                    let nameA = b.name.toUpperCase();
                    let nameB = a.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                break;
            default:
                flatCards = flatCards.sort((a, b) => {
                    let nameA = a.name.toUpperCase();
                    let nameB = b.name.toUpperCase();

                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                break;
        }

        return resolve(flatCards);
    })
}

export const sortCards = {
    sort,
} 