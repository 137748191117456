import React from 'react';
import imageCompression from 'browser-image-compression';
import { history } from '../../../services/history.service';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { apiService } from '../../../services/api.service';
import { cacheService } from '../../../services/cache.service';
import { eventsService } from '../../../services/events.service';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { Mixpanel } from '../../../Mixpanel';
import './Assets.scss';

import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as  AssetList } from '../../../icons/AssetList.svg';

export const conditions = ["Action Required", "Service Needed", "Working as Intended"];
export const types = ["Boiler", "Water Heater", "Air Handling Unit", "Chiller", "Other"];

export default class AssetDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dropdownOptions: [],
            file: null,
            button: "secondary",
            errorText: "",
            uploadText: "Optionally upload a picture of the asset here",
            rooms: [],
            imageChanged: false,
            isChanged: false,
            disabled: true,
            dropdown: {
                assetType: "",
                assetRoom: "",
                assetCondition: "",
                assetSensor: "",
            },
            data: {
                assetName: "",
                assetManufacturer: "",
                assetModel: "",
                assetNumber: "",
                assetNotes: "",
            },
            validate: {
                assetNameState: "",
                assetNumberState: "",
            }

        }
    }

    componentDidMount() {
        const { site, room } = this.props.location.state;

        if (site !== undefined) {
            dataService.getRoomsBySiteId(site)
                .then(rooms => {
                    var roomNames = rooms.map(room => {
                        return room.name;
                    });

                    Promise.all(roomNames)
                        .then(roomNames => {
                            this.setInitialStates(this.props.location.state, rooms, roomNames)
                        });
                })
        } else if (room !== undefined) {
            //get room by room id and then get ther site id, set that to state
            dataService.getRoomById(room)
                .then(room => {
                    dataService.getRoomsBySiteId(room.site_Id)
                        .then(rooms => {
                            var roomNames = rooms.map(room => {
                                return room.name;
                            });

                            Promise.all(roomNames)
                                .then(roomNames => {
                                    this.setInitialStates(this.props.location.state, rooms, roomNames)
                                });
                        })
                })
        }
    }

    setInitialStates(props, rooms, roomNames) {
        const { asset, update, site, room } = props;

        let sensorList = [{
            sensorId: null,
            name: "Not Assigned"
        }]

        dataService.getTilesByLocationId(site, room)
            .then(tiles => {
                var tileNames = tiles.map(tile => {
                    let tileName = {
                        sensorId: tile.sensorId,
                        name: tile.name
                    }
                    return tileName;
                })

                Promise.all(tileNames)
                    .then(tileNames => {
                        sensorList.push(...tileNames)

                        //Check if new asset or updating
                        if (update === false) {
                            //Creating a new asset
                            this.setState({ file: null, tiles: tiles, sensorList: sensorList, dropdownOptions: roomNames, rooms: rooms, dropdown: { assetRoom: rooms[0].name, assetCondition: conditions[0], assetSensor: sensorList[0] }, disabled: false, isLoading: false })
                        } else if (update === true) {
                            //Updating an asset

                            //Get picture in usuable format
                            let file = undefined, image = undefined, sensor;
                            if (asset.image !== undefined && asset.image !== "" && asset.image !== null) {
                                image = asset.image;
                                file = `data:image/;base64,${asset.image}`;
                            }

                            //If an asset has a sensor Id attached find which one
                            if (asset.sensorId !== null) {
                                const sensorIdIndex = sensorList.findIndex(sensor => sensor.sensorId === asset.sensorId);
                                sensor = tiles[sensorIdIndex - 1].sensorId;
                            } else {
                                sensor = sensorList[0];
                            }


                            this.setState({
                                dropdownOptions: roomNames,
                                rooms: rooms,
                                sensorList: sensorList,
                                tiles: tiles,
                                image: image,
                                file: file,
                                dropdown: {
                                    assetType: asset.type,
                                    assetRoom: asset.location,
                                    assetCondition: asset.assetCondition,
                                    assetSensor: sensor,
                                },
                                data: {
                                    assetName: asset.name,
                                    assetManufacturer: asset.manufacturer,
                                    assetModel: asset.model,
                                    assetNumber: asset.serialNumber,
                                    assetNotes: asset.notes,
                                },

                                isLoading: false
                            })
                        }
                    })
            })
    }

    handleOpenModal = (modal) => {
        this.refs[modal].toggleModal();
    }

    handleTextChange = (obj) => {
        this.handleValid(obj)
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleValid = (obj) => {
        const { validate } = this.state;
        if (obj.target.value.length > 0) {
            validate[`${obj.target.id}State`] = "valid";
        } else if (obj.target.value.length < 1) {
            validate[`${obj.target.id}State`] = "invalid";
        }

        this.setState({ validate: validate })
    }

    loadFile = (event) => {
        if (event.target.files[0] !== undefined) {
            if (event.target.files[0].type === "image/jpeg" || event.target.files[0].type === "image/png") {
                this.setState({ file: URL.createObjectURL(event.target.files[0]), image: event.target.files[0], uploadText: "Optionally upload a picture of the asset here", imageChanged: true, isChanged: true });
            } else {
                this.setState({ file: null, image: undefined, uploadText: "Only PNG and JPEG files supported!" });
            }

        } else {
            this.setState({ file: null, image: undefined });
        }
    };

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = obj.target.value;

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleCancel = () => {
        if (this.state.isChanged) {
            this.handleOpenModal("confirm-no-change");
        } else {
            history.goBack();
        }
    }

    handleSave = () => {
        if (this.state.isChanged === true) {
            this.setState({ isLoading: true })
            this.saveDetails();
        } else if (this.state.isChanged === false) {
            this.setState({ button: "danger", errorText: "You've not made any changes!" });
        }
    }

    allowEdit = () => {
        this.setState({
            validate: {
                assetNameState: "valid",
                assetNumberState: "valid",
            },
            disabled: false
        })
    }

    getBase64(file, cb) {

        // console.log('originalFile instanceof Blob', file instanceof Blob); // true
        // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);

        var options = {
            maxSizeMB: 0.25,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        //Run compression library function, before converting to base64 and returning to be posted
        imageCompression(file, options)
            .then(function (compressedFile) {
                // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

                let reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = function () {
                    cb(reader.result);
                }
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }

    saveDetails = () => {
        const { data, validate, dropdown } = this.state, user = JSON.parse(localStorage.getItem("currentUser"));
        let toDatabase = [];
        //Checks to see if every required field is valid
        if (Object.values(validate).every(field => field === "valid")) {

            const roomIdIndex = this.state.rooms.findIndex(room => room.name === dropdown.assetRoom);
            toDatabase = {
                name: data.assetName,
                type: dropdown.assetType,
                location: dropdown.assetRoom,
                assetCondition: dropdown.assetCondition,
                manufacturer: data.assetManufacturer,
                model: data.assetModel,
                serialNumber: data.assetNumber,
                sensorId: parseInt(dropdown.assetSensor),
                account_Id: user.AccountId,
                room_Id: this.state.rooms[roomIdIndex].id,
                notes: data.assetNotes,
                image: undefined,
            }

            if (this.state.image !== undefined && this.state.imageChanged === true) {
                this.getBase64(this.state.image, (result) => {
                    const splits = result.split("base64,");

                    toDatabase.image = splits[1];

                    if (this.props.location.state.update === false) {
                        this.postToDatabase(toDatabase);
                    } else if (this.props.location.state.update === true) {
                        this.patchToDatabase(toDatabase);
                    }

                })
            } else {
                if (this.props.location.state.update === false) {
                    this.postToDatabase(toDatabase);
                } else if (this.props.location.state.update === true) {
                    this.patchToDatabase(toDatabase);
                }
            }

            this.setState({ button: "secondary", errorText: "" });

        } else {
            this.setState({ button: "danger", errorText: "Please fill in all mandatory fields!", isLoading: false });
        }
    }

    postToDatabase = (newAsset) => {
        apiService.postData("/asset", newAsset)
            .then(() => {
                cacheService.updateAssets()
                    .then(() => {
                        const message = "New asset: " + newAsset.name + " - " + newAsset.type;
                        eventsService.newEvent(message, "Assets", "Create")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }

    patchToDatabase = (asset) => {
        asset.id = this.props.location.state.asset.id;
        apiService.patchData("/asset", asset)
            .then(() => {
                cacheService.updateAssets()
                    .then(() => {
                        const message = "Updated asset: " + asset.name + " - " + asset.type;
                        eventsService.newEvent(message, "Assets", "Update")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }

    deleteFromDatabase = (id) => {
        this.setState({ isLoading: true });
        apiService.deleteData("/asset?id=" + id)
            .then(() => {
                cacheService.updateAssets()
                    .then(() => {
                        const message = "Removed asset: " + this.state.data.assetName + " - " + this.state.dropdown.assetType;
                        eventsService.newEvent(message, "Assets", "Delete")
                            .then(() => {
                                history.goBack();
                            })
                    })
            });
    }


    render() {
        Mixpanel.track('Asset Details');
        const { dropdownOptions } = this.state;

        return (
            this.state.isLoading ?
                ""
                :
                <div className="asset-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<AssetList width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Assets
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => this.handleCancel()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <Form className="form">
                        <Row>
                            <Col>
                                <div className="image-wrapper">
                                    <img className="image" id="assetPictureOutput" src={this.state.file} alt="" />
                                </div>
                                <FormGroup className="file-upload">
                                    <Label for="assetPicture">File</Label>
                                    <Input type="file" accept="image/jpeg, image/png" name="file" id="assetPicture" onChange={this.loadFile} {...(this.state.disabled ? { disabled: true } : {})} />
                                    <FormText color="muted">
                                        {this.state.uploadText}
                                    </FormText>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="assetSensor" >Sensor</Label>
                                    <Input type="select" name="sensor" id="assetSensor" value={this.state.dropdown.assetSensor} onChange={obj => this.handleDropdown(obj)} {...(this.state.disabled ? { disabled: true } : {})}>
                                        {this.state.sensorList.map((option, i) => {
                                            return (
                                                option.sensorId !== null ?
                                                    <option key={i} value={option.sensorId}>{option.sensorId + " - " + option.name}</option>
                                                    :
                                                    <option key={i} value={null}>{option.name}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Row className="delete-row">
                                    <div className="edit-delete">
                                        {this.props.location.state.update ?
                                            <Button className="delete-button" color="secondary" onClick={() => this.handleOpenModal("confirm-delete")}>Remove Asset</Button>
                                            :
                                            ""}
                                        <span> </span>
                                        {this.state.disabled ?
                                            <Button className="edit-button" color="secondary" onClick={() => this.allowEdit()}>Edit Asset</Button> : ""}
                                    </div>
                                </Row>
                                <FormGroup>
                                    <Label className="asset-label" for="assetName" >Name*</Label>
                                    <Input type="text" name="name" id="assetName" placeholder="Asset Name" value={this.state.data.assetName} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} valid={this.state.validate.assetNameState === "valid"} invalid={this.state.validate.assetNameState === "invalid"} />
                                    {/* <FormFeedback invalid="true">
                                        Please enter an asset name
                                    </FormFeedback> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetType" >Type*</Label>
                                    <Input type="select" name="type" id="assetType" placeholder="Type" value={this.state.dropdown.assetType} onChange={obj => this.handleDropdown(obj)} {...(this.state.disabled ? { disabled: true } : {})} >
                                        {types.map((option, i) => {
                                            return (
                                                <option key={i} value={option}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetManufacturer" >Manufacturer</Label>
                                    <Input type="text" name="manufacturer" id="assetManufacturer" placeholder="Manufacturer" value={this.state.data.assetManufacturer} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetModel" >Model</Label>
                                    <Input type="text" name="model" id="assetModel" placeholder="Model" value={this.state.data.assetModel} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetNumber" >Serial/Part Number*</Label>
                                    <Input type="text" name="serial" id="assetNumber" placeholder="Serial/Port" value={this.state.data.assetNumber} onChange={obj => this.handleTextChange(obj)} {...(this.state.disabled ? { disabled: true } : {})} valid={this.state.validate.assetNumberState === "valid"} invalid={this.state.validate.assetNumberState === "invalid"} />
                                    {/* <FormFeedback invalid="true">
                                        Please enter a serial number
                                    </FormFeedback> */}
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetRoom" >Room*</Label>
                                    <Input type="select" name="room" id="assetRoom" value={this.state.dropdown.assetRoom} onChange={obj => this.handleDropdown(obj)} {...(this.state.disabled ? { disabled: true } : {})}>
                                        {dropdownOptions.map((option, i) => {
                                            return (
                                                <option key={i}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="asset-label" for="assetCondition" >Condition*</Label>
                                    <Input type="select" name="condition" id="assetCondition" value={this.state.dropdown.assetCondition} onChange={obj => this.handleDropdown(obj)}>
                                        {conditions.map((option, i) => {
                                            return (
                                                <option key={i}>{option}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="">Notes</Label>
                                    <Input type="textarea" name="notes" id="assetNotes" placeholder="Notes" value={this.state.data.assetNotes} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10}>
                            </Col>
                            <Col xs={2}>
                                <Button className="cancel-button" color={this.state.button} onClick={() => this.handleSave()}>Update</Button>
                                <FormText>
                                    {this.state.errorText}
                                </FormText>
                            </Col>
                        </Row>
                    </Form>
                    <ConfirmationModal titleText="Delete Asset?" buttonText="Delete" bodyText="delete this asset" className="confirm-delete-asset" function={() => this.deleteFromDatabase(this.props.location.state.asset.id)} ref="confirm-delete" />
                    <ConfirmationModal titleText="Get rid of changes?" buttonText="Confirm" bodyText="go back without saving your changes" className="confirm-nochanges-asset" function={() => history.goBack()} ref="confirm-no-change" />
                </div>
        )
    }
}