import React from 'react';
import { Col, Row, Button, Card, CardTitle, CardText, Spinner } from 'reactstrap';
import { history } from '../../services/history.service';

import './Admin.scss';

export default class AdminLanding extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        if (this.checkAdmin()) {
            //carry on
            this.setState({ isLoading: false });
        } else {
            history.goBack();
        }
    }

    checkAdmin() {
        const user = JSON.parse(localStorage.getItem("currentUser"))

        if (user.role === "Admin") {
            return true;
        } else {
            return false;
        }
    }


    render() {

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="admin-landing">
                    <Row>
                        <Col xs={6}>
                            <Card body className="text-center">
                                <CardTitle tag="h5">Admin</CardTitle>
                                <CardText>Manage your sensors and users</CardText>
                                <Button href="/siteadmin">Go</Button>
                            </Card>
                        </Col>
                        <Col xs={6}>
                        <Card body className="text-center">
                                <CardTitle tag="h5">SPS Admin</CardTitle>
                                <CardText>Manage on-boarding here, add sites, rooms and sensors to an account</CardText>
                                <Button href="/spsadmin">Go</Button>
                            </Card>
                        </Col>
                    </Row>
                </div>
        )
    }
}