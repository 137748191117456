import React from 'react';
import { Row, Col } from 'reactstrap';

import { datetimeformatterService } from '../../../services/datetimeformatter.service';

import { ReactComponent as Lux } from '../../../icons/Lux.svg';
import { ReactComponent as Hourglass } from '../../../icons/Hourglass.svg';

export default class DetailLuxAdditional extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            dailyTime: "",
        }
    }

    componentDidMount() {
        this.calculations(this.props.readings)
    }

    calculations = (readingss) => {
        let readings = readingss.slice();

        let start = null, end = null, endChanged = false, segments = [], averageOn = 0, onCount = 0, averageOff = 0, offCount = 0, days = [], dayStart, dayAway, dayStartIndex, dayEndIndex;
        // let total = 0;

        //If i > 5 and i+1 > 5 then keep going. Measure end when current < 5 ()
        for (let i = 0; i < readings.length; i++) {
            const currentValue = parseInt(readings[i].value);

            //Check if the lights are on or not, 5 lux being considered on
            if (currentValue > 5) {
                averageOn += currentValue;
                onCount++;
            } else if (currentValue <= 5) {
                averageOff += currentValue;
                offCount++;
            }

            //Beginning start value
            if (currentValue > 5 && start === null) {
                start = i;
            } else if (readings[i] !== undefined && readings[i - 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i - 1].value) < 5) {
                    start = i;
                }
            }

            //This is the end of a section of lights on
            if (readings[i] !== undefined && readings[i + 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i + 1].value) <= 5) {
                    end = i;
                    endChanged = true;
                }
            }

            //Once it reaches the end of the array, check if it needs to end
            if (readings[i + 1] === undefined && currentValue > 5) {
                end = i;
                endChanged = true;
            } else if (readings[i + 1] === undefined && parseInt(readings[i - 1].value) > 5) {
                end = i - 1;
                endChanged = true;
            }

            //Get the dates from milliseconds from start and end, end-start and add to running total 
            if (endChanged === true && end > start) {
                const startTime = readings[start].readingTime;
                const endTime = readings[end].readingTime;
                const segment = endTime - startTime;

                segments.push(segment);

                // total += segment;

                endChanged = false;
            }

            let current = readings[i].readingTime.getTime();
            //Grab days
            if (dayStart === undefined) {
                dayStart = current;
                dayStartIndex = i;
                dayAway = dayStart + 86400000;
            } else if (dayStart !== undefined) {
                //Once a day has passed, grab the indexes of the start and end of an day
                if (current > dayAway) {
                    dayEndIndex = i;

                    const dayTemp = { dayStart: dayStartIndex, dayEnd: dayEndIndex };
                    days.push(dayTemp);

                    dayStart = current;
                    dayStartIndex = i;
                    dayAway = dayStart + 86400000;
                }
            }
        }

        let totalSegment = 0, averageSegment, longest = 0;
        //Calculate segment lengths and average
        segments.forEach(time => {
            totalSegment += time;
            if (time > longest) {
                longest = time;
            }
        });
        averageSegment = totalSegment / segments.length;

        //Do the same calculations to get time on, but for single days
        let dailyTimeOn = [];
        days.forEach(day => {
            let dailyTotal = 0, dailyStart = null, dailyEnd = null, dailyEndChanged = false;
            for (let i = day.dayStart; i <= day.dayEnd; i++) {
                const currentValue = parseInt(readings[i].value);

                //Beginning start value
                if (currentValue > 5 && dailyStart === null) {
                    dailyStart = i;
                } else if (readings[i] !== undefined && readings[i - 1] !== undefined) {
                    if (currentValue > 5 && parseInt(readings[i - 1].value) < 5) {
                        dailyStart = i;
                    }
                }

                //This is the end of a section of lights on
                if (readings[i] !== undefined && readings[i + 1] !== undefined) {
                    if (currentValue > 5 && parseInt(readings[i + 1].value) <= 5) {
                        dailyEnd = i;
                        dailyEndChanged = true;
                    }
                }

                //Once it reaches the end of the array, check if it needs to end
                if (readings[i + 1] === undefined && currentValue > 5) {
                    dailyEnd = i;
                    dailyEndChanged = true;
                } else if (readings[i + 1] === undefined && parseInt(readings[i - 1].value) > 5) {
                    dailyEnd = i - 1;
                    dailyEndChanged = true;
                }

                //Get the dates from milliseconds from start and end, end-start and add to running total 
                if (dailyEndChanged === true && dailyEnd > dailyStart) {
                    const startTime = readings[dailyStart].readingTime;
                    const endTime = readings[dailyEnd].readingTime;
                    const segment = endTime - startTime;

                    dailyTotal += segment;

                    dailyEndChanged = false;
                }
            } 
            dailyTimeOn.push(dailyTotal);
        })
        //For each dailytimeon segment get the total time on and divide by days
        let timeOnTotal = 0;
        dailyTimeOn.forEach(day => {
            timeOnTotal += day;
        })
        let dailyTimeOnAverage = timeOnTotal / dailyTimeOn.length;

        //Get averages for on and off value
        let onValue = averageOn / onCount;
        let offValue = averageOff / offCount;
        onValue = onValue.toFixed(2);
        offValue = offValue.toFixed(2);

        
        //convert milliseconds back to useable time
        const time = datetimeformatterService.convertMilliseconds(dailyTimeOnAverage);
        let averageSeg = datetimeformatterService.convertMilliseconds(averageSegment);
        const longestSeg = datetimeformatterService.convertMilliseconds(longest);

        //Add x days worth of hours onto hours
        if(longestSeg.day !== undefined) {
            longestSeg.hour = longestSeg.hour + (longestSeg.day * 60);
        }

        if (Number.isNaN(averageSegment)) {
            averageSeg = {
                hour: 0,
                minute: 0
            }
        }

        if (onValue === "NaN") {
            onValue = 0;
        }

        this.setState({ dailyTime: time, averageSegment: averageSeg, longestSegment: longestSeg, averageOnValue: onValue, averageOffValue: offValue, isLoading: false });
    }

    render() {
        const { calcValues } = this.props;

        return (
            this.state.isLoading ?
                ""
                :
                <div className="lux-additionals-tiles">
                    <Row>
                        <Col className="tile">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            ▲ <Lux width={35} />
                                        </td>
                                        <td>
                                            HIGH LUX <br />
                                            {calcValues[0].value}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="tile">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            ▲ <Hourglass width={35} />
                                        </td>
                                        <td>
                                            HIGH TIME <br />
                                            {calcValues[0].date}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="tile">
                            AVERAGE TIME ON DURATION<br />
                            {this.state.averageSegment.hour + " hrs " + this.state.averageSegment.minute + " mins"}
                        </Col>
                        <Col className="tile">
                            AVERAGE TIME ON PER DAY <br />
                            {this.state.dailyTime.hour + " hrs " + this.state.dailyTime.minute + " mins"}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="tile">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            ▼ <Lux width={35} />
                                        </td>
                                        <td>
                                            LOW LUX <br />
                                            {calcValues[1].value}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="tile">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            ▲ <Hourglass width={35} />
                                        </td>
                                        <td>
                                            LOW TIME <br />
                                            {calcValues[1].date}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col className="tile">
                            AVERAGE LUX WHEN ON <br />
                            {this.state.averageOnValue}
                        </Col>
                        <Col className="tile">
                            AVERAGE LUX WHEN OFF <br />
                            {this.state.averageOffValue}
                        </Col>
                    </Row>
                </div>
        )
    }
}