import React from 'react'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner, Row, Col } from 'reactstrap'
import { twilioService } from '../../services/twilio.service';

import './Modal.scss';
import { apiService } from '../../services/api.service';

export default class CallBox extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
            phone: "",
            isLoading: true,
        }

        this.connectCall = this.connectCall.bind(this);
        this.disconnectCall = this.disconnectCall.bind(this);
    }

    componentDidMount() {
        this.getActiveClients();
    }

    getActiveClients() {
        this.setState({ isLoading: true })

        //Get current user
        let user = JSON.parse(localStorage.getItem("currentUser"));

        //Checks to see if twilio is set up, if not run set up before carrying on
        if (localStorage.getItem("TwilioIdentity") === null || twilioService.device._status === "offline") {
            return twilioService.setupTwilio().then(() => {
                apiService.getData("/twilio/active?accountId=" + user.AccountId).then((active) => {
                    this.generateBoxes(active);
                })
            })
        } else {
            apiService.getData("/twilio/active?accountId=" + user.AccountId).then((active) => {
                this.generateBoxes(active);
            })
        }
    }

    generateBoxes(active) {
        //First checks if we have a twilio identity
        if (localStorage.getItem("TwilioIdentity") !== null) {
            let twilioId = localStorage.getItem("TwilioIdentity").toString();
            let boxes = [];

            //If any currently active accounts have a -BOX in their name, generate a button to call them
            active.forEach(box => {
                if (box.clientIdentifier.includes("-BOX") && box.clientIdentifier !== twilioId) {
                    let temp =
                        <div>
                            <Button onClick={(obj) => this.connectCall(obj.target.id)} id={box.clientIdentifier} >{box.clientIdentifier}</Button>
                        </div>

                    boxes.push(temp);
                }
            });
            this.setState({ boxes: boxes, active: active, isLoading: false })
        }
    }

    connectCall = (contact) => {

        //Choose how to call someone, number or name
        if (contact.includes("-BOX")) {
            twilioService.device.connect({ "To": contact, "From": localStorage.getItem("TwilioIdentity") });
        } else {
            let formattedNum = '+44' + contact.substr(1)
            twilioService.device.connect({ "To": formattedNum, "From": localStorage.getItem("TwilioIdentity") });
        }
    }

    disconnectCall = () => {
        // this.setState({ numInputVis: false, onSiteVis: false, officeVis: false });
        twilioService.device.disconnectAll();
    }

    toggleModal(type) {
        this.setState({ isOpen: !this.state.isOpen, type: type });
    }

    render() {
        // const { boxes } = this.state;

        return (
            <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className="callbox-modal">
                <ModalHeader toggle={() => this.toggleModal()}>CALL BOX</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={10} className="box-body">
                            CALL AN <br /> SPS BOX: <br />
                        </Col>
                        <Col xs={2} className="box-refresh">
                            <div className="box-refresh-icon" onClick={() => this.getActiveClients()}>⟳</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.isLoading ?
                                <div>
                                    <Spinner className="callbox-loading" />
                                </div>
                                :
                                // boxes.length > 0 ?
                                //     <React.Fragment>
                                //         {boxes}
                                //     </React.Fragment>
                                //     :
                                    <div className="no-boxes">There are no boxes available!</div>
                            }
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    {twilioService.device._status === "busy" &&
                        <Button className="callbox-confirm" onClick={() => this.disconnectCall()}>DISCONNECT</Button>
                    }
                    <Button onClick={() => this.toggleModal()}>CANCEL</Button>
                </ModalFooter>
            </Modal>
        )
    }
}