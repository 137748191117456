import React from 'react';
import { history } from '../../../services/history.service';
import { Alert, Col, Row, Spinner } from 'reactstrap';
import { dataService } from '../../../services/data.service';

import { ReactComponent as  CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as TestSensorsIcon } from '../../../icons/TestSensors.svg';
import { Mixpanel } from '../../../Mixpanel';
import './TestSensors.scss'

export default class TestSensors extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
        }
    }


    componentDidMount() {
        const { site, room } = this.props.location.state;

        //Get site sensors
        if (site !== undefined) {
            dataService.getSiteById(site)
                .then(siteData => {
                    dataService.getSensorsBySiteId(site)
                        .then(sensors => {
                            this.checkSensors(sensors, siteData);
                        })
                })
        //Get site sensors by room
        } else if (room !== undefined) {
            dataService.getRoomById(room)
                .then(roomData => {
                    dataService.getSiteById(roomData.site_Id)
                        .then(siteData => {
                            dataService.getSensorsBySiteId(siteData.id)
                                .then(sensors => {
                                    this.checkSensors(sensors, siteData)
                                })
                        })
                })
        }
    }

    checkSensors(sensors, site) {
        const timeNow = new Date();
        const testTime = timeNow - 3600000; // - 1 hour //1200000 - 20 minutes
        sensors = sensors.flat()

        var sensorHealthy = sensors.map(sensor => {
            return dataService.getReadingsBySensorId(sensor.sensorId)
                .then(readings => {
                    //Sort by date
                    readings.sort(function (a, b) {
                        var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                        return dateB - dateA;
                    });
                    //Check if there are any readings, or if there are readings within the time limit 
                    if (readings[0] !== undefined && readings[0].readingTime.getTime() > testTime) {
                        return true;
                    } else {
                        return false;
                    }
                })
        })

        Promise.all(sensorHealthy)
            .then(sensorHealthy => {

                this.setState({ sensors: sensors, sensorHealthy: sensorHealthy, site: site, isLoading: false })
            })
    }


    render() {
        Mixpanel.track('Test Sensors');
        const { sensors, sensorHealthy, site } = this.state;
        let healthy;
        if (this.state.isLoading === false) {
            healthy = sensorHealthy.filter(Boolean).length;
        }


        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="testsensors-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<TestSensorsIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Test Sensors
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <div className="test-body">
                        <Row className="top-text text-center">
                            <Col>
                                <h1>{sensors.length} sensors found for site {site.id}, {site.name}</h1>
                            </Col>
                        </Row>
                        <Row className="top-text text-center">
                            <Col>
                                <h1> {healthy}/{sensorHealthy.length} sensors healthy </h1>
                            </Col>
                        </Row>
                        {
                            sensors.map((sensor, i) => {
                                if (sensorHealthy[i] === true) {
                                    return (
                                        <Alert fade={true} color="success" className="sensor-alert">
                                            <span> {sensor.type} - {sensor.name} - {sensor.sensorId} - HEALTHY </span>
                                        </Alert>
                                    )
                                } else {
                                    return (
                                        <Alert fade={true} color="danger" className="sensor-alert">

                                            <span> {sensor.type} - {sensor.name} - {sensor.sensorId} - NOT HEALTHY </span>
                                        </Alert>
                                    )
                                }
                            })
                        }

                    </div>
                </div>
        )
    }
}