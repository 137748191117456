import React from 'react';
import DataTable from 'react-data-table-component';
import { apiService } from '../../services/api.service';
import { history } from '../../services/history.service';
import { Button, Row, Col, Spinner } from 'reactstrap';

import { ReactComponent as  CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as  ContactsIcon } from '../../icons/Contacts.svg';
import { Mixpanel } from '../../Mixpanel';
import './Contacts.scss'



export default class Contacts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("currentUser"))
        apiService.getData("/contact")
            .then((allContacts) => {
                let contacts = [];

                allContacts.forEach(contact => {
                    if (contact.accountId === user.AccountId) {
                        contacts.push(contact)
                    }
                })

                this.setState({ data: contacts, isLoading: false })
            })
    }

    handleClick(obj) {
        history.push({
            pathname: "/contactdetails",
            state: {
                contact: obj,
                update: true,

            }
        })
    }

    getColumns() {
        const columns = [
            {
                name: 'First name',
                selector: 'firstName',
                sortable: true,
            },
            {
                name: 'Last Name',
                selector: 'lastName',
                sortable: true,
            },
            {
                name: 'Text',
                selector: 'text',
                sortable: true,
            },
            {
                name: 'Phone',
                selector: 'phone',
                sortable: true,
            },
            {
                name: 'Email',
                selector: 'email',
                sortable: true,
            },
        ];
        return columns;
    }
    render() {
        const { data } = this.state;
        const columns = this.getColumns();
        Mixpanel.track('Contact Page');
        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="contact-frame">
                    <Row noGutters className="contact-header text-center">
                        <Col lg={1} className="icon">
                            {<ContactsIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Contacts
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {data.length > 0 ?
                        <div className="table-div">
                            <Button className="new-contact" outline color="secondary" onClick={() => history.push({ pathname: "/contactdetails", state: { update: false } })}>Add new contact</Button>
                            <DataTable
                                columns={columns}
                                data={data}
                                striped
                                dense
                                highlightOnHover
                                pointerOnHover
                                pagination
                                paginationPerPage={15}
                                paginationRowsPerPageOptions={[15, 30, 50, 100]}
                                fixedHeader
                                fixedHeaderScrollHeight="500px"
                                onRowClicked={(obj) => this.handleClick(obj)}
                            />
                        </div>
                        :
                        <React.Fragment>
                            <Button className="new-contact" outline color="secondary" onClick={() => history.push({ pathname: "/contactdetails", state: { update: false } })}>Add new contact</Button>
                            <div className="empty-contacts">NO CONTACTS</div>
                        </React.Fragment>}
                </div>
        )
    }
}