import React from 'react';

export default class DetailPirHourlyAverage extends React.Component {

    render() {
        //Very simple class to return activations calculated in detailPirCalculations class
        return (
            <div className="pir-average">
                <h4>HOURLY AVERAGE ACTIVATIONS</h4>
                <p><strong>{this.props.value}</strong></p>
            </div>
        )
    }
}