import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { history } from '../../../services/history.service';
import { apiService } from '../../../services/api.service';
import { datetimeformatterService } from '../../../services/datetimeformatter.service';
import DataTable from 'react-data-table-component';

import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as RecentEventsIcon } from '../../../icons/Calendar.svg';
import { Mixpanel } from '../../../Mixpanel';
import './RecentEvents.scss';

export let sorted = true;

export default class RecentEvents extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: true,
            sorted: true,
            data: [],
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("currentUser"));
        apiService.getData("/event/recent?accountId=" + user.AccountId)
            .then(events => {
                events.reverse();
                events.forEach(event => {
                    event.timeLogged = new Date(event.timeLogged)
                    event.timeLogged = datetimeformatterService.formatDateTimeNoSeconds(event.timeLogged);
                })
                this.setState({ data: events, isLoading: false })
            })
    }

    getColumns() {
        const columns = [
            {
                name: 'Date/Time of Event',
                selector: 'timeLogged',
                sortable: true,
            },
            {
                name: 'Message',
                selector: 'message',
                sortable: true,
                grow: 3,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
            },
            {
                name: 'Change',
                selector: 'change',
                sortable: true,
            },
            {
                name: 'User',
                selector: 'username',
                sortable: true,
            },
        ];

        return columns;
    }

    handleClick(obj) {
        // console.log(obj)
    }


    render() {
        Mixpanel.track('Recent Events');
        const { data } = this.state;
        const columns = this.getColumns();
        
        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="events-frame">
                    <Row noGutters className="events-header text-center">
                        <Col lg={1} className="icon">
                            {<RecentEventsIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Recent Events
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {data.length > 0 ?
                        <div className="table-div">
                            {/* <Button className="new-contact" outline color="secondary" onClick={() => history.push({ pathname: "/contactdetails", state: { update: false } })}>Add new contact</Button> */}
                            <DataTable
                                columns={columns}
                                data={data}
                                striped
                                dense
                                highlightOnHover
                                pointerOnHover
                                pagination
                                paginationPerPage={15}
                                paginationRowsPerPageOptions={[15, 30, 50, 100]}
                                fixedHeader
                                fixedHeaderScrollHeight="500px"
                                onRowClicked={(obj) => this.handleClick(obj)}
                            />
                        </div>
                        :
                        <React.Fragment>
                            {/* <Button className="new-contact" outline color="secondary" onClick={() => history.push({ pathname: "/contactdetails", state: { update: false } })}>Add new contact</Button> */}
                            <div className="empty-events">NO RECENT EVENTS</div>
                        </React.Fragment>}
                </div>
        )
    }

}