import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import Rollover from './Rollover';
import { dataService } from '../../../services/data.service';

import { ReactComponent as MoveHandle } from '../../../icons/MoveHandle.svg';
import { ReactComponent as Boiler } from '../../../icons/42/Boiler42.svg';
import { ReactComponent as AirHandler } from '../../../icons/42/AirHandling42.svg';
import { ReactComponent as Chiller } from '../../../icons/42/Chiller42.svg';
import { ReactComponent as WaterHeater } from '../../../icons/42/WaterHeater42.svg';

import { ReactComponent as Temperature } from '../../../icons/Temperature.svg';
import { ReactComponent as AirSpeed } from '../../../icons/AirSpeed.svg';

export default class GraphTile extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: true,
            colours: {
                header: "#000000",
                body: "#ECE9E9",
            },
        }
    }

    componentDidMount() {
        dataService.getReadingsBySensorId(this.props.sensorDisplayInfo.sensorId)
            .then(readings => {
                if (readings.length > 0) {
                    readings.sort(function (a, b) {
                        var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                        return dateB - dateA;
                    });

                    let average = this.calcAverage(readings);
                    average = average.toFixed(2)

                    this.setState({ readings: readings, currentReading: readings[0], average: average, isData: true, isLoading: false })
                } else {
                    this.setState({ currentReading: "INSUFFICIENT DATA", isData: false, isLoading: false })
                }
            })
    }

    getIcon = () => {
        let display, size = 35;
        const assetType = this.props.sensorDisplayInfo.assetType.toUpperCase();

        switch (assetType) {
            case "BOILER":
                display = {
                    mainIcon: <Boiler width={size} height={size}/>,
                    liveIcon: <Temperature width={20} />,
                    averageIcon: <Temperature width={20} />,
                }
                break;
            case "WATER HEATER":
                display = {
                    mainIcon: <WaterHeater width={size} height={size}/>,
                    liveIcon: <Temperature width={20} />,
                    averageIcon: <Temperature width={20} />,
                }
                break;
            case "CHILLER":
                display = {
                    mainIcon: <Chiller width={size} height={size}/>,
                    liveIcon: <Temperature width={20} />,
                    averageIcon: <Temperature width={20} />,
                }
                break;
            case "AIR HANDLING UNIT":
                display = {
                    mainIcon: <AirHandler width={size} height={size}/>,
                    liveIcon: <Temperature width={20} />,
                    averageIcon: <AirSpeed width={40} />,
                }
                break;
            default:
                display = {
                    mainIcon: "",
                    liveIcon: "",
                    averageIcon: "",
                }
                break;
        }

        return display;
    }

    calcAverage = (readings) => {
        let total = 0;
        readings.forEach(reading => {
            total += parseFloat(reading.value);
        });

        const average = (total / readings.length);

        return average;
    }

    render() {
        const { sensorDisplayInfo } = this.props;

        const display = this.getIcon();

        let headerStyles = { backgroundColor: this.state.colours.header }

        let bodyStyles = { backgroundColor: this.state.colours.body };


        return (
            <div className="graph-tile">
                <Col lg={12} className="header-wrapper" style={headerStyles}>
                    <Row className="text-center header">
                        <Col lg={1} className="icon">
                            {display.mainIcon}
                        </Col>
                        <Col lg={10} className="title">
                        {sensorDisplayInfo.assetType.toUpperCase()} <br />
                        <span className="user-defined-name">{sensorDisplayInfo.name.toUpperCase()}</span>
                        </Col>
                        <Col lg={1} className="drag-handle">
                            <MoveHandle width={15} />
                        </Col>
                    </Row>
                </Col>
                {this.state.isLoading ?
                    <Col lg={12} className="body" style={bodyStyles}>
                        <div className="tile-loading">
                            <Spinner className="tile-spinner" />
                        </div>
                    </Col>
                    :
                    this.state.isData ?
                        <Link to={{
                            pathname: '/detail', state: {
                                sensorDisplayInfo: sensorDisplayInfo,
                                roomName: sensorDisplayInfo.roomName,
                                siteAddress: this.props.siteAddress,
                                siteName: this.props.siteName
                            }
                        }}>
                            <Col lg={12} className="body" style={bodyStyles}>
                                <Row className="asset-body">
                                    <Col>
                                        <h4>LIVE READING</h4>
                                        <div className="icon-reading">
                                            {display.liveIcon}
                                            <div className="asset-reading"><strong>{this.state.currentReading.value}</strong> <span>{this.state.currentReading.unit}</span></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="asset-body"><div className="asset-spacer"> </div></Row>
                                <Row className="asset-body">
                                    <Col>
                                        <h4>AVERAGE READING</h4>
                                        <div className="icon-reading" >
                                            {display.averageIcon}
                                            <div className="asset-reading"><strong>{this.state.average}</strong> <span>{this.state.currentReading.unit}</span></div>
                                        </div>
                                    </Col>
                                </Row>
                                <Rollover />
                            </Col>
                        </Link>
                        :
                        <Col lg={12} className="body" style={bodyStyles}>
                            <div className="current-reading">{this.state.currentReading}</div>

                        </Col>

                }
            </div>
        );
    }
}