import React from 'react';
import { history } from '../../../services/history.service';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText, Table } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { apiService } from '../../../services/api.service';
import { cacheService } from '../../../services/cache.service';
import { eventsService } from '../../../services/events.service';
import ConfirmationModal from '../../modals/ConfirmationModal';

import { ReactComponent as  CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as  AlertIcon } from '../../../icons/Alerts.svg';
import { Mixpanel } from '../../../Mixpanel';
import './Alerts.scss';

export const status = ["Unactioned", "In Progress", "Actioned"];

export default class AlertDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            button: "primary",
            errorText: "",
            isChanged: false,
            assets: "",
            assetDropdowns: [],
            dropdown: {
                alertStatus: "",
                alertAsset: "",
            },
            data: {
                alertEngineer: "",
                alertJobId: "",
                alertNotes: "",
            },
        }
    }

    componentDidMount() {
        const { site, room } = this.props.location.state;

        //Checks whether someone came from whole site or just a room and gets missing information
        if (site !== undefined) {
            dataService.getRoomsBySiteId(site)
                .then(rooms => {
                    this.setInitialStates(this.props.location.state, rooms, site);
                })
        } else if (room !== undefined) {
            //get room by room id and then get the site id, set that to state
            dataService.getRoomById(room)
                .then(room => {
                    dataService.getRoomsBySiteId(room.site_Id)
                        .then(rooms => {
                            this.setInitialStates(this.props.location.state, rooms, room.site_Id);
                        })
                })
        }
    }

    checkCache = () => {
        //If the cache is empty then fill it first
        return new Promise(resolve => {
            dataService.getAllAssets().then(assets => {
                if (assets.length === 0) {
                    cacheService.fillAssetDefectCache().then(
                        resolve(assets)
                    );
                } else {
                    resolve();
                }
            })
        })
    }

    setInitialStates(props, rooms, siteId) {
        const { alert } = props;
        let assetNames = ["Not assigned"];

        this.checkCache().then(() => {
            //Get all assets for this account first
            dataService.getAllAssets().then(assets => {
                const user = JSON.parse(localStorage.getItem("currentUser"))

                //Grab all asset names
                var names = assets.map(asset => {
                    if (asset.account_Id === user.AccountId) {
                        return asset.name;
                    } else {
                        return undefined;
                    }
                });

                Promise.all(names).then(names => {
                    //spread the names out and get rid of any undefined values
                    assetNames.push(...names);
                    assetNames = assetNames.filter(asset => asset !== undefined);

                    let assetDrop;

                    //If the alert doesn't have an id attached, select the first option declared above
                    if (alert.assetId === null) {
                        assetDrop = assetNames[0];
                    } else {
                        const assetIndex = assets.findIndex(asset => asset.id === alert.assetId);

                        if (assets[assetIndex] !== undefined) {
                            assetDrop = assets[assetIndex].name;
                        }
                    }

                    this.setState({
                        rooms: rooms,
                        assets: assets,
                        assetDropdowns: assetNames,
                        dropdown: {
                            alertStatus: alert.status,
                            alertAsset: assetDrop,
                        },
                        data: {
                            alertEngineer: alert.engineer,
                            alertJobId: alert.job,
                            alertNotes: alert.notes,
                        },
                        siteId: siteId,
                        isLoading: false
                    })

                })
            })
        })
    }

    handleOpenModal = (modal) => {
        this.refs[modal].toggleModal();
    }

    handleTextChange = (obj) => {
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = obj.target.value;

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleCancel = () => {
        if (this.state.isChanged) {
            this.handleOpenModal("confirm-no-change");
        } else {
            history.goBack();
        }
    }

    handleSave = () => {
        if (this.state.isChanged === true) {
            this.saveDetails();
        } else if (this.state.isChanged === false) {
            this.setState({ button: "danger", errorText: "You've not made any changes!" });
        }

    }

    saveDetails = () => {
        const { alert } = this.props.location.state;
        const { data, dropdown } = this.state;
        let toDatabase = [], assetId;

        //Checks to see if every required field is valid
        const assetIdIndex = this.state.assets.findIndex(asset => asset.name === this.state.dropdown.alertAsset);

        if (assetIdIndex === -1) {
            assetId = null;
        } else {
            assetId = this.state.assets[assetIdIndex].id;
        }

        toDatabase = {
            id: this.props.location.state.alert.id,
            alertStatus: dropdown.alertStatus,
            jobId: data.alertJobId,
            engineer: data.alertEngineer,
            asset_Id: assetId,
            notes: data.alertNotes,
        }

        //If job id has been assigned
        if (data.alertJobId !== "") {
            const workHistory = {
                jobId: data.alertJobId,
                engineer: data.alertEngineer,
                notes: data.alertNotes,
                status: dropdown.alertStatus,
                type: alert.type,
                sensorId: alert.sensorId,
                siteId: this.state.siteId,
                assetId: assetId,
            }

            this.postToWorkHistory(workHistory);
        }

        this.patchToDatabase(toDatabase);

        this.setState({ button: "primary", errorText: "" });

    }

    patchToDatabase = (alert) => {
        apiService.patchData("/alert", alert)
            .then(() => {
                const message = alert.alertStatus + " - " + this.props.location.state.alert.date;
                eventsService.newEvent(message, "Alerts", "Update")
                    .then(() => {
                        history.goBack();
                    })
            });
    }

    postToWorkHistory = (workHistory) => {
        apiService.postData("/workhistory", workHistory)
            .then(() => {
                // console.log("finished")
            })
    }

    render() {
        Mixpanel.track('Alert Details');
        const { alert } = this.props.location.state;

        return (
            this.state.isLoading ?
                ""
                :
                <div className="alert-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<AlertIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Alerts
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => this.handleCancel()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <Form className="form">
                        <Row>
                            <Col>
                                <Row className="alert-details">
                                    <Col>
                                        <Row>
                                            <h1>Alert</h1>
                                        </Row>
                                        <Row>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Type</th>
                                                        <th>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{alert.date}</td>
                                                        <td>{alert.type}</td>
                                                        <td>{alert.desc}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Row>
                                        <Row>
                                            <FormGroup>
                                                <Label for="alertStatus" >Status</Label>
                                                <Input type="select" name="status" id="alertStatus" value={this.state.dropdown.alertStatus} onChange={obj => this.handleDropdown(obj)}>
                                                    {status.map((option, i) => {
                                                        return (
                                                            <option key={i}>{option}</option>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="alert-details">
                                    <Col>
                                        <Row>
                                            <h1>Assign Asset</h1>
                                        </Row>
                                        <Row>
                                            <FormGroup>
                                                <Label for="assets" >Asset</Label>
                                                <Input type="select" name="assets" id="alertAsset" value={this.state.dropdown.alertAsset} onChange={obj => this.handleDropdown(obj)}>
                                                    {this.state.assetDropdowns.map((option, i) => {
                                                        return (
                                                            <option key={i}>{option}</option>
                                                        )
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="alert-details-job">
                                    <Col>
                                        <Row>
                                            <h1>Job</h1>
                                        </Row>
                                        <Row>
                                            <FormGroup className="engineer">
                                                <Label for="alertEngineer" >Engineer</Label>
                                                <Input type="text" name="name" id="alertEngineer" placeholder="Engineer" value={this.state.data.alertEngineer} onChange={obj => this.handleTextChange(obj)} />
                                            </FormGroup>
                                            <FormGroup className="job">
                                                <Label for="alertJobId" >Job ID</Label>
                                                <Input type="text" name="name" id="alertJobId" placeholder="Job ID" value={this.state.data.alertJobId} onChange={obj => this.handleTextChange(obj)} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup className="textarea">
                                                <Label for="alertNotes">Notes</Label>
                                                <Input type="textarea" name="notes" id="alertNotes" placeholder="Notes" className="alert-notes" value={this.state.data.alertNotes} onChange={obj => this.handleTextChange(obj)} />
                                            </FormGroup>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button className="save-button" color={this.state.button} onClick={() => this.handleSave()}>Save</Button>
                                <FormText>
                                    {this.state.errorText}
                                </FormText>
                            </Col>
                            <Col>
                                <Button className="cancel-button" onClick={() => this.handleCancel()}>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                    <ConfirmationModal titleText="Get rid of changes?" buttonText="Confirm" bodyText="go back without saving your changes" className="confirm-nochanges-alert" function={() => history.goBack()} ref="confirm-no-change" />
                </div>
        )
    }
}