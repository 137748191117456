import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

export default class DetailSingleLiveReading extends React.Component {

    render() {

        const { reading } = this.props;
        var styles;

        let readingCopy = JSON.parse(JSON.stringify(reading));

        if (reading.alertLevel === 1) {
            styles = {
                //Amber
                backgroundColor: "#F4A105",
                color: "#FFF"
            }
        } else if (reading.alertLevel > 1) {
            styles = {
                //Red
                backgroundColor: "#FF0000",
                color: "#FFF"
            }
        } else {
            styles = {
                //Green
                backgroundColor: "#8CC63F",
                color: "#FFF"
            }
        }

        if (readingCopy.type === "CO LEVEL") {
            const splits = readingCopy.value.split("|");
            readingCopy.value = splits[0];
        }

        if(readingCopy.type === "PIR") {
            if (readingCopy.value === "True") {
                readingCopy.value = reading.value + " - Motion Detected";
            } else if (reading.value === "False") {
                readingCopy.value = reading.value + " - No Motion Detected";
            }
        }

        if(readingCopy.type === "LIGHT") {
            if (readingCopy.value < "5") {
                readingCopy.value = "LIGHT: OFF | " + reading.value;
            } else {
                readingCopy.value = "LIGHT: ON | " + reading.value;
            }
        }



        return (
            <Col lg={12} style={styles} className={`live-reading text-center`}>
                <h4>LIVE READING</h4>
                <h2>{readingCopy.value}{reading.unit}</h2>
            </Col>
        )
    }
}

DetailSingleLiveReading.propTypes = {
    data: PropTypes.object.isRequired,
}