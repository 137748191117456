import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { processService } from "../../services/processing.service";
import { dataService } from "../../services/data.service";

import EmergencyModal from "../modals/EmergencyModal";

import { ReactComponent as ContactEngineerIcon } from "../../icons/ContactEngineer.svg";
import { ReactComponent as RaiseAlarmIcon } from "../../icons/RaiseAlarm.svg";
import { ReactComponent as DashboardIcon } from "../../icons/Dashboard.svg";

import "./Rooms.scss";

import ffCornerOfficeImage from "../../images/OCS-FF-CornerOffice.jpeg";
import warehouseImage from "../../images/OCS-Warehouse.jpeg";
import ffImage from "../../images/OCS-FF.jpeg";
import ffHallwayImage from "../../images/OCS-FF-Halway.jpeg";
import receptionImage from "../../images/OCS-ReceptionMain.jpeg";

export const position = [
  "TEMPERATURE",
  "HUMIDITY",
  "CO LEVEL",
  "PIR",
  "SOUND",
  "LIGHT",
  "AIR QUALITY",
];

export default class RoomCard extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      readings: [],
      image: "",
      overallColour: "white",
      safeTile: "",
    };
  }

  componentDidMount = () => {
    this.setState({ isLoading: true });

    //Grab the room and set the image
    dataService
      .getRoomById(this.props.room.id)
      .then((room) => {
        this.setState({ image: room.image });
      })
      .catch(() => {
        this.setState({ error: true });
      });

    //Call a function to return the worst readings for all the sensor types for this room
    return new Promise((resolve) => {
      resolve(processService.getRoomCardReadings(this.props.room));
    }).then((readings) => {
      this.setContextColours(readings);
    });
  };

  setContextColours = (readings) => {
    let overallColour, safeTile;

    //Checks all the readings to see if any are amber or red, if they are than change the top colour to match
    if (readings.some((reading) => reading.alertLevel === 2)) {
      overallColour = "red";

      //If it's a red alert and one of them is air quality or CO then make it read unsafe
      if (
        readings.some(
          (reading) =>
            reading.alertLevel === 2 && reading.type === "AIR QUALITY"
        )
      ) {
        safeTile = (
          <Col lg={3} className="card-indicator safe-tile red">
            <small>CURRENTLY</small>
            <br />
            <span>UNSAFE</span>
          </Col>
        );
      } else if (
        readings.some(
          (reading) => reading.alertLevel === 2 && reading.type === "CO LEVEL"
        )
      ) {
        safeTile = (
          <Col lg={3} className="card-indicator safe-tile red">
            <small>CURRENTLY</small>
            <br />
            <span>UNSAFE</span>
          </Col>
        );
      } else {
        safeTile = (
          <Col lg={3} className="card-indicator safe-tile green">
            <small>CURRENTLY</small>
            <br />
            <span>SAFE</span>
          </Col>
        );
      }
    } else if (readings.some((reading) => reading.alertLevel === 1)) {
      overallColour = "amber";
      safeTile = (
        <Col lg={3} className="card-indicator safe-tile green">
          <small>CURRENTLY</small>
          <br />
          <span>SAFE</span>
        </Col>
      );
    } else {
      overallColour = "green";
      safeTile = (
        <Col lg={3} className="card-indicator safe-tile green">
          <small>CURRENTLY</small>
          <br />
          <span>SAFE</span>
        </Col>
      );
    }

    this.generateReadingsTiles(readings);
    this.setState({ safeTile: safeTile, overallColour: overallColour });
  };

  generateReadingsTiles = (readings) => {
    var tiles = [];

    //Create an empty array
    tiles = Array(7).fill(null);

    readings.forEach((reading) => {
      //Checks for motion and changes the value to a string
      if (reading.type === "PIR" && reading.value === "True") {
        reading.value = "Active";
      } else if (reading.type === "PIR" && reading.value === "False") {
        reading.value = "Vacant";
      }

      //Checks for air quality and changes the output based on the value
      if (reading.type === "AIR QUALITY" && reading.alertLevel === 0) {
        reading.value = "Good";
      } else if (reading.type === "AIR QUALITY" && reading.alertLevel === 1) {
        reading.value = "Moderate";
      } else if (reading.type === "AIR QUALITY" && reading.alertLevel > 1) {
        reading.value = "Bad";
      }

      //If the sensor type has a position then attach it to the tiles array in the correct place
      if (reading.pos !== null) {
        tiles[reading.pos] = reading;
      }
    });

    this.setState({ readings: tiles, isLoading: false });
  };

  toggleModal = (modalName, type) => {
    this.refs[modalName].toggleModal(type);
  };

  getRoomImage(param) {
    switch (param) {
      case "First Floor Corner Office":
        return ffCornerOfficeImage;
      case "First Floor Hallway":
        return ffHallwayImage;
      case "First Floor":
        return ffImage;
      case "Warehouse":
        return warehouseImage;
      case "Reception":
        return receptionImage;
      default:
        return "foo";
    }
  }

  render() {
    const room = this.props.room;

    return (
      <Card className="site-card">
        <CardHeader className={`${this.state.overallColour}-header`}>
          <h5>
            {room.name}
            <br />
            <small>Notes: {room.notes}</small>
          </h5>
        </CardHeader>
        <CardBody>
          <Row noGutters className="pt-2 pl-2 pr-2 onsite-contact">
            <Col lg={12}>
              <p>
                <small>On-site Contact:</small>
                <br />
                {!room.onsiteContactName && !room.onsitePhoneNo
                  ? "No contact details provided"
                  : `${room.onsiteContactName} | ${room.onsitePhoneNo}`}
              </p>
            </Col>
          </Row>
          <Row noGutters className="site-info pl-2 pr-2">
            <div className="info">
              <Row noGutters>
                <Col lg={3}>Location:</Col>
                <Col lg={9}>{room.location}</Col>
              </Row>
            </div>
            <Col lg={4} className="live-feed disabled">
              <small>LIVE FEED</small>
            </Col>
          </Row>

          <Row noGutters>
            {this.state.isLoading ? (
              <div className="loading-div">
                <Spinner className="loading" />
              </div>
            ) : (
              this.state.readings.map((reading, i) => {
                if (reading != null) {
                  return (
                    <Col
                      lg={3}
                      className={`card-indicator ${reading.colour}`}
                      key={i.toString()}
                    >
                      {reading.value}{" "}
                      {reading.type !== "AIR QUALITY" ? reading.unit : ""}
                      <br />
                      <small>{reading.type}</small>
                    </Col>
                  );
                } else {
                  return (
                    <Col
                      lg={3}
                      className={`card-indicator not-monitored`}
                      key={i.toString()}
                    >
                      <span>
                        {position[i]} <br />
                        <small>NOT MONITORED</small>
                      </span>
                    </Col>
                  );
                }
              })
            )}
            {this.state.isLoading ? "" : this.state.safeTile}
          </Row>
          {this.state.isLoading ? (
            ""
          ) : (
            <Row noGutters className="card-image">
              <img src={this.getRoomImage(room.name)} alt="Room" />
            </Row>
          )}
          <Row noGutters>
            <Col lg={4} className="card-action contact-engineer">
              <ContactEngineerIcon height={25} /> <br />
              <span>CONTACT ENGINEER</span>
            </Col>
            <Col
              className="card-action card-action-red"
              type="button"
              onClick={() => this.toggleModal("emergency", "999")}
            >
              <div>
                EMERGENCY <br /> SERVICES
              </div>
            </Col>
            <Col
              lg={4}
              className="card-action raise-alarm"
              type="button"
              onClick={() => this.toggleModal("emergency", "alarms")}
            >
              <RaiseAlarmIcon height={25} /> <br />
              <span>RAISE ALARM</span>
            </Col>
          </Row>
          <Link
            to={{
              pathname: "/dashboard",
              state: {
                roomId: room.id,
                roomName: room.name,
                siteAddress: this.props.siteAddress,
                siteName: this.props.siteName,
              },
            }}
          >
            <Row noGutters>
              <Col className="card-action card-action-fullwidth">
                <DashboardIcon height={50} />
                <span>GO TO DASHBOARD</span>
              </Col>
            </Row>
          </Link>
        </CardBody>
        <EmergencyModal ref="emergency" />
      </Card>
    );
  }
}
