import React from 'react';

import { ReactComponent as BatteryLevel5 } from '../../icons/BatteryLevel5.svg';
import { ReactComponent as BatteryLevel4 } from '../../icons/BatteryLevel4.svg';
import { ReactComponent as BatteryLevel3 } from '../../icons/BatteryLevel3.svg';
import { ReactComponent as BatteryLevel2 } from '../../icons/BatteryLevel2.svg';
import { ReactComponent as BatteryLevel1 }from '../../icons/BatteryLevel1.svg';
import { ReactComponent as BatteryLevel0 } from '../../icons/BatteryLevel0.svg';



export default class Battery extends React.Component {

    //Takes a value in as props and returns a battery indicator SVG with the correct amount of bars
    render() {
        const {value} = this.props;
        
        if (value >= 100) {
            return (
                <BatteryLevel5 width={this.props.width} />
            ) 
        } else if (value < 100 && value >= 80) {
            return (
                <BatteryLevel4 width={this.props.width} />
            ) 
        } else if (value < 80 && value >= 60) {
            return (
                <BatteryLevel3 width={this.props.width} />
            ) 
        } else if (value < 60 && value >= 40) {
            return (
                <BatteryLevel2 width={this.props.width} />
            ) 
        } else if (value < 40 && value >= 20) {
            return (
                <BatteryLevel1 width={this.props.width} />
            ) 
        } else if (value === 0) {
            return (
                <BatteryLevel0 width={this.props.width} />
            ) 
        } else {
            return (
                <BatteryLevel0 width={this.props.width} />
            ) 
        }
    }
}