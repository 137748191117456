import React from 'react';
import { dataService } from '../../services/data.service';
import { Row, Col, Spinner, Input } from 'reactstrap';
import { history } from '../../services/history.service';
import { processService } from '../../services/processing.service';

import RoomCard from './RoomCard';
import { sortCards } from '../../services/sortcards.service';
import { Mixpanel } from '../../Mixpanel';
export default class RoomsMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            isLoading: true,
        }
    }

    componentDidMount() {
        //Push user back to sites if no site ID is provided
        if (typeof this.props.id == "undefined") {      //NOT WORKING
            history.replace('/');
        }

        //If no button has been pressed or the all button has
        if (this.props.alert === undefined) {
            //Get site first, then grab rooms
            dataService.getSiteById(parseInt(this.props.id))
                .then(site => {
                    dataService.getRoomsBySiteId(site.id)
                        .then(rooms => {
                            this.setState({ site: site, flatCards: rooms });
                            this.generateGrid(rooms, site);
                        })
                        .catch(() => {
                            this.setState({ error: true });
                        })
                })
        } else {
            //Filter by alert value passed in before getting site and rooms
            this.filterByAlert(this.props.alert)
                .then((filtered) => {
                    var maps = filtered.map((room) => {
                        return dataService.getRoomById(room);
                    })
                    Promise.all(maps)
                        .then((rooms) => {
                            dataService.getSiteById(parseInt(this.props.id))
                                .then((site) => {
                                    this.setState({ site: site, flatCards: rooms });
                                    this.generateGrid(rooms, site);
                                })
                        })
                })
        }
    }

    filterByAlert = (alert) => {
        //This promise runs right at the end to return the filtered values
        return new Promise(resolve => {
            return new Promise(resolve => {
                //Loop over rooms for this site and then get the room card readings
                dataService.getRoomsBySiteId(parseInt(this.props.id))
                    .then((rooms) => {
                        return new Promise(resolve => {
                            var maps = rooms.map((room, i) => {
                                return processService.getRoomCardReadings(room)
                            })

                            Promise.all(maps)
                                .then((readings) => {
                                    resolve(readings);
                                })
                        })
                            .then((readings) => {
                                resolve(readings);
                            })
                    })
            })
                .then((rooms) => {
                    //Determine overall alert value and then return based on alert passed in
                    // eslint-disable-next-line
                    var returns = rooms.map((room) => {
                        let overall = 0;
                        if (room.some(sensor => (sensor.alertLevel > 1))) {
                            overall = 2;
                        } else if (room.some(sensor => sensor.alertLevel === 1)) {
                            overall = 1;
                        }

                        if (overall === alert) {
                            return room;
                        }
                    })
                    let filtered = [];
                    Promise.all(returns)
                        .then((rooms) => {
                            rooms.forEach(room => {
                                if (room !== undefined && room.length > 0) {
                                    filtered.push(room[0].id);
                                }
                            });
                            resolve(filtered);
                        })
                })
        })
            .then((filtered) => {
                return filtered;
            })
    }

    generateGrid = (flatCards, site, sortType) => {
        sortCards.sort(flatCards, sortType)
            .then(flatCards => {
                var contactDetails = {
                    onsiteContactName: site.onsiteContactName,
                    onsitePhoneNo: site.onsitePhoneNo
                };
                var rows = [], cards = [];

                for (var i = 0; i < flatCards.length; i++) {
                    var newCard = { ...flatCards[i], ...contactDetails };
                    
                    cards.push(newCard);
                    if (Number.isInteger((i + 1) / 3)) {
                        rows.push(cards);
                        cards = [];
                    }
                    
                    //if (Number.isInteger((i + 1) / 4)) {
                    //    //We need a new row
                    //    rows.push(cards);
                    //    cards = [];
                    //}
                    //else if (flatCards.length <= 4 && i + 1 === flatCards.length)
                    //{
                    //    rows.push(cards);
                    //}
                }
                if (cards.length <= 3)
                    rows.push(cards);
                var formattedCards = rows;//.reverse();
                this.setState({ isLoading: false, rows: formattedCards, site: site });
            })
    }

    handleSort(obj) {
        this.setState({ isLoading: true, sortBy: obj.target.value });

        this.generateGrid(this.state.flatCards, this.state.site, obj.target.value);
    }

    render() {
        Mixpanel.track('Rooms Main');
        const { site } = this.state;
        let siteName, siteAddress;

        if (this.state.isLoading === false) {
            siteName = site.name;
            siteAddress = site.address;
        }
        
        return (
            <div className="m-3">
                {
                    this.state.isLoading ?
                        <div className="main-loading-div" >
                            <Spinner className="main-loading" />
                        </div>
                        :
                        this.state.rows.length === 0 ?
                            <div className="empty-filter">NO MATCHING SITES FOR THIS FILTER</div>
                            :
                            <React.Fragment>
                                <Row className="tile-filter">
                                        <Col className="dropdowns">
                                            <span>SORT BY</span>
                                            <Input type="select" value={this.state.sortBy} onChange={(obj) => this.handleSort(obj)}>
                                                <option>A-Z</option>
                                                <option>Z-A</option>
                                            </Input>
                                        </Col>
                                    </Row>
                                {
                                    this.state.rows.map((row, i) => {
                                        return (
                                            <Row key={i} noGutters>
                                                {
                                                    row.map((room, j) => {
                                                        return (
                                                            <Col key={j} xl={4} className="p-2 col-xxl-3">
                                                                <RoomCard key={j} room={room} siteName={siteName} siteAddress={siteAddress} />
                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        );
                                    })
                                }
                            </React.Fragment>
                }
            </div>
        );
    }
} 