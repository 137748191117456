import { authService } from './auth.service';
import { cacheService } from './cache.service';

export async function handleResponse(response) {
    return await response.text().then(async text => {
        const data = text && await JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                cacheService.deleteDb();
                authService.logout();
                window.location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export async function handleLoginResponse(response) {
    return await response.text().then(async text => {
        const data = text && await JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}