import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
        }
    }

    //Standard confirmation modal, with custom text using props

    toggleModal() {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        const { titleText, buttonText, bodyText, className } = this.props;
        
        return (
            <div>
                {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
                <Modal isOpen={this.state.isOpen} toggle={() => this.toggleModal()} className={className}>
                    <ModalHeader toggle={() => this.toggleModal()}>{titleText}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to {bodyText}?
        </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.props.function()}>{buttonText}</Button>{' '}
                        <Button color="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}