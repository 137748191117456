import { Device } from 'twilio-client';
import { apiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

const device = new Device();
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const setupTwilio = async () => {

    if (currentUserSubject.value !== null) {
        let isBox = false;
        let boxId = currentUserSubject.value.username;
        let accountId = currentUserSubject.value.AccountId;

        //If we are the box itself, tell the API that
        if (currentUserSubject.value.role === "BOX") {
            isBox = true;
        }

        // console.log(boxId)

        //Get the Twilio token from main API, save the Identity and setup the twilio client 
        apiService.postData('/twilio/clienttoken', { isBox: isBox, boxId: boxId, accountId: accountId })
            .then(twilioToken => {
                // console.log("TWILIO TOKEN");
                // console.log(twilioToken);
                localStorage.setItem("TwilioIdentity", twilioToken.identity);
                device.setup(twilioToken.token, {
                    appName: twilioToken.identity,
                    codecPreferences: ["opus", "pcmu"],
                    fakeLocalDTMF: true,
                    enableRingingState: true
                });
            })
            .catch((error) => {
                console.log(error)
            })

        device.on('ready', () => {
            // console.log("Twilio ready");
            twilioHeartbeat.startTimer();
        })

        device.on('offline', (con) => {
            // console.log("Twilio offline")
            if (localStorage.getItem("TwilioIdentity") !== null) {
                apiService.postData('/twilio/clientoffline', { identity: localStorage.getItem("TwilioIdentity") });
                localStorage.removeItem("TwilioIdentity");
            }
            if (twilioHeartbeat.isRunning()) {
                twilioHeartbeat.stopTimer();
            }
        })

        setRoomChecker();
    }
}

const setRoomChecker = () => {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    // let timer = false;
    if (user.role === "BOX") {
        // timer = setInterval(() => console.log("yup"), 1000)
    }
}

const registerOffline = () => {
    // console.log("Reg offline");
    device.destroy();
    localStorage.removeItem("TwilioIdentity");
    twilioHeartbeat.stopTimer();
    offlineClientTimout.stopTimer();
}

class twilioHeartbeat {

    static timer = false;

    static sendHeartbeat = () => {
        // console.log("Sending twilio heartbeat...")
        apiService.postData('/twilio/clientheartbeat', { identity: localStorage.getItem("TwilioIdentity") });
    }

    static startTimer = () => {
        if (!this.isRunning()) {
            //5 mins in ms = 300000 // 2 mins = 120000
            // console.log("Starting twilio heartbeat...");
            this.timer = setInterval(this.sendHeartbeat, 120000);
        }
    }

    static stopTimer = () => {
        // console.log("Stopping twilio heartbeat....")
        clearInterval(this.timer);
        this.timer = false;
    }

    static isRunning = () => {
        return this.timer !== false;
    }
}

export class offlineClientTimout {

    static timer = false;

    static startTimer = () => {
        if (!this.isRunning()) {
            //5 mins in ms = 300000 // 2 mins = 120000
            // console.log("Starting twilio disconnect timer...");
            this.timer = setInterval(registerOffline, 120000);
        }
    }

    static stopTimer = () => {
        // console.log("Stopping twilio disconnect timer...")
        clearInterval(this.timer);
        this.timer = false;
    }

    static isRunning = () => {
        return this.timer !== false;
    }
}

export const twilioService = {
    device,
    setupTwilio,
    registerOffline
}