import { BehaviorSubject } from 'rxjs';
import { handleLoginResponse, handleResponse } from './response.service';
import { apiUrl } from './config';
import { history } from './history.service';
import jwt_decode from "jwt-decode";
import { twilioService } from './twilio.service';
import { Mixpanel } from '../Mixpanel';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));


export const authService = {
    login,
    logout,
    authHeader,
    refreshToken,
    boxName,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value },
}

function login(username, password) {
    return new Promise((resolve, reject) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'omit',
            body: JSON.stringify({ "username": username, "password": password })
        };

        //First check IP address
        checkIp().then(result => {
            if (result) {
                //Try and get a token using login info
                return fetch(`${apiUrl}/token/authenticate`, requestOptions)
                    .then(handleLoginResponse)
                    .then(user => {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        var userInfo = jwt_decode(user.token);
                        userInfo["token"] = user.token;
                        localStorage.setItem('currentUser', JSON.stringify(userInfo));
                        Mixpanel.register({ 'Userrole': userInfo.role, 'AccountId': userInfo.AccountId, 'SiteId': userInfo.SiteId0, 'Username': userInfo.username });


                        currentUserSubject.next(userInfo);

                        return resolve(user);
                    }).catch(error => {
                        console.log(error)
                        return resolve(false)
                    });
            } else {
                return resolve(false);
            }
        })
    })
}

function checkIp() {
    return new Promise(resolve => {
        //Get users IP address
        const publicIp = require('public-ip');

        //Client list of blacklisted countries
        const blackList = ["China", "Iran", "Russia"]

        //Using the IP get the geo location of the user
        publicIp.v4().then(ip => {
            fetch('https://extreme-ip-lookup.com/json/' + ip)
                .then(response => response.json())
                .then((geo) => {

                    //Resolve true if not in blacklisted country
                    if (blackList.includes(geo.country)) {
                        return resolve(false);
                    } else {
                        return resolve(true);
                    }
                })

                //Resolve true if this doesn't work - user might have ad blocker or the service might be down
                .catch((e) => {
                    return resolve(true);
                })
        })
            //Resolve true if this doesn't work - user might have ad blocker or the service might be down
            .catch((e) => {
                return resolve(true);
            })
    })
}

function logout() {
    // remove user from local storage, expire cookie, stop twilio, remove currentUserSubject, push back to login and then refresh to get rid of the cookie
    localStorage.removeItem('currentUser');
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 12:00:00 AM UTC; domain=sps-dev-api.azurewebsites.net";
    twilioService.registerOffline();
    currentUserSubject.next(null);
    history.push("/login");
    window.location.reload();
}

async function refreshToken() {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'omit',
    };

    return await fetch(`${apiUrl}/token/renew`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            var userInfo = jwt_decode(user.token);
            userInfo["token"] = user.token;
            localStorage.setItem('currentUser', JSON.stringify(userInfo));
            currentUserSubject.next(userInfo);

            return user;
        });
}

async function authHeader() {
    // check the token hasn't expired
    const currentUser = authService.currentUserValue;

    if (currentUser && currentUser.token) {
        if (currentUser.exp > Math.round(Date.now() / 1000)) {
            //token hasnt expired
            return { Authorization: `Bearer ${currentUser.token}` };
        } else {
            //token has expired, get a new one
            var newUser = await refreshToken();
            return { Authorization: `Bearer ${newUser.token}` }
        }
    } else {
        //User doesn't exist
        return {};
    }
}

async function boxName() {
    if (currentUserSubject.value !== null) {
        const username = currentUserSubject.value.username
        var val = Math.floor(1000 + Math.random() * 9000);

        return username + val.toString();
    }
}