import React from 'react';
import { history } from '../../services/history.service';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { apiService } from '../../services/api.service';
import { dataService } from '../../services/data.service';
import { eventsService } from '../../services/events.service';
import ConfirmationModal from '../modals/ConfirmationModal';

import { ReactComponent as  CloseIcon } from '../../icons/Close.svg';
import { ReactComponent as ContactsIcon } from '../../icons/Contacts.svg';
import { Mixpanel } from '../../Mixpanel';
import './Contacts.scss';

export default class ContactDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isChanged: false,
            allSites: [],
            sites: [],
            button: "primary",
            errorText: "",
            data: {
                id: "",
                firstName: "",
                lastName: "",
                phone: "",
                textNumber: "",
                email: "",
            },
            dropdown: {
                siteId: "",
            },
        }
    }

    componentDidMount() {
        dataService.getAllSites()
            .then(sites => {
                this.setInitialStates(sites);
            })
    }

    setInitialStates(sites) {
        const { contact, update } = this.props.location.state;
        let temp = { name: "Not Assigned" }

        sites.unshift(temp);

        if (update === false) {
            //Create a new contact
            this.setState({ sites: sites, isLoading: false })
        } else if (update === true) {
            //Update a current contact
            const siteIdIndex = sites.findIndex(site => site.id === contact.siteId);
            let site;

            if (siteIdIndex !== -1) {
                site = sites[siteIdIndex - 1].id;
            } else {
                site = sites[0];
            }


            this.setState({
                sites: sites,

                data: {
                    id: contact.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    phone: contact.phone,
                    textNumber: contact.text,
                    email: contact.email,
                },
                dropdown: {
                    siteId: site,
                },

                isLoading: false,
            })
        }
    }

    handleOpenModal = (modal) => {
        this.refs[modal].toggleModal();
    }

    handleTextChange = (obj) => {
        const { data } = this.state;
        data[`${obj.target.id}`] = obj.target.value;

        this.setState({ data: data, isChanged: true })
    }

    handleDropdown = (obj) => {
        const { dropdown } = this.state;
        dropdown[`${obj.target.id}`] = obj.target.value;

        this.setState({ dropdown: dropdown, isChanged: true })
    }

    handleCancel = () => {
        if (this.state.isChanged) {
            this.handleOpenModal("confirm-no-change");
        } else {
            history.goBack();
        }
    }

    handleSave = () => {
        if (this.state.isChanged === true) {
            this.setState({ isLoading: true })
            this.saveDetails();
        } else if (this.state.isChanged === false) {
            this.setState({ button: "danger", errorText: "You've not made any changes!" });
        }
    }

    saveDetails = () => {
        const { data, dropdown } = this.state, user = JSON.parse(localStorage.getItem("currentUser"));
        let toDatabase = [];
        if (data.firstName.length > 0) {

            if (this.props.location.state.update === false) {
                toDatabase = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    text: data.textNumber,
                    email: data.email,
                    accountId: user.AccountId,
                    siteId: parseInt(dropdown.siteId),
                }

                this.postToDatabase(toDatabase);
            } else if (this.props.location.state.update === true) {
                toDatabase = {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    text: data.textNumber,
                    email: data.email,
                    accountId: user.AccountId,
                    siteId: parseInt(dropdown.siteId),
                }
                this.patchToDatabase(toDatabase);
            }

            this.setState({ button: "primary", errorText: "" });
        } else {
            this.setState({ button: "danger", errorText: "Please fill in all mandatory fields!", isLoading: false });

        }
    }

    postToDatabase = (newContact) => {
        apiService.postData("/contact", newContact)
            .then(() => {
                const message = "New contact added: " + newContact.firstName + " " + newContact.lastName;
                eventsService.newEvent(message, "Contacts", "Create")
                .then(() => {
                    history.goBack();
                })
            });
    }

    patchToDatabase = (contact) => {
        contact.id = this.props.location.state.contact.id;
        apiService.patchData("/contact", contact)
            .then(() => {
                const message = "Contact updated: " + contact.firstName + " " + contact.lastName;
                eventsService.newEvent(message, "Contacts", "Update")
                .then(() => {
                    history.goBack();
                })
            });
    }

    deleteFromDatabase = (id) => {
        this.setState({ isLoading: true });
        apiService.deleteData("/contact?id=" + id)
            .then(() => {
                const message = "Contact removed: " + this.state.data.firstName + " " + this.state.data.lastName;
                eventsService.newEvent(message, "Contacts", "Delete")
                .then(() => {
                    history.goBack();
                })
            });
    }

    render() {

        Mixpanel.track('Contact Details Page');
        return (
            this.state.isLoading ?
                ""
                :
                <div className="contact-frame">
                    <Row noGutters className="contact-header text-center">
                        <Col lg={1} className="icon">
                            {<ContactsIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Contacts
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <Form className="form">
                        <Row>
                            <Col>
                                <br />
                                <FormGroup>
                                    <Label>First Name*</Label>
                                    <Input type="text" id="firstName" value={this.state.data.firstName} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Last Name</Label>
                                    <Input type="text" id="lastName" value={this.state.data.lastName} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Main Site Contact?</Label>
                                    <Input type="select" id="siteId" value={this.state.dropdown.siteId} onChange={obj => this.handleDropdown(obj)} >
                                        {this.state.sites.map(site => {
                                            return (
                                                site.id !== undefined ?
                                                    <option value={site.id}>{site.name + " - " + site.id}</option>
                                                    :
                                                    <option value={""}>{site.name}</option>
                                            )
                                        })}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Row className="delete-row">
                                    <div className="edit-delete">
                                        {this.props.location.state.update ?
                                            <Button className="delete-button" color="secondary" onClick={() => this.handleOpenModal("confirm-delete")}>Remove Contact</Button>
                                            :
                                            ""}
                                        <span> </span>
                                    </div>
                                </Row>
                                <FormGroup>
                                    <Label>Phone Number</Label>
                                    <Input type="text" id="phone" value={this.state.data.phone} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Text Number</Label>
                                    <Input type="text" id="textNumber" value={this.state.data.textNumber} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email Address</Label>
                                    <Input type="text" id="email" value={this.state.data.email} onChange={obj => this.handleTextChange(obj)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className="save-button" color={this.state.button} onClick={() => this.handleSave()}>Save</Button>
                                <FormText>
                                    {this.state.errorText}
                                </FormText>
                            </Col>
                            <Col>
                                <Button className="cancel-button" onClick={() => this.handleCancel()}>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                    <ConfirmationModal titleText="Delete Contact?" buttonText="Delete" bodyText="delete this contact" className="confirm-delete-contact" function={() => this.deleteFromDatabase(this.props.location.state.contact.id)} ref="confirm-delete" />
                    <ConfirmationModal titleText="Get rid of changes?" buttonText="Confirm" bodyText="go back without saving your changes" className="confirm-nochanges-contact" function={() => history.goBack()} ref="confirm-no-change" />
                </div>
        )
    }

}