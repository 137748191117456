import React from 'react';
import { Jumbotron } from 'reactstrap';
import { apiService } from '../../../services/api.service';
import { datetimeformatterService } from '../../../services/datetimeformatter.service';
import { Mixpanel } from '../../../Mixpanel';
import './Notes.scss'

export default class Notes extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            data: [],
        }
    }

    componentDidMount() {
        const { type } = this.props;

        const user = JSON.parse(localStorage.getItem("currentUser"));
        apiService.getData("/event/recent?accountId=" + user.AccountId)
            .then(events => {
                events.reverse();
                let notes = [];
                events.forEach(event => {
                    event.timeLogged = new Date(event.timeLogged)
                    event.timeLogged = datetimeformatterService.formatDateTimeNoSeconds(event.timeLogged);

                    if (event.type === type) {
                        notes.push(event)
                    }
                })
                this.setState({ data: notes, isLoading: false })
            })
    }

    render() {
        Mixpanel.track('Notes');
        const { data } = this.state;


        return(
            this.state.isLoading ?
            ""
            :
            <Jumbotron>
                <h1>Notes</h1>
                {
                    data.map(note => {
                        return (
                            <div>
                                <br />
                                {note.message} <br />
                                {note.timeLogged} <br />
                                {note.username} <br />
                                <hr />
                            </div>
                        )
                    })
                }
            </Jumbotron>
        )
    }
}