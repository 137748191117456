import React from "react";
import { Link } from "react-router-dom";
import { dataService } from "../../../services/data.service";
import { Col, Row, Spinner } from "reactstrap";

import CoTile from "./CoTile";
import MotionTile from "./MotionTile";
import WaterLevelTile from "./WaterLevelTile";
import WaterRopeTile from "./WaterRopeTile";
import AirQualityTile from "./AirQualityTile";
import Rollover from "./Rollover";

import { ReactComponent as MoveHandle } from "../../../icons/MoveHandle.svg";

// import CO from '../../../icons/CO.svg';
// import PIR from '../../../icons/PIR.svg';
// import WaterLevelSVG from '../../../icons/WaterLevel.svg';
// import AirQuality from '../../../icons/AirQuality.svg';

import { ReactComponent as CO } from "../../../icons/42/CO42.svg";
import { ReactComponent as PIR } from "../../../icons/42/PIR42.svg";
import { ReactComponent as WaterLevelSVG } from "../../../icons/42/WaterLevel42.svg";
import { ReactComponent as AirQuality } from "../../../icons/42/AirQuality42.svg";

export default class OtherTile extends React.Component {
  constructor() {
    super();

    this.state = {
      body: "",
      icon: "",
      currentReading: "",
      isData: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    //Grab sensors, check if any readings returned, then sort
    dataService
      .getReadingsBySensorId(this.props.sensorDisplayInfo.sensorId)
      .then((readings) => {
        if (readings.length > 0) {
          readings.sort(function (a, b) {
            var dateA = new Date(a.readingTime),
              dateB = new Date(b.readingTime);
            return dateA - dateB;
          });
          const isData = true;
          this.sensorSwitch(readings, isData);
        } else {
          let noData = <div className="no-data">INSUFFICIENT DATA</div>;
          const isData = false;
          const data = [{ type: this.props.sensorDisplayInfo.type }];
          this.sensorSwitch(data, isData);
          this.setState({ body: noData, isLoading: false });
        }
      })
      .catch((error) => {
        console.log("catching error");
        this.setState({ error: true });
      });
  }

  sensorSwitch = (data, isData) => {
    let body;
    let icon,
      size = 35;
    switch (data[0].type) {
      case "PIR":
        if (isData === true) {
          body = <MotionTile data={data} type={data[0].type} />;
        }
        icon = <PIR width={size} height={size} />;
        break;
      case "CO LEVEL":
        if (isData === true) {
          body = <CoTile data={data} type={data[0].type} />;
        }
        icon = <CO width={size} height={size} />;
        break;
      case "WATER LEVEL":
        if (isData === true) {
          body = <WaterLevelTile data={data} type={data[0].type} />;
          this.setState({
            currentReading:
              data[data.length - 1].value + " " + data[data.length - 1].unit,
          });
        }
        icon = <WaterLevelSVG width={size} height={size} />;
        break;
      case "WATER ROPE":
        if (isData === true) {
          body = <WaterRopeTile data={data} type={data[0].type} />;
        }
        break;
      case "AIR QUALITY":
        if (isData === true) {
          body = (
            <AirQualityTile
              data={data}
              type={data[0].type}
              sensorId={this.props.sensorDisplayInfo.sensorId}
            />
          );
        }
        icon = <AirQuality width={size} height={size} />;
        break;
      default:
        break;
    }
    this.setState({
      body: body,
      icon: icon,
      data: data,
      isLoading: false,
      isData: isData,
    });
  };

  render() {
    const { sensorDisplayInfo } = this.props;

    let headerStyles = {
      backgroundColor: sensorDisplayInfo.headColour,
    };

    let bodyStyles = { backgroundColor: "" };

    //Set the background colour to be amber or red if they are alerting
    if (
      this.state.isData === true &&
      this.state.data[this.state.data.length - 1].alertLevel === 1
    ) {
      bodyStyles.backgroundColor = "#F7931E";
      if (sensorDisplayInfo.type === "AIR QUALITY") {
        bodyStyles.backgroundColor = "#EA7E00";
      }
    } else if (
      this.state.isData === true &&
      this.state.data[this.state.data.length - 1].alertLevel > 1
    ) {
      bodyStyles.backgroundColor = "#FF0000";
      if (sensorDisplayInfo.type === "AIR QUALITY") {
        bodyStyles.backgroundColor = "#CC0000";
      }
    } else {
      bodyStyles.backgroundColor = sensorDisplayInfo.bodyColour;
    }

    return (
      <div className="graph-tile">
        <Col lg={12} className="header-wrapper" style={headerStyles}>
          <Row className="text-center header">
            <Col lg={2} className="icon">
              {this.state.icon}
            </Col>
            <Col lg={8} className="title">
              {sensorDisplayInfo.type} <br />
              <span className="user-defined-name">
                {sensorDisplayInfo.name.toUpperCase()}
              </span>
            </Col>
            <Col lg={2} className="drag-handle">
              <MoveHandle width={15} />
            </Col>
          </Row>
        </Col>

        {this.state.isLoading ? (
          <Col lg={12} className="body" style={bodyStyles}>
            <div className="tile-loading">
              <Spinner className="tile-spinner" />
            </div>
          </Col>
        ) : this.state.isData ? (
          <Link
            to={{
              pathname: "/detail",
              state: {
                sensorDisplayInfo: sensorDisplayInfo,
                roomName: sensorDisplayInfo.roomName,
                siteAddress: this.props.siteAddress,
                siteName: this.props.siteName,
              },
            }}
          >
            <Rollover />
            <Col lg={12} className="body " style={bodyStyles}>
              <div className="current-reading">{this.state.currentReading}</div>
              {this.state.body}
              <Rollover />
            </Col>
          </Link>
        ) : (
          <Col lg={12} className="body" style={bodyStyles}>
            <div className="current-reading">{this.state.currentReading}</div>
            {this.state.body}
          </Col>
        )}
      </div>
    );
  }
}
