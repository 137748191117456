export const intervalService = {
    setup
}

//Sets up a function to be be on a timeout and works between refreshes by using the local storage
function setup(callback, interval) {
    var key = 'cacheInterval'
    var now = Date.now();
    var timeInMs = localStorage.getItem(key);
    var executeCallback = function () {
        localStorage.setItem(key, Date.now());
        callback();
    }
    if (timeInMs) { // User has visited
        var time = parseInt(timeInMs);
        var delta = now - time;
        if (delta > interval) { // User has been away longer than interval
            setInterval(executeCallback, interval);
        } else { // Execute callback when we reach the next interval
            setTimeout(function () {
                setInterval(executeCallback, interval);
                executeCallback();
            }, interval - delta);
        }
    } else {
        setInterval(executeCallback, interval);
    }
    localStorage.setItem(key, now);
}