import React from "react";
import { dataService } from "../../services/data.service";
import { Responsive, WidthProvider } from "react-grid-layout";
import { history } from "../../services/history.service";
import { tileService } from "../../services/tile.service";
import { Col, Input, Row } from "reactstrap";

import GraphTile from "./tiles/GraphTile";
import OtherTile from "./tiles/OtherTile";
import AssetTile from "./tiles/AssetTile";
import WifiDetails from "./tiles/WifiDetails";
import MiniTile from "./tiles/MiniTile";
import EnergyEfficiencyTile from "./tiles/EnergyEfficiencyTile";
import TopDefects from "./tiles/TopDefectsTile";

import PartyModeModal from "../modals/PartyModeModal";
import CallBoxModal from "../modals/CallBox";
import LiveFeedModal from "../modals/LiveFeed";
import { Mixpanel } from '../../Mixpanel';
import "./Dashboard.scss";

//This lets the grid layout change size with the screen
const ResponsiveGridLayout = WidthProvider(Responsive);

export default class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      processedData: [],
      sensors: [],
      layouts: [],
      sortBy: "Highest Alert",
      showing: "",
      isLoading: true,
      allTiles: false,
      animated: "",
    };
  }

  componentDidMount() {
    //Must come from clicking on a room or site, otherwise sent back
    if (typeof this.props.location.state == "undefined") {
      history.replace("/");
    } else {
      //Gets all tiles from cache, if none then run service first
      dataService.getAllTiles().then((tiles) => {
        if (tiles.length <= 0) {
          tileService.createTileData().then(() => {
            dataService
              .getTilesByLocationId(
                this.props.location.state.siteId,
                this.props.location.state.roomId
              )
              .then((tiles) => {
                this.setState({ tileData: tiles });
                this.generateTiles(tiles);
              });
          });
        } else {
          dataService
            .getTilesByLocationId(
              this.props.location.state.siteId,
              this.props.location.state.roomId
            )
            .then((tiles) => {
              this.setState({ tileData: tiles });
              this.generateTiles(tiles);
            });
        }
      });
    }

    //Using CSS classes I can get rid of the initial animation, but keep the moving animations
    setTimeout(() => this.setState({ animated: "animated" }), 3000);
  }

  hasData = (sensorId) => {
    //Returns true or false depending on whether a sensor has any readings attached to it
    return new Promise((resolve) => {
      dataService.getReadingsBySensorId(sensorId).then((readings) => {
        if (readings.length > 0) {
          return resolve(true);
        } else {
          return resolve(false);
        }
      });
    });
  };

  generateTiles = (tileData, sortType) => {
    this.getRooms().then((rooms) => {
      let allTiles = [];
      const miniTiles = tileService.getMiniTiles();

      //Returns an array motion and light sensors, if they are in the same room
      this.checkMatches(tileData).then((matches) => {
        //Pushes a tile for each set of matches
        matches.forEach((match) => {
          allTiles.push(
            <EnergyEfficiencyTile sensors={match} isMini={false} />
          );
        });

        //Add sensors to an array and checks for Tile type
        let tiles = tileData.map((sensor) => {
          //If no data then won't return a sensor
          return this.hasData(sensor.sensorId).then((bool) => {
            //Checks for type of tile, creates one and returns it
            if (bool) {
              if (sensor.isGraph === true) {
                return (
                  <GraphTile
                    siteName={this.props.location.state.siteName}
                    siteAddress={this.props.location.state.siteAddress}
                    sensorDisplayInfo={sensor}
                    isMini={false}
                  />
                );
              } else {
                if (sensor.isSensor === true) {
                  return (
                    <OtherTile
                      siteName={this.props.location.state.siteName}
                      siteAddress={this.props.location.state.siteAddress}
                      sensorDisplayInfo={sensor}
                      isMini={false}
                    />
                  );
                } else {
                  return (
                    <AssetTile
                      siteName={this.props.location.state.siteName}
                      siteAddress={this.props.location.state.siteAddress}
                      sensorDisplayInfo={sensor}
                      isMini={false}
                    />
                  );
                }
              }
            }
          });
        });

        //After the map has finished, clear any undefined and carry on
        Promise.all(tiles).then((tiles) => {
          tiles = tiles.filter((tile) => tile !== undefined);
          allTiles.push(...tiles);

          //Add top defects
          allTiles.push(<TopDefects site={this.state.site} isMini={false} />);

          //Add wifi tile
          allTiles.push(
            <WifiDetails
              site={this.props.location.state.siteId}
              room={this.props.location.state.roomId}
              isMini={false}
            />
          );

          //Add the mini tiles to the array
          let topSix = [];
          miniTiles[0].forEach((tile) => {
            topSix.push(
              <MiniTile
                name={tile.name}
                icon={tile.icon}
                colour={tile.colour}
                onClick={this.toggleModal}
                modalRef={tile.modalRef}
                site={this.props.location.state.siteId}
                room={this.props.location.state.roomId}
                siteName={this.props.location.state.siteName}
                siteAddress={this.props.location.state.siteAddress}
                isMini={true}
              />
            );
          });

          miniTiles[1].forEach((tile) => {
            allTiles.push(
              <MiniTile
                name={tile.name}
                icon={tile.icon}
                colour={tile.colour}
                onClick={this.toggleModal}
                modalRef={tile.modalRef}
                site={this.props.location.state.siteId}
                room={this.props.location.state.roomId}
                siteName={this.props.location.state.siteName}
                siteAddress={this.props.location.state.siteAddress}
                isMini={true}
              />
            );
          });

          //Sort tiles based on dropdown options
          allTiles = this.sortTiles(allTiles, sortType);

          //Insert the 6 mini tiles specified to be in the top right
          allTiles.splice(3, 0, ...topSix);

          //Generate layouts for all the tiles
          const layouts = this.generateAllLayouts(allTiles);

          //Put tiles into their own data grid div
          let tilesArray = [];
          allTiles.forEach((tile, i) => {
            tilesArray.push(
              <div data-grid={layouts[i]} key={i.toString()}>
                {tile}
              </div>
            );
          });

          //Return layouts and tiles
          this.setState({
            tiles: allTiles,
            tilesArray: tilesArray,
            layouts: layouts,
            isLoading: false,
          });
        });
      });
    });
  };

  getRooms() {
    //Returns the rooms for a site depending on whether you came from a room or a site
    let roomNames = [{ id: -1, name: "Entire Site" }];
    return new Promise((resolve) => {
      const { siteId, roomId } = this.props.location.state;

      if (siteId !== undefined) {
        dataService.getRoomsBySiteId(siteId).then((rooms) => {
          roomNames.push(...rooms);
          this.setState({
            site: siteId,
            rooms: roomNames,
            showing: "Entire Site",
          });
          return resolve(rooms);
        });
      } else if (roomId !== undefined) {
        dataService.getRoomById(roomId).then((room) => {
          dataService.getRoomsBySiteId(room.site_Id).then((rooms) => {
            roomNames.push(...rooms);
            this.setState({
              site: room.site_Id,
              rooms: roomNames,
              showing: room.name,
            });
            return resolve(rooms);
          });
        });
      }
    });
  }

  sortTiles(allTiles, sortType) {
    let first = undefined,
      toSort = [];
    allTiles.forEach((tile, i) => {
      //Check if tile is graph/asset/other
      if (tile.props.sensorDisplayInfo) {
        //Get the first in a series of graph/asset/other tiles
        if (first === undefined) {
          first = i;
        }

        toSort.push(tile);
      }
    });

    //Catch it the first time
    if (sortType === undefined) {
      sortType = "Highest Alert";
    }

    //pick type of sorting
    switch (sortType) {
      case "Highest Alert":
        toSort = toSort.sort((a, b) => {
          return (
            b.props.sensorDisplayInfo.alertLevel -
            a.props.sensorDisplayInfo.alertLevel
          );
        });
        break;
      case "Lowest Alert":
        toSort = toSort.sort((a, b) => {
          return (
            a.props.sensorDisplayInfo.alertLevel -
            b.props.sensorDisplayInfo.alertLevel
          );
        });
        break;
      case "A-Z":
        toSort = toSort.sort((a, b) => {
          let nameA = a.props.sensorDisplayInfo.name.toUpperCase();
          let nameB = b.props.sensorDisplayInfo.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      case "Z-A":
        toSort = toSort.sort((a, b) => {
          let nameA = b.props.sensorDisplayInfo.name.toUpperCase();
          let nameB = a.props.sensorDisplayInfo.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        break;
      default:
        toSort = toSort.sort((a, b) => {
          return (
            b.props.sensorDisplayInfo.alertLevel -
            a.props.sensorDisplayInfo.alertLevel
          );
        });
        break;
    }

    allTiles.splice(first, toSort.length, ...toSort);

    return allTiles;
  }

  handleSort = (obj) => {
    this.setState({ isLoading: true, sortBy: obj.target.value });

    this.generateTiles(this.state.tileData, obj.target.value);
  };

  handleRoom = (obj) => {
    const index = obj.target.selectedIndex;

    //Changes the props and refreshes page depending on which room/entire site clicked from dropdown
    if (obj.target.value !== "Entire Site") {
      history.replace({
        pathname: "/dashboard",
        state: {
          roomId: this.state.rooms[index].id,
          siteName: this.props.location.state.siteName,
          siteAddress: this.props.location.state.siteAddress,
        },
      });
      window.location.reload();
    } else {
      history.replace({
        pathname: "/dashboard",
        state: {
          siteId: this.state.site,
          siteName: this.props.location.state.siteName,
          siteAddress: this.props.location.state.siteAddress,
        },
      });
      window.location.reload();
    }
  };

  toggleModal = (modalName) => {
    this.refs[modalName].toggleModal();
  };

  checkMatches = (allSensors) => {
    return new Promise((resolve) => {
      //Get all light and motion sensors
      const lights = allSensors.filter((sensor) => sensor.type === "LIGHT");
      const motions = allSensors.filter((sensor) => sensor.type === "PIR");

      //Run through each light sensor
      let matches = lights.map(async (light) => {
        let match = "";
        //See if there are any motion sensors in the same room as the light sensor
        match = motions.filter((motion) => motion.roomId === light.roomId);

        //If there are matches then check both sensors have data before proceding
        if (match.length > 0) {
          const pirBool = await this.hasData(match[0].sensorId);
          const lightBool = await this.hasData(light.sensorId);
          if (lightBool === true && pirBool === true) {
            return { motion: match[0], light: light };
          }
        }
      });

      //Once checked for data then return any matches
      Promise.all(matches).then((matches) => {
        matches = matches.filter((match) => match !== undefined);

        return resolve(matches);
      });
    });
  };

  generateAllLayouts = (allTiles) => {
    var layouts = [];
    var x = 0,
      y = 0,
      w = 1,
      cols = 5;
    var yCount = 0;

    //Checks window width and changes number of columns accordingly
    if (window.matchMedia("(max-width: 1402px)").matches) {
      cols = 4;
    } else {
      cols = 5;
    }

    allTiles.forEach((tile, i) => {
      //Mini tiles
      if (tile.props.isMini) {
        var smallLayout = { i: i.toString(), x: x, y: y, w: w, h: 1 };
        layouts.push(smallLayout);
        //If x is divisible by 5 and yCount has run is 2
        if (Number.isInteger((x + 1) / cols) && yCount === 2) {
          x = 0;
          y++;
          yCount = 0;
        } else if (yCount === 2) {
          x++;
          y -= 2;
          yCount = 0;
        } else {
          y++;
          yCount++;
        }
        //Large tiles
      } else {
        yCount = 0;

        var largeLayout = { i: i.toString(), x: x, y: y, w: w, h: 3 };
        layouts.push(largeLayout);
        if (Number.isInteger((x + 1) / cols)) {
          x = 0;
          y++;
        } else {
          x++;
        }
      }
    });

    return layouts;
  };

    render() {
        Mixpanel.track('Dashboard');
    return this.state.isLoading ? (
      ""
    ) : (
      <React.Fragment>
        <Row className="top-filter">
          <Col>
            <span className="showing">
              SHOWING: <strong>{this.state.showing.toUpperCase()}</strong>
            </span>
          </Col>
          <Col className="dropdowns">
            <div>
              <span className="clr--blue bold">ROOM</span>
              <Input
                type="select"
                value={this.state.showing}
                onChange={(obj) => this.handleRoom(obj)}
              >
                {this.state.rooms.map((room) => {
                  return <option key={room.id}>{room.name}</option>;
                })}
              </Input>
            </div>
            <div>
              <span className="clr--blue bold">SORT BY</span>
              <Input
                type="select"
                value={this.state.sortBy}
                onChange={(obj) => this.handleSort(obj)}
              >
                <option>Highest Alert</option>
                <option>Lowest Alert</option>
                <option>A-Z</option>
                <option>Z-A</option>
              </Input>
            </div>
          </Col>
        </Row>

        <PartyModeModal ref="partyMode" />
        <CallBoxModal ref="callBox" />
        <LiveFeedModal ref="liveFeed" />
        <ResponsiveGridLayout
          classtype="layout m-4"
          className={this.state.animated}
          rowHeight={100}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 5, md: 4, sm: 3, xs: 2, xxs: 1 }}
          layouts={{ lg: this.state.layouts, md: this.state.layouts }}
          isResizable={false}
          // width={"1080px"}
          draggableHandle=".drag-handle"
        >
          {this.state.tilesArray}
        </ResponsiveGridLayout>
      </React.Fragment>
    );
  }
}
