import React from 'react';
import DataTable from 'react-data-table-component';
import { history } from '../../../services/history.service';
import { Col, Row, Spinner } from 'reactstrap';
import { apiService } from '../../../services/api.service';

import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as WorkHistoryIcon } from '../../../icons/WorkHistory.svg';

import './WorkHistory.scss'

const columns = [
    {
        name: 'Job ID',
        selector: 'jobId',
        sortable: true,
    },
    {
        name: 'Engineer',
        selector: 'engineer',

        sortable: true,
    },
    {
        name: 'Type',
        selector: 'type',
        sortable: true,
    },
    {
        name: 'Notes',
        selector: 'notes',
        sortable: true,
        grow: 3,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
    },
    {
        name: 'AssetId',
        selector: 'assetId',
        sortable: true,
    },
];

export default class WorkHistory extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            data: [],
        }

    }

    componentDidMount() {
        apiService.getData("/workhistory")
        .then(work => {
            work.reverse();
            this.setState({data: work, isLoading: false})
        })
    }

    handleAssetClick(obj) {
        // console.log(obj)
    }


    render() {
        const { data } = this.state;

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="work-frame">
                    <Row noGutters className="work-header text-center">
                        <Col lg={1} className="icon">
                            {<WorkHistoryIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Work History
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    <div className="table-div">
                        <DataTable
                            columns={columns}
                            data={data}
                            striped
                            dense
                            highlightOnHover
                            pointerOnHover
                            pagination
                            paginationPerPage={15}
                            paginationRowsPerPageOptions={[15, 30, 50, 100]}
                            fixedHeader
                            fixedHeaderScrollHeight="500px"
                            onRowClicked={(obj) => this.handleAssetClick(obj)}
                        />
                    </div>
                </div>
        )
    }
}