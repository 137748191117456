import React from 'react';

import { ReactComponent as RoundedTriangle } from '../../icons/RoundedTriangle.svg';

export default class DetailHighLow extends React.Component {
    render() {
        
        const { peak, type, value, date } = this.props;
        var cssClass, icon, text; 

        if(peak) {
            text = "PEAK"
            cssClass = "high";
            icon = <RoundedTriangle width={30} className="high-icon" /> 
        } else {
            text = "LOW";
            cssClass = "low";
            icon = <RoundedTriangle width={30} className="low-icon" /> 
        }

        return (
            <div className={cssClass}>
                { text }  <br />
                { type }  <br />
                { icon }
                <strong>{ value }</strong>
                { date }
            </div>
        )
        
    }
}