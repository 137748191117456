import React from 'react';
import DataTable from 'react-data-table-component';
import { history } from '../../../services/history.service';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { cacheService } from '../../../services/cache.service';

import Notes from '../notes/Notes';

import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as CurrentDefects } from '../../../icons/CurrentDefects.svg';
import { Mixpanel } from '../../../Mixpanel';
import './Defects.scss';

export default class Defects extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            rooms: [],
        }
    }

    componentDidMount() {
        dataService.getAllDefects()
            .then(defects => {
                if (defects.length === 0) {
                    cacheService.fillAssetDefectCache()
                        .then(() => {
                            dataService.getAllDefects()
                                .then(defects => {
                                    this.afterDataImport(defects)
                                })
                        })
                } else {
                    dataService.getAllDefects()
                        .then(defects => {
                            this.afterDataImport(defects)
                        })
                }
            })
    }

    getColumns() {
        const columns = [
            {
                name: 'Image',
                selector: 'thumbnailImage',
                sortable: false,
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
            },
            {
                name: 'Description',
                selector: 'description',
                sortable: true,
                grow: 2,
            },
            {
                name: 'Asset',
                selector: 'assetName',
                sortable: true,
            },
            {
                name: 'Status',
                selector: 'defectStatus',
                sortable: true,
            },
        ];
        return columns;
    }

    afterDataImport = (defects) => {
        let data = [], rooms = [];
        const user = JSON.parse(localStorage.getItem("currentUser"))
        this.checkLocation()
            .then(roomNumbers => {
                rooms = roomNumbers;

                //check each defect and only add the ones that match the users account and the site they're in
                defects.forEach(defect => {
                    if (defect.accountId === user.AccountId) {
                        if (defect.thumbnailImage !== null && defect.thumbnailImage !== "") {
                            defect.thumbnailImage = <img src={`data:image/;base64,${defect.thumbnailImage}`} style={{ height: "30px" }} alt="Thumbnail" />
                        }

                        if (rooms.includes(defect.room_Id)) {
                            data.push(defect)
                        }
                    }
                });
                this.setState({ data: data, isLoading: false })
            })
    }

    checkLocation = () => {
        //Check if you have site or room id, then get rooms numbers from either one

        return new Promise((resolve) => {

           
            // Hardcoded to Id 1 - Vijay - 29-05-2023 -- Logic needs to be verified from the parent pages
            if (this.props.location.state !== undefined) {
               
                dataService.getRoomsBySiteId(1)
                    .then(rooms => {
                        var roomNumbers = rooms.map(room => {
                            return room.id;
                        });

                        Promise.all(roomNumbers)
                            .then(roomNumbers => {
                                this.setState({ site: this.props.location.state.site })
                                resolve(roomNumbers)
                            });
                    })
            } else if (this.props.location.state.room !== undefined) {
                dataService.getRoomById(this.props.location.state.room)
                    .then(room => {
                        dataService.getSiteById(room.site_Id)
                            .then(site => {
                                dataService.getRoomsBySiteId(site.id)
                                    .then(rooms => {
                                        var roomNumbers = rooms.map(room => {
                                            return room.id;
                                        });

                                        Promise.all(roomNumbers)
                                            .then(roomNumbers => {
                                                this.setState({ site: site.id })
                                                resolve(roomNumbers)
                                            });
                                    })
                            })
                    })
            }
        })
    }

    handleDefectClick = (obj) => {
        obj.thumbnailImage = "";
        history.push({
            pathname: "/defectdetails",
            state: {
                site: this.state.site,
                room: this.props.location.state.room,
                defect: obj,
                update: true,
                siteAddress: this.props.location.state.siteAddress,
                siteName: this.props.location.state.siteName
            }
        })
    }

    render() {
        Mixpanel.track('Defects');
        const columns = this.getColumns();

        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="defect-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<CurrentDefects width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Defects
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {this.state.data.length > 0 ?
                        <React.Fragment>
                            <div className="table-div">
                                <Button className="new-defect" color="secondary" onClick={() => history.push({ pathname: "/defectdetails", state: { site: this.props.location.state.site, room: this.props.location.state.room, update: false, siteAddress: this.props.location.state.siteAddress, siteName: this.props.location.state.siteName } })}>Add new defect</Button>
                                <DataTable
                                    columns={columns}
                                    data={this.state.data}
                                    striped
                                    dense
                                    highlightOnHover
                                    pointerOnHover
                                    pagination
                                    paginationPerPage={15}
                                    paginationRowsPerPageOptions={[15, 30, 50, 100]}
                                    defaultSortField="defectStatus"
                                    fixedHeader
                                    fixedHeaderScrollHeight="500px"
                                    onRowClicked={(obj) => this.handleDefectClick(obj)}
                                />
                            </div>
                            <Notes type="Defects" />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Button className="new-defect" color="secondary" onClick={() => history.push({ pathname: "/defectdetails", state: { site: this.props.location.state.site, room: this.props.location.state.room, update: false, siteAddress: this.props.location.state.siteAddress, siteName: this.props.location.state.siteName } })}>Add new defect</Button>
                            <div className="empty-alerts">NO DEFECTS</div>
                        </React.Fragment>}
                </div>
        )
    }
}