import React from 'react';
import { Spinner } from 'reactstrap';
import { history } from '../../services/history.service';
import Registration from '../login/Registration';

import './Admin.scss';

export default class SiteAdmin extends React.Component {
    constructor() {
        super()

        this.state = {
            isLoading: false,
        }
    }

    componentDidMount() {
        if (this.checkAdmin()) {
            //carry on
            this.setState({ isLoading: false });
        } else {
            history.goBack();
        }
    }

    checkAdmin() {
        const user = JSON.parse(localStorage.getItem("currentUser"))

        if (user.role === "Admin" || user.role === "SPS Admin") {
            return true;
        } else {
            return false;
        }
    }


    render() {


        return (

            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="site-admin">
                    
                </div>
        )
    }
}