import React from 'react';
import { dataService } from '../../../services/data.service';

import { ReactComponent as EnergyBackground } from '../../../icons/EnergyBackground.svg';
import { ReactComponent as EnergyColour } from '../../../icons/EnergyColour.svg';
import { ReactComponent as EnergyDial } from '../../../icons/EnergyDial.svg';

export default class DetailEnergyEfficiency extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            roomHasMotion: false,
            percentage: "",
        }
    }

    componentDidMount() {
        this.getReadings(this.props.readings, this.props.luxSensorId);
    }

    getReadings = (luxReadings) => {
        //First off, get all the sensors
        dataService.getAllSensors()
            .then((sensors) => {
                let luxSensor, motionSensor;
                //filter out everything but this light sensor and any motion sensors that are in the same room
                luxSensor = sensors.filter(sensor => sensor.type === "LIGHT" && sensor.sensorId === luxReadings[0].sensorId);
                motionSensor = sensors.filter(sensor => sensor.type === "PIR" && sensor.roomId === luxSensor[0].roomId);

                //If there are any motion sensors that share a room
                if (motionSensor.length > 0) {
                    //Get the readings for the motion sensor
                    dataService.getReadingsBySensorId(motionSensor[0].sensorId)
                        .then((readings) => {
                            //Sort both by date and time
                            luxReadings.sort(function (a, b) {
                                var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                                return dateA - dateB;
                            });

                            readings.sort(function (a, b) {
                                var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
                                return dateA - dateB;
                            });
                            this.calculateEfficiency(luxReadings, readings);
                        })
                        .catch((error) => {
                            console.log("catching error");
                            this.setState({ error: true });
                        })
                } else {
                    //No motion sensors in the same room as the light sensor
                    this.setState({ roomHasMotion: false })
                }

            })
            .catch((error) => {
                console.log("catching error");
                this.setState({ error: true });
            })
    }

    calculateEfficiency = (luxReadings, pirReadings) => {

        //Get segments of motion detected

        /*
            The motion sensors work by sending a message when there is movement and as soon as the movement stops it send another to say no motion detected
            By using this we can work out time where there are people around by checking if the sequence is 1,0,1,0,1,0,1,0
            As soon as there is no more movement the sensor will wait it's usual interval to send another 0, so if two zeroes are in a row then we know that segment has ended, e.g:
            0,0,1,0,1,0,1,0,0 - There were 3 points in this segment so we can measure from the first 1 and then until the last 1 to get the total time on
        */

        let start = null, end = null, endChanged = false, luxIndex = 0, onPoints = 0, offPoints = 0;

        //Loop over all the motion readings
        for (let i = 0; i < pirReadings.length; i++) {

            //Beginning edge cases
            if (pirReadings[i].value === "True" && i === 0) {
                start = 0;
            } else if (pirReadings[i].value === "True" && i === 1) {
                start = 1;
            }

            //If there was a gap before the current reading then make start here
            if (pirReadings[i] !== undefined && pirReadings[i - 1] !== undefined && pirReadings[i - 2] !== undefined) {
                if (pirReadings[i].value === "True" && pirReadings[i - 1].value === "False" && pirReadings[i - 2].value === "False") {
                    start = i;
                }
            }

            //This is the end of a section of motion
            if (pirReadings[i] !== undefined && pirReadings[i + 1] !== undefined && pirReadings[i + 2] !== undefined) {
                if (pirReadings[i].value === "True" && pirReadings[i + 1].value === "False" && pirReadings[i + 2].value === "False") {
                    end = i;
                    endChanged = true;
                }
            }

            //Once it reaches the end of the array, check if it needs to end the segment
            if (pirReadings[i + 1] === undefined && pirReadings[i].value === "True") {
                end = i;
                endChanged = true;
            } else if (pirReadings[i + 1] === undefined && pirReadings[i - 1].value === "True") {
                end = i - 1;
                endChanged = true;
            }

            //Get the dates from milliseconds from start and end, end-start and add to running total 
            if (endChanged === true && end > start) {

                let startTime = pirReadings[start].readingTime;
                let endTime = pirReadings[end].readingTime;

                startTime = startTime.getTime();
                endTime = endTime.getTime();

                while (1) {

                    //Need to check when current reading is greater than or equal to endtime then break
                    if (luxReadings[luxIndex] !== undefined) {
                        let currentTime = luxReadings[luxIndex].readingTime;
                        currentTime = currentTime.getTime();

                        //Check to see if the lights are on or off when there is motion
                        if (luxReadings[luxIndex].value > 5) {
                            if (currentTime > startTime && currentTime <= endTime) {
                                onPoints++;
                            } else {
                                offPoints++;
                            }
                        }
                        if (currentTime >= endTime) {
                            break;
                        }
                        luxIndex++;
                    } else {
                        break;
                    }
                }

                endChanged = false;
            }
        }
        let percentage = (onPoints / (onPoints + offPoints)) * 100;

        //Must check for edge cases, if one or both have no readings
        const lux = luxReadings.filter(reading => reading.value > 5);
        const pir = pirReadings.filter(reading => reading.value === "True");

        if (onPoints === 0 && offPoints === 0) {
            percentage = 100;
        }

        if (lux.length === 0 && pir.length === 0) {
            percentage = 100;
        } else if (pir.length > 0 && lux.length === 0) {
            percentage = 100;
        } else if (lux.length > 0 && pir.length === 0) {
            percentage = 0;
        }

        percentage = percentage.toFixed(2);

        this.setState({ percentage: percentage, roomHasMotion: true, isLoading: false })
    }

    render() {
        if (this.state.roomHasMotion === true) {
            if (this.state.isLoading === false) {
                // const alert = this.state.latest.alertLevel

                let value = parseFloat(this.state.percentage), bgColour;

                if (value > 80) {
                    //Green
                    bgColour = "#8CC63F";
                } else if (value >= 58) {
                    //Amber
                    bgColour = "#F7931E";
                } else if (value < 58) {
                    //Red
                    bgColour = "#FF0000";
                }

                //Rotate the dial part of the SVG depending on percentage calculated
                let transform = "translate(0, -106.2%) rotate(" + (value / 100) + "turn) scale(1.2)"

                const styles = {
                    transform: transform,
                }

                const style = { backgroundColor: bgColour };

                return (
                    <div className="energy-efficiency" style={style}>
                        <h3>ENERGY EFFICIENCY</h3>
                        <div className="efficiency-bar">
                            <EnergyBackground className="background" />
                            <EnergyColour className="colour" />
                            <EnergyDial className="needle" style={styles} />
                            <div className="value" >{value + "%"}</div>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div className="no-motion-in-room">
                    NO MOTION DATA AVAILABLE
                </div>
            )
        }
    }
}