import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col, Form, Input, Label } from 'reactstrap';
import { authService } from '../../services/auth.service';
import { cacheService } from '../../services/cache.service';
import { twilioService } from '../../services/twilio.service';
import LoginProcess from './LoginProcess';

import './login.scss';

import AHU from '../../images/AHU.png';
import Logo from '../../images/MetlLogoBottom.png';
import { ReactComponent as SadFace } from '../../icons/SadFace.svg';
import { Mixpanel } from '../../Mixpanel';

export default class Login extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            isLoading: false,
            username: '',
            password: '',
            incorrectCredentials: false,
        }
    }

    componentDidMount() {
        //Keep checking the cache state
        cacheService.cacheLoaded.subscribe(cacheState => {
            if (cacheState.populated === false && this.props.currentUser !== null) {
                this.setState({ isLoading: true, currentStage: cacheState.status });
            }
        })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    login = (formData) => {
        this.setState({ isLoading: true });

        //Make sure local storage is empty first
        localStorage.clear();
        formData.preventDefault();

        let { username, password } = this.state;

        //Call the auth login process, if fine then user is passed out
        authService.login(username, password)
            .then(user => {
                if (!user) {
                    this.setState({ incorrectCredentials: true, isLoading: false });
                    Mixpanel.track('Unsuccessful login');

                } else {
                    //If login successful fill the cache and then do the twilio set-up
                    cacheService.fillCache().then(() => {
                        twilioService.setupTwilio();
                        Mixpanel.track('Successful login');
                    })
                }
            }).catch(error => {
                this.setState({ incorrectCredentials: true, isLoading: false });
                Mixpanel.track('Unsuccessful login');
            });
    }

    render() {

        const { currentUser, cacheState } = this.props;
        const { isLoading } = this.state;

        if (currentUser !== null && cacheState.populated === true && isLoading === false) {
            return (
                <Redirect to={{ pathname: "/" }} />
            )
        }
        return (
            <Row noGutters>
                <Col xl={{ size: 10, offset: 1 }} className="login-wrapper clearfix col-xxl-8 offset-xxl-2">
                    <Col xl={6} className="float-left login-image">
                        <img src={AHU} width={600} alt="Air handling unit" />
                    </Col>
                    <Col xl={6} className="float-right content-wrapper">
                        <Col xl={12} className="login-brand text-center">
                            <img src={Logo} width={200} alt={"Logo"} style={{verticalAlign:"baseline"}} className="" />
                            DASHBOARD
                        </Col>
                        <br />
                        <br />
                        {
                            isLoading ?
                                <div className="login-loading">
                                    <LoginProcess cacheState={cacheState} />
                                </div>
                                :
                                <React.Fragment>
                                    <div className="register-link">Don't have an account? &nbsp;&nbsp;<a href="/register">Sign Up</a></div>
                                    <Form className="login-form" onSubmit={(ev) => this.login(ev)}>
                                        <Label for="username">Username</Label>
                                        <Input type="text" className="form-control" name="username" id="username" onChange={(e) => this.handleChange(e)} />
                                        <br />
                                        <Label for="password">Password</Label>
                                        {this.state.incorrectCredentials &&
                                            <div className="login-error">
                                                Ooops! Username and/or password incorrect
                                        <SadFace width={20} className="ml-4" />
                                            </div>
                                        }
                                        <Input type="password" className="form-control" name="password" id="password" onChange={(e) => this.handleChange(e)} />
                                        <br />
                                        <br />
                                        <Input type="submit" className="btn btn-primary" value="SIGN INTO SPS DASHBOARD" />
                                        <Label className="terms">By signing in, you agree to&nbsp;<a href="/terms">Terms and Privacy Policy</a></Label>
                                    </Form>
                                </React.Fragment>
                        }
                    </Col>
                </Col>
            </Row>
        );
    }
}