import React from 'react';
import { Bar, XAxis, YAxis, ResponsiveContainer, BarChart } from 'recharts';

import { datetimeformatterService } from '../../../services/datetimeformatter.service';

export default class OtherTile extends React.Component {
    constructor() {
        super()

        this.state = {
            data: "",
        }
    }

    componentDidMount() {
        this.createGraphs(this.props.data);
    }

    processGraphData(reading) {
        //We assume this works, never got a sensor live to test
        let data = [];
        const length = (reading.length - 1);
        let time = datetimeformatterService.formatDate(reading[length].readingTime);

        data.push({ name: time, value: parseFloat(reading[length].value) });
        return data;
    }

    createGraphs(processedData) {
        const graphData = this.processGraphData(processedData);
        let graph =
            <ResponsiveContainer height="78%">
                <BarChart data={graphData} margin={{ top: 0, right: 25, bottom: 5, left: -15 }}>
                    <Bar type="monotone" dataKey="value" stroke="#ffff" fill="#ffff" />
                    <XAxis dataKey="name" stroke="#fff" />
                    <YAxis stroke="#fff" />
                    {/* domain={[0, 100]} for y axis to set a limit between 0 and 100*/}
                </BarChart>
            </ResponsiveContainer>
        this.setState({ data: graph })
    }

    render() {
        return(this.state.data)
    }
}