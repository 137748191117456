import React from 'react';
import { Col, Row } from 'reactstrap';
import { apiService } from '../../../services/api.service';

const alertColors = ["#8CC63F", "#F7931E", "red"]

export default class DetailAirQualityAverage extends React.Component {
    constructor() {
        super()
        this.state = {
            isLoading: true,
            pm: 0,
            selected: [
                "selected",
                "",
                ""
            ]
        }
    }

    componentDidMount() {
        this.getAlertLevels(this.props.reading.value, this.props.sensorId).then(alertLevels => {
            this.setState({ alertLevels: alertLevels, isLoading: false });
        })
    }

    getAlertLevels(reading, sensorId) {
        let alertLevels = [];
        //Call API to get individual air quality alert levels
        return apiService.getData("/alert/aircheck?SensorId=" + sensorId + "&Value=" + reading).then(alerts => {
            alertLevels = alerts.split("|");
    
            if (alertLevels[0] === "No rules found for this sensor!") {
                alertLevels = [0, 0, 0];
                return alertLevels;
            } else {
                return alertLevels;
            }
        })
    }

    changePmNumber(pmNum) {
        //Select which PM value to show and change the CSS class
        let selected = this.state.selected;
        selected = ["", "", ""];
        selected[pmNum] = "selected";

        this.setState({ pm: pmNum, selected: selected })
    }

    render() {
        const average = this.props.values[0], lowValues = this.props.values[1], highValues = this.props.values[2];
        const { pm, alertLevels } = this.state;

        return (
            this.state.isLoading ? 
            ""
            :
            <div className="airquality-values">
                <Row noGutters style={{height: "100%"}}>
                    <Col xs={4} className="airquality-pm-type">
                        <div className={`type ${this.state.selected[0]}`} onClick={(obj) => this.changePmNumber(0, obj)}><h2>PM<sub>1</sub><sup style={{color: alertColors[parseInt(alertLevels[0])] }}>•</sup></h2></div>
                        <div className={`type ${this.state.selected[1]}`} onClick={() => this.changePmNumber(1)}><h2>PM<sub>2.5</sub><sup style={{color: alertColors[parseInt(alertLevels[1])] }}>•</sup></h2></div>
                        <div className={`type ${this.state.selected[2]}`} onClick={() => this.changePmNumber(2)}><h2>PM<sub>10</sub><sup style={{color: alertColors[parseInt(alertLevels[2])] }}>•</sup></h2></div>
                    </Col>
                    <Col xs={8} className="airquality-readings">
                        <span>BEST <br /> <strong>{highValues[pm].value}</strong> <br /> {highValues[pm].date} </span>
                        <hr />
                        <span>WORST <br /> <strong>{lowValues[pm].value}</strong> <br /> {lowValues[pm].date} </span>
                        <hr />
                        <span>AVERAGE <br /> <strong>{average[pm]}</strong> </span> <br />
                    </Col>
                </Row>
            </div>
        )
    }
}