// This React component represents a form for creating a new account.
// It collects information such as account details, admin credentials, and site details.
// Users can add multiple invitees by providing their emails.
// The form includes validation for mandatory fields, email format, and password strength.
// Upon submission, the form data is sent to the backend API for account creation.
// The component uses various icons and styles for a visually appealing interface.
// Users can cancel the form, and successful submissions trigger events and navigation.


import React, { useState } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { ReactComponent as AddUserIcon } from '../../icons/plus.svg';
import { ReactComponent as MinususerIcon } from '../../icons/minus.svg'
import { ReactComponent as CreateAccountIcon } from '../../icons/CreateAccount.svg'
import './CreateAccount.scss';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { apiService } from '../../services/api.service';
import { eventsService } from '../../services/events.service';

import { history } from '../../services/history.service';


const AccountForm = () => {
    const [formData, setFormData] = useState({
        accountName: '',
        adminEmail: '',
        adminUser: '',
        password: '',
        addusers: [],
        name: '',
        address: '',
        latitude:null,
        longitude: null,
        wifiSSID: '',
        wifiPassword: '',
        accountImage: '',
        siteImage: ''
    });
    const [confirmPassword, setConfirmPassword] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [adduser, setAdduser] = useState([]);
    const [errorText, setErrorText] = useState('');

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);

        // If you need to perform additional actions with the image file, you can do it here
    };




    const handleRemoveField = (index) => {
        const updatedFields = adduser.filter((_, i) => i !== index);
        setAdduser(updatedFields);

        const updatedFormData = {
            ...formData,
            addusers: updatedFields,
        };
        setFormData(updatedFormData);
    };


    const handleAddField = () => {
        //const newFieldId = `adduser_${adduser.length + 1}`;
        setAdduser([...adduser, '']);

    };



    const handleTextChange = (e, fieldName, index) => {
        // If index is provided, it means this is an array field
        if (typeof index !== 'undefined') {
            const updatedArrayFields = [...adduser];
            updatedArrayFields[index] = e.target.value;

            const updatedFormData = {
                ...formData,
                addusers: updatedArrayFields,
            };

            setFormData(updatedFormData);
            setAdduser(updatedArrayFields);
        } else {
            // For regular fields
            const updatedFormData = {
                ...formData,
                [fieldName]: e.target.value,
            };

            setFormData(updatedFormData);
        }
    };



    const saveDetails = async () => {

        const user = JSON.parse(localStorage.getItem("currentUser"));
        let toDatabase=[]

         toDatabase = {
            accountName: formData?.accountName,
            accountAdminName: formData?.adminUser,
            adminPassword: formData?.password,
            accountAdminEmail: formData?.adminEmail,
            inviteEmails: formData?.addusers,
            //accountLogo: formData?.accountImage,
            //siteImage: formData?.siteImage,
            siteName: formData?.name,
            siteAddress: formData?.address,
             siteLatitude: parseFloat(formData?.latitude),
             siteLongitude: parseFloat(formData?.longitude),
            siteNetworkSSID: formData?.wifiSSID,
            siteNetworkPassword: formData?.wifiPassword




        }
        
        postToDatabase(toDatabase);
    }




    const postToDatabase = (newAccount) => {
        apiService.postData("/Admin/ProvisionAccount", newAccount)
            .then(() => {
                const message = "New Account added: " + newAccount.adminUser;
                eventsService.newEvent(message)
                    .then(() => {
                        history.goBack();
                    })
            });
    }


    const handleSubmit = () => {
        const requiredFields = ['accountName', 'adminEmail', 'adminUser', 'password', 'name', 'address', 'wifiSSID', 'wifiPassword'];
        for (const field of requiredFields) {
            if (!formData[field]) {
                setErrorText(`Please fill all the mandatory fields`);
                return;
            }
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.adminEmail)) {
            setErrorText('Please enter a valid email address');
            return;
        }

        const expectedPasswordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        if (!expectedPasswordPattern.test(formData.password)) {
            setErrorText('Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long');
            return;
        }

        if (!expectedPasswordPattern.test(formData.wifiPassword)) {
            setErrorText('WiFi Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long');
            return;
        }



        // Check if password and confirm password match
        if (formData.password !== confirmPassword) {
            setErrorText('Password and Confirm Password do not match');
            return;
        }

        // Clear any previous error messages
        setErrorText('');
        saveDetails();
        console.log('Form submitted:', formData);

    };
    const handleCancel = () => {

        history.goBack();

    }

    return (
        <div className="asset-frame">
            <Row noGutters className="detail-header text-cente">
                <Col lg={1} className="icon">
                    {<CreateAccountIcon width={45} />}
                </Col>
                <Col lg={10} style={{ textAlign: 'center' }} className="title">
                    Create Account
                </Col>
                <Col lg={1} className="close-icon">
                    <div style={{ marginLeft: '3rem' }} className="close-icon-wrapper" onClick={() => history.goBack()}>
                        <CloseIcon width={40} height={40} />
                    </div>
                </Col>
            </Row>
            <Form className="form">
                <Row noGutters>
                    {/* Left Column */}
                    <Col md={6} style={{ marginTop: '2rem' }}>
                        <FormGroup>

                            <Label className="asset-label" for="accountName">Account Name*</Label>
                            <Input
                                type="text"
                                id="accountName"
                                placeholder="Account name"
                                value={formData?.accountName}
                                onChange={(e) => handleTextChange(e, "accountName")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="adminEmail">Admin Email*</Label>
                            <Input
                                type="email"
                                id="adminEmail"
                                placeholder="Admin email"
                                value={formData?.adminEmail}
                                onChange={(e) => handleTextChange(e, "adminEmail")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="adminUser">Admin Username*</Label>
                            <Input
                                type="text"
                                id="adminUser"
                                placeholder="Admin username"
                                value={formData?.adminUser}
                                onChange={(e) => handleTextChange(e, "adminUser")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="password">Password*</Label>
                            <Input
                                type="password"
                                id="password"
                                placeholder="Password"
                                value={formData?.password}
                                onChange={(e) => handleTextChange(e, "password")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="confirmPassword">Confirm Password*</Label>
                            <Input
                                type="password"
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="uploadImage">Upload Logo</Label>




                            <Input
                                type="file"
                                id="accountImage"
                                onChange={handleImageChange}
                            />



                            {selectedImage && (
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected"
                                    style={{ maxWidth: '10%', marginLeft: '10px' }}
                                />
                            )}

                        </FormGroup>

                        <div style={{ marginTop: '1rem' }}>
                            <Label style={{ marginLeft: '9rem' }}>
                                Invite User
                                <AddUserIcon style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleAddField} />
                            </Label>



                            {adduser.map((value, index) => (
                                <FormGroup key={index}>

                                    <Input

                                        type="text"
                                        id={`adduser_${index}`}
                                        placeholder="Add email"
                                        value={value}
                                        onChange={(e) => handleTextChange(e, "addusers", index)}
                                        style={{ width: '25rem', marginLeft: '8.9rem' }}
                                    />
                                    <MinususerIcon style={{ cursor: 'pointer' }} onClick={() => handleRemoveField(index)} />
                                </FormGroup>
                            ))}
                        </div>



                    </Col>
                    {/* Right Column */}
                    <Col md={6}>
                        <Label className="asset-label" style={{ fontWeight: 'bold', marginLeft: "8.7rem" }}>Site Detials</Label>
                        <FormGroup>
                            <Label className="asset-label" for="name">Name*</Label>
                            <Input
                                type="text"
                                id="name"
                                placeholder="Name"
                                value={formData?.name}
                                onChange={(e) => handleTextChange(e, "name")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="address">Address*</Label>
                            <Input
                                type="text"
                                id="address"
                                placeholder="Address"
                                value={formData?.address}
                                onChange={(e) => handleTextChange(e, "address")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="latitude">Latitude</Label>
                            <Input
                                type="text"
                                id="latitude"
                                placeholder="Latitude"
                                value={formData?.latitude}
                                onChange={(e) => handleTextChange(e, "latitude")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="longitude">Longitude</Label>
                            <Input
                                type="text"
                                id="longitude"
                                placeholder="Longitude"
                                value={formData?.longitude}
                                onChange={(e) => handleTextChange(e, "longitude")}

                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="wifiSSID">WiFi SSID*</Label>
                            <Input
                                type="text"
                                id="wifiSSID"
                                placeholder="WiFi SSID"
                                value={formData?.wifiSSID}
                                onChange={(e) => handleTextChange(e, "wifiSSID")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="wifiPassword">WiFi Password*</Label>
                            <Input
                                type="password"
                                id="wifiPassword"
                                placeholder="WiFi Password"
                                value={formData?.wifiPassword}
                                onChange={(e) => handleTextChange(e, "wifiPassword")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="asset-label" for="uploadImage">Upload Image</Label>




                            <Input
                                type="file"
                                accept="image/jpeg, image/png"
                                id="siteImage"
                                onChange={handleImageChange}
                            />



                            {selectedImage && (
                                <img
                                    src={URL.createObjectURL(selectedImage)}
                                    alt="Selected"
                                    style={{ maxWidth: '10%', marginLeft: '10px' }}
                                />
                            )}

                        </FormGroup>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className={`save-button ${errorText ? 'btn-danger' : 'btn-primary'}`} color="primary" onClick={handleSubmit}>
                            Create
                        </Button>
                        {errorText && <FormText>{errorText}</FormText>}
                    </Col>
                    <Col>
                        <Button className="cancel-button" color="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </Col>

                </Row>






            </Form>
        </div>
    );
};

export default AccountForm;
