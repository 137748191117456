import React from 'react';
import { Row, Col } from 'reactstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import Battery from './Battery';
import Signal from './Signal';
import GradientSVG from './SvgGradient';

import 'react-circular-progressbar/dist/styles.css';

export default class DetailSensorHealth extends React.Component {

    render() {
        const { reading } = this.props, battery = reading.batteryLevel, signal = reading.signalStrength;

        const value = (battery + signal) / 2;

        const styles = {
            root: {
                width: "80%",
                height: "80%",
            },
            path: {
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transform: 'rotate(0.62turn)',
                transformOrigin: 'center center',
            },
            trail: {
                // Rotate the trail
                transform: 'rotate(0.62turn)',
                transformOrigin: 'center center',
            },
            text: {
                fontSize: "24px",
                fill: "#000"
            }
        }

        return (
            <div className="sensor-health-wrapper">
                <CircularProgressbarWithChildren className="sensor-health-progress"
                    value={value}
                    circleRatio={0.76}
                    styles={styles}
                >
                    <div className="sensor-health-text">SENSOR <br /> HEALTH</div>
                    <div className="sensor-health-value-text">{value}</div>
                    <div className="sensor-health-percent">%</div>
                </CircularProgressbarWithChildren>
                <Row>
                    <Col>
                        <Battery width={50} value={battery} />
                    </Col>
                    <Col>
                        <Signal width={40} value={signal} />
                    </Col>
                </Row>
                <GradientSVG startColor="#FF0000" midColor="#D27D02" endColor="#66A731" idCSS="progressGradient" rotation={90} />
            </div>
        );
    }
}

