import React from 'react';
import DataTable from 'react-data-table-component';
import { history } from '../../../services/history.service';
import { Row, Col, Spinner, ButtonGroup, Button } from 'reactstrap';
import { dataService } from '../../../services/data.service';
import { apiService } from '../../../services/api.service';
import { datetimeformatterService } from '../../../services/datetimeformatter.service';

import Notes from '../notes/Notes';

import { ReactComponent as  CloseIcon } from '../../../icons/Close.svg';
import { ReactComponent as  AlertIcon } from '../../../icons/Alerts.svg';
import { Mixpanel } from '../../../Mixpanel';
import './Alerts.scss';

export default class Alerts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentSelected: ["Unactioned", "In Progress"],
            filter: {
                unactioned: true,
                inProgress: true,
                actioned: false,
            },
        }
    }

    componentDidMount() {
        const { site, room } = this.props.location.state;

        if (room !== undefined) {
            //Coming from a room, need to get site ID first
            dataService.getRoomById(room)
                .then(room => {
                    dataService.getSiteById(room.site_Id)
                        .then(site => {
                            apiService.getData("/alert/recent?siteid=" + site.id)
                                .then(readings => {
                                    //Need to get all sensors by site id
                                    dataService.getSensorsBySiteId(site.id)
                                        .then(sensors => {
                                            sensors = sensors.flat();

                                            this.filterByStatus(readings, sensors);
                                        })
                                })
                        })
                })

        } else if (site !== undefined) {
            //Coming from a site, use the ID

            apiService.getData("/alert/recent?siteid=" + site)
                .then(readings => {
                    //Need to get all sensors by site id
                    dataService.getSensorsBySiteId(site)
                        .then(sensors => {
                            sensors = sensors.flat();

                            this.filterByStatus(readings, sensors);
                        })
                })
        }
    }

    getColumns() {
        const columns = [
            {
                name: 'Alert',
                selector: 'alertColour',
                sortable: false,
            },
            {
                name: 'Value',
                selector: 'value',
                sortable: true,
            },
            {
                name: 'Date/Time',
                selector: 'date',
                sortable: true,
            },
            {
                name: 'Type',
                selector: 'type',
                sortable: true,
            },
            {
                name: 'Status',
                selector: 'status',
                sortable: true,
            },
            {
                name: 'Job ID',
                selector: 'job',
                sortable: true,
            },
            {
                name: 'Engineer',
                selector: 'engineer',
                sortable: true,
            },
        ];
        return columns;
    }

    handleAlertClick(obj) {
        obj.alertColour = "";
        history.push({
            pathname: "/alertdetails",
            state: {
                site: this.props.location.state.site,
                room: this.props.location.state.room,
                alert: obj,
                update: true,
                siteAddress: this.props.location.state.siteAddress,
                siteName: this.props.location.state.siteName
            }
        })
    }

    handleToggle(name, obj) {
        const { filter, currentSelected, readings, sensors } = this.state;
        let newSelected = [];

        filter[`${name}`] = !this.state.filter[`${name}`];

        //If clicked object was selected then de-select it, but keep everything else
        if (currentSelected.includes(obj.target.id)) {
            newSelected = currentSelected.filter((e) => { return e !== obj.target.id })
        //Else transfer everything that was selected and add the new object
        } else {
            newSelected = currentSelected;
            newSelected.push(obj.target.id)
        }

        this.setState({ filter: filter, currentSelected: newSelected, isLoading: true })

        this.filterByStatus(readings, sensors, newSelected);
    }

    filterByStatus = (readings, sensors, selected) => {
        let data = [], sensorType = "";

        if (selected === undefined) {
            selected = this.state.currentSelected;

            readings.sort(function (a, b) {
                var dateA = new Date(a.alertTime), dateB = new Date(b.alertTime);
                return dateB - dateA;
            });
        }
        readings.forEach((reading, i) => {

            if (selected.includes(reading.alertStatus)) {

                reading.alertTime = new Date(reading.alertTime);

                sensors.forEach(sensor => {
                    if (reading.sensor_Id === sensor.sensorId) {
                        sensorType = sensor.type;
                    }
                });

                if (reading.alertLevel > 0) {
                    let className = "yellow";

                    if (reading.alertLevel > 1) {
                        className = "red";
                    }

                    data.push({
                        id: reading.id,
                        alert: reading.alertLevel,
                        alertColour: <div className={className}>      </div>,
                        status: reading.alertStatus,
                        value: reading.readingValue,
                        date: datetimeformatterService.formatDateTime(reading.alertTime),
                        type: sensorType.charAt(0) + sensorType.slice(1).toLowerCase(),
                        sensorId: reading.sensor_Id,
                        desc: reading.description,
                        roomId: reading.room_Id,
                        job: reading.jobId,
                        notes: reading.notes,
                        engineer: reading.engineer,
                        assetId: reading.asset_Id,
                    });
                }
            }
        });
        data = data.filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i);

        //This way of removing duplicated is really, really slow for some reason
        // data = data.filter((value, index, array) => array.findIndex(a => (JSON.stringify(a) === JSON.stringify(value))) === index);

        //removes duplicates from array
        const unique = [...new Set(data)]

        this.setState({ data: unique, readings: readings, sensors: sensors, isLoading: false });
    }

    render() {
        Mixpanel.track('Alerts');
        const columns = this.getColumns();
        return (
            this.state.isLoading ?
                <div className="loading-div">
                    <Spinner className="detail-loading" />
                </div>
                :
                <div className="alert-frame">
                    <Row noGutters className="detail-header text-center">
                        <Col lg={1} className="icon">
                            {<AlertIcon width={45} />}
                        </Col>
                        <Col lg={10} className="title">
                            Alerts
                        </Col>
                        <Col lg={1} className="close-icon">
                            <div className="close-icon-wrapper" onClick={() => history.goBack()}>
                                <CloseIcon width={40} height={40} />
                            </div>
                        </Col>
                    </Row>
                    {this.state.data.length > 0 ?
                        <div className="table-div">
                            <ButtonGroup className="toggle-group">
                                {this.state.filter.unactioned ? <Button className="unactioned" color="secondary" id="Unactioned" onClick={(obj) => this.handleToggle("unactioned", obj)}>Unactioned</Button> : <Button className="unactioned" outline color="secondary" id="Unactioned" onClick={(obj) => this.handleToggle("unactioned", obj)}>Unactioned</Button>}
                                {this.state.filter.inProgress ? <Button className="in-progress" color="secondary" id="In Progress" onClick={(obj) => this.handleToggle("inProgress", obj)}>In Progress</Button> : <Button className="in-progress" outline color="secondary" id="In Progress" onClick={(obj) => this.handleToggle("inProgress", obj)}>In Progress</Button>}
                                {this.state.filter.actioned ? <Button className="actioned" color="secondary" id="Actioned" onClick={(obj) => this.handleToggle("actioned", obj)}>Actioned</Button> : <Button className="actioned" outline color="secondary" id="Actioned" onClick={(obj) => this.handleToggle("actioned", obj)}>Actioned</Button>}
                            </ButtonGroup>
                            <DataTable
                                columns={columns}
                                data={this.state.data}
                                striped
                                dense
                                highlightOnHover
                                pointerOnHover
                                pagination
                                paginationPerPage={15}
                                paginationRowsPerPageOptions={[15, 30, 50, 100]}
                                fixedHeader
                                fixedHeaderScrollHeight="500px"
                                onRowClicked={(obj) => this.handleAlertClick(obj)}
                            />
                            <Notes type="Alerts" />
                        </div>
                        :
                        <div className="table-div-no-alerts">
                            <ButtonGroup className="toggle-group">
                                {this.state.filter.unactioned ? <Button className="unactioned" color="secondary" id="Unactioned" onClick={(obj) => this.handleToggle("unactioned", obj)}>Unactioned</Button> : <Button className="unactioned" outline color="secondary" id="Unactioned" onClick={(obj) => this.handleToggle("unactioned", obj)}>Unactioned</Button>}
                                {this.state.filter.inProgress ? <Button className="in-progress" color="secondary" id="In Progress" onClick={(obj) => this.handleToggle("inProgress", obj)}>In Progress</Button> : <Button className="in-progress" outline color="secondary" id="In Progress" onClick={(obj) => this.handleToggle("inProgress", obj)}>In Progress</Button>}
                                {this.state.filter.actioned ? <Button className="actioned" color="secondary" id="Actioned" onClick={(obj) => this.handleToggle("actioned", obj)}>Actioned</Button> : <Button className="actioned" outline color="secondary" id="Actioned" onClick={(obj) => this.handleToggle("actioned", obj)}>Actioned</Button>}
                            </ButtonGroup>
                            <DataTable title="No Data" />
                        </div>}
                </div>
        )
    }
}