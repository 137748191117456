import { datetimeformatterService } from "../../../services/datetimeformatter.service";

export function CalcAirQualityValues(readings, sensorId) {

    //Get a copy of the data with a new pointer
    let data = JSON.parse(JSON.stringify(readings));
    let average = [0, 0, 0], total = [0, 0, 0], lowValue = [0, 0, 0], lowValueDate = ["", "", ""], highValue = [100000, 100000, 100000], highValueDate = ["", "", ""];

    //For loop for every reading
    for (let i = 0; i < data.length; i++) {
        data[i].readingTime = new Date(data[i].readingTime);

        //Split into three values
        const splitValues = data[i].value.split('|');
        //Loop for each of those values
        splitValues.forEach((splits, j) => {
            let currentValue = parseFloat(splits)

            //Increment the total value
            total[j] += currentValue;

            //Get highest and lowest values
            if (currentValue >= lowValue[j]) {
                lowValue[j] = currentValue;
                lowValueDate[j] = data[i].readingTime;
            }
            if (currentValue < highValue[j]) {
                highValue[j] = currentValue;
                highValueDate[j] = data[i].readingTime;
            }
        });
    }

    let lowValues = [], highValues = [];
    //Loop over each of the PM values
    for (let i = 0; i < 3; i++) {
        //Calc the average of each
        average[i] = (total[i] / data.length);
        average[i] = average[i].toFixed(2) + " " + data[0].unit;

        //Format the highest and lowest values with their dates
        lowValue[i] = lowValue[i] + " " + data[0].unit;
        lowValueDate[i] = datetimeformatterService.formatDateTimeNoSeconds(lowValueDate[i]);
        lowValues[i] = { value: lowValue[i], date: lowValueDate[i] }

        highValue[i] = highValue[i] + " " + data[0].unit;
        highValueDate[i] = datetimeformatterService.formatDateTimeNoSeconds(highValueDate[i]);
        highValues[i] = { value: highValue[i], date: highValueDate[i] }
    }

    let airQualityArrays = [];
    airQualityArrays.push(average, lowValues, highValues);

    return airQualityArrays;
}
