import { dataService } from './data.service';

export const processService = {
    // ProcessData,
    ProcessSensor,
    // ProcessSingleSensor,
    ProcessReading,
    getSiteCardReadings,
    getRoomCardReadings,
}

//Define properties for each reading, from each type of sensor
const rules = [
    { uglyType: "percentage", prettyType: "HUMIDITY", unit: "%", monitored: false, pos: 1, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "temperaturedata", prettyType: "TEMPERATURE", unit: "°C", monitored: false, pos: 0, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "temperature", prettyType: "L8", unit: "°C", monitored: false, pos: null, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "luxdata", prettyType: "LIGHT", unit: "LUX", monitored: false, pos: 5, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "motion", prettyType: "PIR", unit: "", monitored: false, pos: 3, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "ppm", prettyType: "CO LEVEL", unit: "ppm", monitored: false, pos: 2, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "db", prettyType: "SOUND", unit: "dB", monitored: false, pos: 4, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "airquality", prettyType: "AIR QUALITY", unit: "µg/m³", monitored: false, pos: 6, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "centimeter", prettyType: "WATER LEVEL", unit: "cm", monitored: false, pos: null, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    { uglyType: "waterdetect", prettyType: "WATER ROPE", unit: "", monitored: false, pos: null, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null },
    // { uglyType: "twa", prettyType: "CO TWA LEVEL", unit: "", monitored: false, pos: null, value: "", alertLevel: null, colour: null, readingTime: null, sensorID: null }

]

// function ProcessData(readings) {
//     var processedData = [];
//     if (readings !== undefined) {

//         readings.forEach((reading) => {
//             if (reading.value !== null) {
//                 var types = [];
//                 if (reading.type !== "Centimeter|Centimeter" && reading.type !== "PPM|PPM|TemperatureData") {
//                     types = reading.type.split('|');
//                 } else if (reading.type === "Centimeter|Centimeter") {
//                     const splits = reading.type.split('|');
//                     types[0] = splits[0];
//                 } else if (reading.type === "PPM|PPM|TemperatureData") {
//                     //Take 0 and 2, merge readings for 0 and 1 by taking the split down there and doing value[0] = value[0] + "|" + value[1];
//                     const splits = reading.type.split('|');
//                     types[0] = splits[0];
//                     types[1] = splits[2];
//                 }

//                 var values = [];
//                 if (reading.type !== "airquality" && reading.type !== "Centimeter") {
//                     values = reading.value.split('|');
//                 } else if (reading.type === "Centimeter") {
//                     const splits = reading.value.split('|');
//                     values[0] = splits[0];
//                 } else if (reading.type === "airquality") {
//                     values[0] = reading.value;
//                 } else if (reading.type === "PPM|PPM|TemperatureData") {
//                     const splits = reading.value.split('|');
//                     values[0] = splits[0] + "|" + splits[1];
//                     values[1] = splits[2];
//                 }


//                 types = types.map(v => v.toLowerCase());

//                 //Check for to see if two PPMs come in and change one to TWA
//                 // if (types[0] === "ppm" && types[1] === "ppm") {
//                 //     types[1] = "twa";
//                 // }

//                 if (types[1] === "temperaturedata" && types[2] === "temperaturedata") {
//                     types[2] = "not used";
//                 }

//                 types.forEach((type, i) => {
//                     // Checks to see if there a a matching sensor in our list and map a reading to singleSplitReading
//                     let singleSplitReading = rules.find((sensor) => (sensor.uglyType === type));

//                     //Checks that the singleSplitReading has a type that's not undefined
//                     if (typeof singleSplitReading !== "undefined" && values[i] !== null) {

//                         //Attaches values to the reading, sets a colour depending on alert and pushes to an array
//                         singleSplitReading = { type: singleSplitReading.prettyType, value: values[i], unit: singleSplitReading.unit, alertLevel: reading.alertLevel, pos: singleSplitReading.pos, readingTime: new Date(reading.readingTime), sensorID: reading.sensorID || reading.originId };

//                         singleSplitReading = SetAlertColours(singleSplitReading);

//                         processedData.push(singleSplitReading);
//                     }
//                 })
//             }
//         })
//     }

//     return processedData;
// }

function ProcessSensor(sensors) {
    var processedData = [];

    sensors.forEach(sensor => {
        if (sensor !== undefined) {

            //Split on a pipe
            var types = sensor.type.split('|');

            //lowercase all the types
            types = types.map(t => t.toLowerCase());

            //Remove unwanted types
            if (types[0] === "ppm" && types[1] === "ppm") {
                types.pop();
                types.pop();
            }
            if (types[1] === "temperaturedata" && types[2] === "temperaturedata") {
                types[2] = "not used";
            }

            types.forEach((type, i) => {
                // Checks to see if there a a matching sensor in our list and map a reading to singleSplitReading
                let singleSplitReading = rules.find((rule) => (rule.uglyType === type));

                //Checks that the singleSplitReading has a type that's not undefined
                if (typeof singleSplitReading !== "undefined") {

                    //Changes type, sensorId and roomId to be the correct values
                    singleSplitReading = { name: sensor.name, type: singleSplitReading.prettyType, sensorId: sensor.originId, roomId: sensor.room_Id };

                    processedData.push(singleSplitReading);
                }
            })
        }
    });
    return processedData;
}

// function ProcessSingleSensor(sensor) {
//     if (sensor !== undefined) {

//         var types = sensor.type.split('|');
//         types = types.map(t => t.toLowerCase());

//         if (types[0] === "ppm" && types[1] === "ppm") {
//             types.pop();
//             types.pop();
//         }

//         if (types[1] === "temperaturedata" && types[2] === "temperaturedata") {
//             types[2] = "not used";
//         }

//         types.forEach((type, i) => {
//             // Checks to see if there a a matching sensor in our list and map a reading to singleSplitReading
//             let singleSplitReading = rules.find((rule) => (rule.uglyType === type));

//             //Checks that the singleSplitReading has a type that's not undefined
//             if (typeof singleSplitReading !== "undefined") {

//                 //Changes type, sensorId and roomId to be the correct values
//                 singleSplitReading = { type: singleSplitReading.prettyType, sensorId: sensor.originId, roomId: sensor.room_Id };

//                 return singleSplitReading;
//             }
//         })
//     }
// }

function ProcessReading(readings) {
    var processedData = [];

    if (readings !== undefined) {
        readings.forEach((reading) => {
            if (reading.value !== null) {
                var types = [], values = [];

                //Split the readings types out
                if (reading.type === "Centimeter|Centimeter") {
                    //Water level needs to split only the type, only has one reading
                    const splits = reading.type.split('|');
                    types[0] = splits[0];
                } else if (reading.type === "PPM|PPM|TemperatureData") {
                    //Need to split this into two, not three
                    const splits = reading.type.split('|');
                    types[0] = splits[0];
                } else {
                    types = reading.type.split('|');
                }

                //Split out the values
                if (reading.type === "Centimeter") {
                    const splits = reading.value.split('|');
                    values[0] = splits[0];
                } else if (reading.type === "airquality") {
                    values[0] = reading.value;
                } else if (reading.type === "PPM|PPM|TemperatureData") {
                    const splits = reading.value.split('|');
                    values[0] = splits[0] + "|" + splits[1];
                } else {
                    values = reading.value.split('|');
                }

                //Lowercase all the types
                types = types.map(v => v.toLowerCase());

                // //Check for to see if two PPMs come in and change one to TWA
                // if (types[0] === "ppm" && types[1] === "ppm") {
                //     types[1] = "twa";
                // }

                //Removes one of the types of temperaturedata
                if (types[1] === "temperaturedata" && types[2] === "temperaturedata") {
                    types[2] = "not used";
                }

                types.forEach((type, i) => {
                    // Checks to see if there a a matching sensor in our list and map a reading to singleSplitReading
                    let singleSplitReading = rules.find((sensor) => (sensor.uglyType === type));

                    //Checks that the singleSplitReading has a type that's not undefined
                    if (typeof singleSplitReading !== "undefined" && values[i] !== null) {

                        //Attaches values to the reading, sets a colour depending on alert and pushes to an array
                        singleSplitReading = { type: singleSplitReading.prettyType, value: values[i], unit: singleSplitReading.unit, alertLevel: reading.alertLevel, pos: singleSplitReading.pos, readingTime: new Date(reading.readingTime), sensorId: reading.sensorID, batteryLevel: reading.batteryLevel, signalStrength: reading.signalStrength };

                        singleSplitReading = SetAlertColours(singleSplitReading);

                        processedData.push(singleSplitReading);
                    }
                })
            }
        })
    }

    return processedData;

}

//Simple function to set the colour depending on the alert level
function SetAlertColours(reading) {
    switch (reading.alertLevel) {
        case (0):
            reading.colour = "green";
            break;
        case (1):
            reading.colour = "amber";
            break;
        case (2 || 3):
            reading.colour = "red";
            break;
        default:
            reading.colour = undefined;
            break;
    }
    return reading;
}

function getSiteCardReadings(site) {
    return new Promise(resolve => {

        //Get all the sensors for that site
        dataService.getSensorsBySiteId(site.id).then(sensors => {
            const siteSensors = sensors;
            //Get all the readings for that site
            dataService.getReadingsBySiteId(site.id).then(readings => {
                const siteReadings = readings;

                //Run through all the readings for that site to get the worst values
                return new Promise(resolve => {
                    resolve(filterWorst(siteSensors, siteReadings, site.id));
                }).then(worstReadings => {
                    resolve(worstReadings);
                })
            })
                .catch((error) => {
                    console.log("Catching error");
                    console.log(error);
                })
        })
            .catch((error) => {
                console.log("Catching error");
                console.log(error);
            })

    }).then(worstReadings => {
        return worstReadings;
    })
}

function getRoomCardReadings(room) {
    return new Promise(resolve => {

        //Get all the sensors for that room
        dataService.getSensorsByRoomId(room.id).then(sensors => {
            const roomSensors = sensors;

            //Get all the readings for that room
            dataService.getReadingsByRoomId(room.id).then(readings => {
                const roomReadings = readings;

                //Run through all the readings for that room and return the worst values
                return new Promise(resolve => {
                    resolve(filterWorst(roomSensors, roomReadings, room.id));
                }).then(worstReadings => {
                    resolve(worstReadings);
                })
            })
                .catch((error) => {
                    console.log("Catching error");
                    console.log(error);
                })
        })
            .catch((error) => {
                console.log("Catching error");
                console.log(error);
            })

    }).then(worstReadings => {
        return worstReadings;
    })
}

function filterWorst(sensors, readings, siteId) {
    //Flatten the arrays, the double flat is intentional
    sensors = sensors.flat();
    readings = readings.flat();
    readings = readings.flat();

    //This is all the sensors for that site/room, and all the readings from all those sensors

    //Date order and reverse array to get latest reading first
    readings.sort(function (a, b) {
        var dateA = new Date(a.readingTime), dateB = new Date(b.readingTime);
        return dateA - dateB;
    });
    readings.reverse();


    // check type and sensor id matches anything from sensors and the index hasn't already been used
    let seen = [], oneOfEach = [];
    for (let i = 0; i < readings.length; i++) {
        for (let j = 0; j < sensors.length; j++) {
            if (readings[i].type === sensors[j].type && readings[i].sensorId === sensors[j].sensorId && seen.includes(j) === false) {
                seen.push(j);
                oneOfEach.push(readings[i]);
            }
        }
    }

    //Split the readings out into the ones we need and get the worst reading of each
    let worstReadings = [], currentTemp, currentHumidity, currentCo, currentPir, currentSound, currentLight, currentAir, currentL8;
    oneOfEach.forEach(reading => {
        reading.id = siteId;

        //Switch case on the type and then get the worst for that sensor type
        switch (reading.type) {
            case "TEMPERATURE":
                if (currentTemp === undefined) {
                    currentTemp = reading;
                } else if (reading.alertLevel >= currentTemp.alertLevel) {
                    if (reading.alertLevel === currentTemp.alertLevel && reading.value > currentTemp.value) {
                        currentTemp = reading;
                    } else if (reading.alertLevel > currentTemp.alertLevel) {
                        currentTemp = reading;
                    }
                }
                break;
            case "HUMIDITY":
                if (currentHumidity === undefined) {
                    currentHumidity = reading;
                } else if (reading.alertLevel >= currentHumidity.alertLevel) {
                    if (reading.alertLevel === currentHumidity.alertLevel && reading.value > currentHumidity.value) {
                        currentHumidity = reading;
                    } else if (reading.alertLevel > currentHumidity.alertLevel) {
                        currentHumidity = reading;
                    }
                }
                break;
            case "CO LEVEL":
                const coSplit = reading.value.split("|");
                if (currentCo === undefined) {
                    reading.value = coSplit[0];
                    currentCo = reading;
                } else if (reading.alertLevel >= currentCo.alertLevel) {
                    if (reading.alertLevel === currentCo.alertLevel && reading.value > currentCo.value) {
                        reading.value = coSplit[0];
                        currentCo = reading;
                    } else if (reading.alertLevel > currentCo.alertLevel) {
                        reading.value = coSplit[0];
                        currentCo = reading;
                    }
                }
                break;
            case "PIR":
                if (currentPir === undefined) {
                    currentPir = reading;
                } else if (reading.alertLevel >= currentPir.alertLevel) {
                    if (reading.alertLevel === currentPir.alertLevel && reading.value > currentPir.value) {
                        currentPir = reading;
                    } else if (reading.alertLevel > currentPir.alertLevel) {
                        currentPir = reading;
                    }
                }
                break;
            case "SOUND":
                if (currentSound === undefined) {
                    currentSound = reading;
                } else if (reading.alertLevel >= currentSound.alertLevel) {
                    if (reading.alertLevel === currentSound.alertLevel && reading.value > currentSound.value) {
                        currentSound = reading;
                    } else if (reading.alertLevel > currentSound.alertLevel) {
                        currentSound = reading;
                    }
                }
                break;
            case "LIGHT":
                if (currentLight === undefined) {
                    currentLight = reading;
                } else if (reading.alertLevel >= currentLight.alertLevel) {
                    if (reading.alertLevel === currentLight.alertLevel && reading.value > currentLight.value) {
                        currentLight = reading;
                    } else if (reading.alertLevel > currentLight.alertLevel) {
                        currentLight = reading;
                    }
                }
                break;
            case "AIR QUALITY":
                const airSplit = reading.value.split("|");
                if (currentAir === undefined) {
                    currentAir = reading;
                } else if (reading.alertLevel >= currentAir.alertLevel) {
                    currentAir = reading;
                } else if (airSplit[0] > currentAir.value) {
                    currentAir = reading;
                } else if (airSplit[1] > currentAir.value) {
                    currentAir = reading;
                } else if (airSplit[2] > currentAir.value) {
                    currentAir = reading;
                }
                break;
            case "L8":
                if (currentL8 === undefined) {
                    currentL8 = reading;
                } else if (reading.alertLevel >= currentL8.alertLevel) {
                    if (reading.alertLevel === currentL8.alertLevel && reading.value > currentL8.value) {
                        currentL8 = reading;
                    } else if (reading.alertLevel > currentL8.alertLevel) {
                        currentL8 = reading;
                    }
                }
                break;
            default:
                break;
        }

    });

    //Add all of the worst readings, and filter out any undefined readings
    worstReadings.push(currentTemp, currentHumidity, currentCo, currentPir, currentSound, currentLight, currentAir, currentL8);
    worstReadings = worstReadings.filter(item => item !== undefined);

    return worstReadings;
}