import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import { ReactComponent as MoveHandle } from '../../../icons/MoveHandle.svg';



export default class OtherTile extends React.Component {
    constructor() {
        super()
        
        this.state = {
            data: "",
            unit: "",
            latest: "",
            isLoading: true,
        }
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        const {name, icon, colour} = this.props;
        
        let headerStyles = {
            backgroundColor: colour
        }

        //Remove whitspace
        let path = this.props.name.toLowerCase().replace(/\s/g, "");

        return (
            <div className="mini-tile">
                <Col lg={12} className="header-wrapper" style={headerStyles}>
                    <Row className="text-center header">
                        {this.props.modalRef === undefined ?
                        //Link to new page
                        <Link className="mini-btn" type="button" to={{
                            pathname: '/' + path, state: {
                                name: this.props.name,
                                room: this.props.room,
                                site: this.props.site,
                                siteAddress: this.props.siteAddress,
                                siteName: this.props.siteName
                            }
                        }}>
                        <Col lg={2} className="icon" >
                            {icon}
                        </Col>
                        <Col lg={8} className="title">
                            {name}
                        </Col>
                        </Link>
                        :
                        //Expand a relevant modal
                        <div className="mini-btn" type="button" onClick={() => this.props.onClick(this.props.modalRef)}>
                        <Col lg={2} className="icon" >
                            {icon}
                        </Col>
                        <Col lg={8} className="title">
                            {name}
                        </Col>
                        </div>
                        }
                        <Col lg={2} className="drag-handle">
                            <MoveHandle width={15} />
                        </Col>
                    </Row>
                </Col>
            </div>
        )
    }
}


