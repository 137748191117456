import React from 'react';

import { datetimeformatterService } from '../../../services/datetimeformatter.service';

export default class DetailLuxLongestSegment extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
            longestSegment: "",
        }
    }

    componentDidMount() {
        this.calculations(this.props.readings)
    }

    calculations = (readingss) => {
        let readings = readingss.slice();

        //If i > 5 and i+1 > 5 then keep going. Measure end when current < 5 ()
        let start = null, end = null, endChanged = false, segments = [];
        for (let i = 0; i < readings.length; i++) {
            const currentValue = parseInt(readings[i].value);

            //Beginning start value
            if (currentValue > 5 && start === null) {
                start = i;
            } else if (readings[i] !== undefined && readings[i - 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i - 1].value) < 5) {
                    start = i;
                }
            }

            //This is the end of a section of lights on
            if (readings[i] !== undefined && readings[i + 1] !== undefined) {
                if (currentValue > 5 && parseInt(readings[i + 1].value) <= 5) {
                    end = i;
                    endChanged = true;
                }
            }

            //Once it reaches the end of the array, check if it needs to end
            if (readings[i + 1] === undefined && currentValue > 5) {
                end = i;
                endChanged = true;
            } else if (readings[i + 1] === undefined && parseInt(readings[i - 1].value) > 5) {
                end = i - 1;
                endChanged = true;
            }

            //Get the dates from milliseconds from start and end, end-start and add to running total 
            if (endChanged === true && end > start) {
                const startTime = readings[start].readingTime;
                const endTime = readings[end].readingTime;
                const segment = endTime - startTime;

                segments.push({ segment: segment, startTime: readings[start].readingTime, endTime: readings[end].readingTime });

                endChanged = false;
            }
        }
        let longest = 0, segmentStart, segmentEnd;

        //Calculate segment lengths and average
        segments.forEach(segment => {
            if (segment.segment > longest) {
                longest = segment.segment;
                segmentStart = segment.startTime;
                segmentEnd = segment.endTime;
            }
        });

        let longestSeg, startDate, endDate;
        if (segmentStart !== undefined && segmentEnd !== undefined) {
            //convert milliseconds back to useable time
            longestSeg = datetimeformatterService.convertMilliseconds(longest);
            startDate = datetimeformatterService.formatDateTimeNoSeconds(segmentStart);
            endDate = datetimeformatterService.formatDateTimeNoSeconds(segmentEnd);

            //Add x days worth of hours onto hours
            if (longestSeg.day !== undefined) {
                longestSeg.hour = longestSeg.hour + (longestSeg.day * 60);
            }
        } else {
            longestSeg = {
                hour: 0,
                minute: 0,
            }
        }

        this.setState({ longestSegment: longestSeg, start: startDate, end: endDate, isLoading: false });
    }

    render() {
        return (
            this.state.isLoading ?
                ""
                :
                <div className="lux-longest-segment">
                    <h3>LONGEST DURATION</h3>
                    <h1> </h1>
                    <h4><strong>{this.state.longestSegment.hour}</strong>hrs <strong>{this.state.longestSegment.minute}</strong>mins</h4>
                    { this.state.start !== undefined ? 
                    <h5>{this.state.start + " - " + this.state.end}</h5>
                    :
                    "" }
                </div>

        )
    }
}