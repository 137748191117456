import React from 'react';
import { CSVLink } from 'react-csv';

import { ReactComponent as Download } from '../../icons/Download.svg';

export default class DetailDownloadReadings extends React.Component {

    //Simple class to add a download link to the detail page
    render() {

        return (
            <div className="readings-download">
                Download Readings:
                <CSVLink data={this.props.data} filename={`${this.props.type} Readings.csv`}>
                    <Download width={22} />
                </CSVLink>
            </div>
        )
    }
}