import { apiService } from "./api.service";
import { cacheService } from "./cache.service";
import { eventsService } from "./events.service";
import { tileService } from "./tile.service";

export const settingsService = {
    updateName,
    filterContacts
}

function updateName(sensorDisplayInfo, sensorName) {
    return new Promise(resolve => {
        let toDatabase = {
            OriginId: sensorDisplayInfo.sensorId,
            Name: sensorName,
        }

        apiService.patchData("/sensor/edit", toDatabase).then(() => {
            const message = `${sensorDisplayInfo.name} name changed to ${sensorName}`;
            eventsService.newEvent(message, "Sensor", "Update").then((event) => {
                cacheService.updateSensorName(sensorDisplayInfo.sensorId, sensorName).then((update) => {
                    tileService.createTileData().then((tiles) => {
                        return resolve(true);
                    })
                })
            })
        })
    })
}

function filterContacts(contacts) {
    let contact = { all: [], phone: [], text: [], email: [] }

    //Push contacts into sections for easier output
    contacts.forEach(singleContact => {
        contact.all.push(singleContact);

        if (singleContact.phone !== "") {
            contact.phone.push(singleContact);
        }

        if (singleContact.text !== "") {
            contact.text.push(singleContact);
        }

        if (singleContact.email !== "") {
            contact.email.push(singleContact);
        }
    });

    let contactsAndDropdowns = []
    //check length of contacts array
    if (contact.all.length > 0) {
        contactsAndDropdowns = {
            contact: contact,
            dropdown: {
                phone: contact.phone[0].id,
                text: contact.text[0].id,
                email: contact.email[0].id,
            }
        }

        return contactsAndDropdowns;
    } else {
        contact = {
            all: [{ firstName: "No available contacts", lastName: "", id: -1 }],
            phone: [{ firstName: "No available contacts", lastName: "", id: -1 }],
            text: [{ firstName: "No available contacts", lastName: "", id: -1 }],
            email: [{ firstName: "No available contacts", lastName: "", id: -1 }]
        }
        contactsAndDropdowns = {
            contact: contact,
            dropdown: {
                phone: "No available contacts",
                text: "No available contacts",
                email: "No available contacts",
            }
        }

        return contactsAndDropdowns;
    }
}