import React from 'react';
import { Col, Row, Spinner, Progress } from 'reactstrap';
import { apiService } from '../../../services/api.service';

import  { ReactComponent as TopDefectsIcon } from '../../../icons/TopDefects.svg';
import { ReactComponent as MoveHandle } from '../../../icons/MoveHandle.svg';
import { dataService } from '../../../services/data.service';

export default class TopDefectsTile extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        const { site } = this.props;

        apiService.getData("/workhistory/recent")
            .then(work => {
                work.reverse();

                //Remove anything not for that site
                work = work.filter(job => job.siteId === site)
                if (work.length > 0) {
                    this.getCount(work);
                } else {
                    this.noWork();
                }
            })
    }

    getCount(work) {
        let counts = {};

        //Tally up occurrences per sensor ID
        for (let i = 0; i < work.length; i++) {
            const id = work[i].sensorId;
            counts[id] = counts[id] ? counts[id] + 1 : 1;
        }

        //Get all the sensor Ids
        const sensorIds = Object.keys(counts)
        let data = [], max = 0;

        //Get all sensor objects
        dataService.getAllSensors().then(allSensors => {
            //filter them down to only the sensors from above
            let filtered = allSensors.filter(sensor => sensorIds.includes(sensor.sensorId.toString()));

            sensorIds.forEach(sensor => {
                let sensorName;

                //Get the largest amount of occurrences
                if (counts[sensor] > max) {
                    max = counts[sensor];
                }

                //Get sensor object from ID
                const results = filtered.filter(singleSensor => singleSensor.sensorId.toString() === sensor);

                if (results.length > 0) {
                    sensorName = results[0].name;
                } else {
                    sensorName = sensor;
                }

                const temp = {
                    sensor: sensorName,
                    count: counts[sensor]
                }
                data.push(temp);
            });

            //Sort so highest count at the top
            data.sort(function (a, b) {
                return b.count - a.count;
            });

            //Create graph
            this.createGraph(data, max);
        })
    }

    createGraph(data, max) {
        const progressMax = max * 1.2;
        let graph = [], length = 4;

        if (data.length < length) {
            length = data.length;
        }

        for (let i = 0; i < length; i++) {
            let color = ""

            //Changes colour per row
            switch (i) {
                case 1:
                    color = "success"
                    break;
                case 2:
                    color = "info"
                    break;
                case 3:
                    color = "warning"
                    break;
                default:
                    color = ""
                    break;
            }

            graph.push(
                <React.Fragment key={i}>
                    <Row className="top-defect-progress">
                        <Col xs={4} style={{ padding: 0 }} className="sensor-name">
                            {data[i].sensor}
                        </Col>
                        <Col xs={5} style={{ padding: 0 }}>
                            <Progress color={color} value={data[i].count} max={progressMax} />
                        </Col>
                        <Col xs={2} style={{ padding: 0 }}>
                            {
                                data[i].count > 1 ?
                                    <React.Fragment><strong>{data[i].count}</strong> EVENTS</React.Fragment>
                                    :
                                    <React.Fragment><strong>{data[i].count}</strong> EVENT</React.Fragment>
                            }
                        </Col>
                    </Row>
                    {
                        i !== 3 ?
                            <hr style={{ borderTop: "1px solid rgba(0, 0, 0, 1)", margin: "0 auto", width: "95%" }} />
                            :
                            ""
                    }
                </React.Fragment>
            )
        }

        this.setState({ data: graph, isLoading: false })
    }

    noWork() {
        const noData =
            <div className="top-defect-no-data">
                NO <br/> WORK HISTORY
            </div>

            this.setState({data: noData, isLoading: false})
    }

    render() {
        let headerStyles = { backgroundColor: "#21316F" }
        let bodyStyles = { backgroundColor: "#1B254D" };

        return (
            <div className="graph-tile">
                <Col lg={12} className="header-wrapper" style={headerStyles}>
                    <Row className="text-center header">
                        <Col lg={2} className="top-defects-icon">
                            <TopDefectsIcon width={35} />
                        </Col>
                        <Col lg={8} className="title">
                            TOP DEFECTS
                        </Col>
                        <Col lg={2} className="drag-handle">
                            <MoveHandle width={15} />
                        </Col>
                    </Row>
                </Col>
                {
                    this.state.isLoading ?
                        <Col lg={12} className="body" style={bodyStyles}>
                            <div className="tile-loading">
                                <Spinner className="tile-spinner" />
                            </div>
                        </Col>
                        :
                        <Col lg={12} className="body" style={bodyStyles}>
                            <Row>
                                <Col style={{ marginLeft: "15px" }}>
                                    FROM THE <strong> PAST 7 DAYS</strong>
                                </Col>
                            </Row>
                            <Row style={{height: "88%"}}>
                                <Col>
                                    {this.state.data}
                                </Col>
                            </Row>
                        </Col>
                }
            </div>
        )
    }
}