import { authService } from './auth.service';
import { handleResponse } from './response.service';
import { apiUrl } from './config';

export const apiService = {
    getData,
    postData,
    patchData,
    deleteData
}

//A class to simplify the use of GET, POST, PATCH and DELETE

async function getData (path) {

    const requestOptions = { 
        method: 'GET', 
        headers: { 'Content-Type': 'application/json', ...await authService.authHeader() },
    };

    return new Promise(async function (resolve, reject) {
        await fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            return resolve(data);
        })
        .catch(error => {
            return reject(error);
        })
    });
}

async function postData(path, bodyData) {

    const requestOptions = { 
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', ...await authService.authHeader() },
        body: JSON.stringify(bodyData) 
    };

    // console.log(requestOptions.body);

    return new Promise(function (resolve, reject) {
        fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            resolve(data);
        })
        .catch(error => {
            reject(error);
        })
    });
}

async function patchData(path, bodyData) {

    const requestOptions = { 
        method: 'PATCH', 
        headers: { 'Content-Type': 'application/json', ...await authService.authHeader() },
        body: JSON.stringify(bodyData) 
    };

    // console.log(requestOptions.body);

    return new Promise(function (resolve, reject) {
        fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            resolve(data);
        })
        .catch(error => {
            reject(error);
        })
    });
}

async function deleteData (path) {

    const requestOptions = { 
        method: 'DELETE', 
        headers: { 'Content-Type': 'application/json', ...await authService.authHeader() },
    };

    return new Promise(async function (resolve, reject) {
        await fetch(apiUrl + path , requestOptions)
        .then(handleResponse)
        .then(data => { 
            return resolve(data);
        })
        .catch(error => {
            return reject(error);
        })
    });
}