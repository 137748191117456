import React, { Component } from "react";
import {
    Row,
    Col,
    Container,
    Navbar,
    Button,
    NavItem,
    NavLink,
    Nav,
    Spinner,
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { history } from "../services/history.service";
import { dataService } from "../services/data.service";
import { authService } from "../services/auth.service";
import { versionNumber } from "../services/config";
import EmergencyModal from "./modals/EmergencyModal";
import CallBoxModal from "./modals/CallBox";
import LiveFeedModal from "./modals/LiveFeed";

import SPSLogo from "../images/MetlLogoBottom.png";
import CustLogo from "../images/MetlLogo.png";
import { ReactComponent as DashIconOff } from "../icons/OffState-Dashboard.svg";
import { ReactComponent as DashIconOn } from "../icons/Rollover-Dashboard.svg";
import { ReactComponent as SearchIconOff } from "../icons/OffState-Search.svg";
import { ReactComponent as SettingsIcon } from "../icons/Settings.svg";
import { ReactComponent as SearchIconOn } from "../icons/Rollover-Search.svg";
import { ReactComponent as UserIconOff } from "../icons/OffState-User.svg";
import { ReactComponent as UserIconOn } from "../icons/Rollover-User.svg";
import { ReactComponent as BackArrow } from "../icons/BackArrow.svg";
import { ReactComponent as MapView } from "../icons/MapView.svg";
import { ReactComponent as CardView } from "../icons/CardView.svg";
import { ReactComponent as ListView } from "../icons/ListView.svg";
import { ReactComponent as GreenSites } from "../icons/Green-OffState-RecentReading.svg";
import { ReactComponent as GreenSitesRollover } from "../icons/Green-Rollover-RecentReading.svg";
import { ReactComponent as AmberSites } from "../icons/Amber-OffState-RecentReading.svg";
import { ReactComponent as AmberSitesRollover } from "../icons/Amber-Rollover-RecentReading.svg";
import { ReactComponent as RedSites } from "../icons/Red-OffState-RecentReading.svg";
import { ReactComponent as RedSitesRollover } from "../icons/Red-Rollover-RecentReading.svg";
import { ReactComponent as GreySites } from "../icons/Grey-OffState-RecentReading.svg";
import { ReactComponent as GreySitesRollover } from "../icons/Grey-Rollover-RecentReading.svg";
import { ReactComponent as CornerTriangle } from "../icons/CornerTriangle.svg";

import logo from "../images/MetlLogoBottom.png";

import "./styles/NavMenu.scss";

class NavMenu extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: true,
            showBack: false,
            section: "",
            locationLabel: "",
            update: false,
            collapsed: true,
            logo: <img src={logo} alt="Logo" />,
            alertGreen: "",
            alertAmber: "",
            alertRed: "",
            alertAll: "",
            redirectSites: "",
            redirect: false,
            greySelected: "underline-selected",
            greenSelected: "",
            amberSelected: "",
            redSelected: "",
            isAdmin: false
        };
    }

    componentDidMount = () => {
        var mutationObserver = new MutationObserver(() => {
            this.updatePageTitle();
            this.checkAdmin();
        });

        mutationObserver.observe(document.documentElement, {
            attributes: true,
            characterData: true,
            childList: true,
            subtree: true,
            attributeOldValue: true,
            characterDataOldValue: true,
        });

        this.fetchAllFlags();
    };

    fetchAllFlags = () => {
        //Collect all sensors and reading from cache and pass them through to calculate flags
        let allSensors, allReadings;
        dataService
            .getAllSensors()
            .then((sensors) => {
                allSensors = sensors;
                dataService.getAllReadings().then((readings) => {
                    allReadings = readings;
                    this.calculateFlags(allSensors, allReadings);
                });
            })
            .catch((error) => {
                console.log("Catching error");
            });
    };

    checkAdmin() {
        const user = JSON.parse(localStorage.getItem("currentUser"))

        if (user?.role === "Admin" || user?.role === "SPS Admin") {
            //this.setState({ isAdmin: true });

        }
    }

    calculateFlags = (sensors, readings) => {
        //Date order and reverse array to get latest reading first
        readings.sort(function (a, b) {
            var dateA = new Date(a.readingTime),
                dateB = new Date(b.readingTime);
            return dateA - dateB;
        });
        readings.reverse();

        //check type and sensor id matches anything from sensors and the index hasn't already been used
        let seen = [],
            oneOfEach = [];
        for (let i = 0; i < readings.length; i++) {
            for (let j = 0; j < sensors.length; j++) {
                if (
                    readings[i].type === sensors[j].type &&
                    readings[i].sensorId === sensors[j].sensorId &&
                    seen.includes(j) === false
                ) {
                    seen.push(j);
                    oneOfEach.push(readings[i]);
                }
            }
        }

        //Get rid of any duplicates
        oneOfEach = oneOfEach.filter(this.onlyUnique);

        //Tally up the number of sensors with various alert levels
        let green = 0,
            amber = 0,
            red = 0;
        oneOfEach.forEach((sensor) => {
            if (sensor.alertLevel === 0) {
                green++;
            } else if (sensor.alertLevel === 1) {
                amber++;
            } else if (sensor.alertLevel > 1) {
                red++;
            }
        });

        this.setState({
            alertGreen: green,
            alertAmber: amber,
            alertRed: red,
            isLoading: false,
        });
    };

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    handleRefresh() {
        authService.refreshToken();
    }

    handleBack() {
        history.goBack();
    }

    toggleModal = (modalName, type) => {
        this.refs[modalName].toggleModal(type);
    };

    updatePageTitle = () => {
        var fullPath = window.location.pathname;
        fullPath.indexOf(1);
        var firstElem = fullPath.split("/")[1];
        var firstElemLower = firstElem.toLowerCase();
        var locationLabel;
        if (this.props.location.state !== undefined) {
            const routerProps = this.props.location.state;
            if (routerProps.siteAddress !== undefined) {
                locationLabel = routerProps.siteName + ", " + routerProps.siteAddress;
                if (routerProps.roomName !== undefined) {
                    locationLabel =
                        routerProps.roomName +
                        ", " +
                        routerProps.siteName +
                        ", " +
                        routerProps.siteAddress;
                }
            }
        } else {
            locationLabel = this.props.account;
        }
        switch (firstElemLower) {
            case "sites":
                this.setState({
                    locationLabel: locationLabel,
                    section: "SITES",
                    showBack: false,
                });
                break;
            case "rooms":
                this.setState({
                    locationLabel: locationLabel,
                    section: "ROOMS",
                    showBack: true,
                });
                break;
            case "":
                this.setState({
                    locationLabel: locationLabel,
                    section: "SITES",
                    showBack: false,
                });
                break;
            case "map":
                this.setState({
                    locationLabel: locationLabel,
                    section: "MAP",
                    showBack: false,
                });
                break;
            case "dashboard":
                this.setState({
                    locationLabel: locationLabel,
                    section: "DASHBOARD",
                    showBack: true,
                });
                break;
            case "detail":
                this.setState({
                    locationLabel: locationLabel,
                    section: "DETAIL",
                    showBack: true,
                });
                break;
            case "contacts":
                this.setState({
                    locationLabel: locationLabel,
                    section: "CONTACTS",
                    showBack: true,
                });
                break;
            case "contactdetails":
                this.setState({
                    locationLabel: locationLabel,
                    section: "CONTACTS",
                    showBack: true,
                });
                break;
            case "assets":
                this.setState({
                    locationLabel: locationLabel,
                    section: "ASSETS",
                    showBack: true,
                });
                break;
            case "assetdetails":
                this.setState({
                    locationLabel: locationLabel,
                    section: "ASSETS",
                    showBack: true,
                });
                break;
            case "alerts":
                this.setState({
                    locationLabel: locationLabel,
                    section: "ALERTS",
                    showBack: true,
                });
                break;
            case "alertdetails":
                this.setState({
                    locationLabel: locationLabel,
                    section: "ALERTS",
                    showBack: true,
                });
                break;
            case "defects":
                this.setState({
                    locationLabel: locationLabel,
                    section: "DEFECTS",
                    showBack: true,
                });
                break;
            case "defectdetails":
                this.setState({
                    locationLabel: locationLabel,
                    section: "DEFECTS",
                    showBack: true,
                });
                break;
            case "recentevents":
                this.setState({
                    locationLabel: locationLabel,
                    section: "RECENT EVENTS",
                    showBack: true,
                });
                break;
            case "workhistory":
                this.setState({
                    locationLabel: locationLabel,
                    section: "WORK HISTORY",
                    showBack: true,
                });
                break;
            default:
                this.setState({
                    locationLabel: locationLabel,
                    section: "",
                    showBack: true,
                });
                break;
        }
    };

    handleFilter(num) {
        this.props.filter(num);
        let { greySelected, greenSelected, amberSelected, redSelected } =
            this.state;

        //Clear all of them
        greySelected = "";
        greenSelected = "";
        amberSelected = "";
        redSelected = "";

        //Change the css class based on which button is clicked
        switch (num) {
            case undefined:
                greySelected = "underline-selected";
                break;
            case 0:
                greenSelected = "underline-selected";
                break;
            case 1:
                amberSelected = "underline-selected";
                break;
            case 2:
                redSelected = "underline-selected";
                break;
            default:
                greySelected = "underline-selected";
                break;
        }

        this.setState({
            greySelected: greySelected,
            greenSelected: greenSelected,
            amberSelected: amberSelected,
            redSelected: redSelected,
        });
    }

    render() {
        const { isAdmin } = this.state;
        return (
            <Container className="reset-mp" fluid>
                {/* {this.state.redirect ?
                    this.state.redirectSites
                    :
                    ""
                    } */}

                {/* Side Nav Bar */}
                <Nav id="vert-nav" className="vert-nav sticky" vertical="true">
                    <div className="nav-icons">
                        <NavItem>
                            <NavLink href="/">
                                {this.state.isLoading ? (
                                    <Spinner className="nav-loading" />
                                ) : (
                                    <div>
                                        <span className="health-widget-indicator critical badge badge-pill badge-danger">
                                            {this.state.alertRed}
                                        </span>
                                        <span className="health-widget-indicator warning badge badge-pill badge-warning">
                                            {this.state.alertAmber}
                                        </span>
                                        <span className="health-widget-indicator normal badge badge-pill badge-success">
                                            {this.state.alertGreen}
                                        </span>
                                        <DashIconOff width={63} className="dash-off" />
                                        <DashIconOn width={63} className="dash-on" />
                                    </div>
                                )}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">
                                <div>
                                    <SearchIconOff width={63} className="search-off" />
                                    <SearchIconOn width={63} className="search-on" />
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/contacts">
                                <div>
                                    <UserIconOff width={63} className="user-off" />
                                    <UserIconOn width={63} className="user-on" />
                                </div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/admin">

                                {isAdmin && (<div>
                                    <SettingsIcon width={63} className="setting-off" />

                                </div>
                                )}
                            </NavLink>
                        </NavItem>

                    </div>
                    <div className="customer-logo">
                        <img src={CustLogo} alt="Logo" width="80px" />
                    </div>
                    <div className="logout">
                        {/* {user.role === "Admin" || user.role === "SPS Admin" ? 
                        <Button className="btn" href="/admin">Admin</Button>
                        :
                        ""
                        } */}
                        <Button className="btn" onClick={() => this.props.logout()}>
                            Logout
                        </Button>
                    </div>
                    <div className="logo">
                        <small>PRODUCED BY</small>
                        <img src={logo} width={80} className="" />
                    </div>
                </Nav>

                {/* Back icon */}
                {this.state.showBack ? (
                    <div
                        id="back-button"
                        className="back-button-wrapper"
                        onClick={() => this.handleBack()}
                    >
                        <div className="back-button">
                            <BackArrow width={22} />
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {/* Top Nav Bar */}
                <Navbar id="hori-nav" className="hori-nav sticky">
                    <Row noGutters className="views-filters ">
                        <Col xs={6} lg={6} className="hori-nav-left">
                            <h3>
                                SPS DASHBOARD{" "}
                                <strong id="page-title">{this.state.section}</strong>
                            </h3>
                            <p>
                                Dashboard for: <strong>{this.state.locationLabel}</strong>
                            </p>
                        </Col>
                        <Col xs={6} lg={6} className="hori-nav-right">
                            {this.props.location.pathname === "/" ||
                                this.props.location.pathname.split("/")[1] === "rooms" ||
                                this.props.location.pathname.split("/")[1] === "map" ? (
                                <React.Fragment>
                                    {/* Types of views */}
                                    <span> VIEW </span>&nbsp;
                                    <Link
                                        className=""
                                        to={{
                                            pathname: "/map",
                                        }}
                                    >
                                        <MapView width={35} className="view-icon" />
                                    </Link>{" "}
                                    &nbsp;
                                    <Link
                                        className=""
                                        to={{
                                            pathname: "/",
                                        }}
                                    >
                                        <CardView width={35} className="view-icon" />
                                    </Link>{" "}
                                    &nbsp;
                                    <Link
                                        className=""
                                        to={{
                                            pathname: "/",
                                        }}
                                    >
                                        <ListView width={35} className="view-icon" />
                                    </Link>
                                    {/* Site filtering */}
                                    {this.props.location.pathname.split("/")[1] === "map" ? (
                                        ""
                                    ) : (
                                        <React.Fragment>
                                            &nbsp;&nbsp;<span class="uniqueSpan1"> SHOW </span>
                                            <div className="redSites colourSites">
                                                <RedSites type="button" className="sites" width={35} />
                                                {/* <RedSitesRollover
                          type="button"
                          onClick={() => this.handleFilter(2)}
                          className="redRollover colourRollover"
                          width={35}
                        /> */}
                                                <div
                                                    className={`underline ${this.state.redSelected}`}
                                                ></div>
                                            </div>
                                            <div className="amberSites colourSites">
                                                <AmberSites
                                                    type="button"
                                                    className="sites"
                                                    width={35}
                                                />
                                                {/* <AmberSitesRollover
                          type="button"
                          onClick={() => this.handleFilter(1)}
                          className="amberRollover colourRollover"
                          width={35}
                        /> */}
                                                <div
                                                    className={`underline ${this.state.amberSelected}`}
                                                ></div>
                                            </div>
                                            <div className="greenSites colourSites">
                                                <GreenSites className="sites" width={35} />
                                                {/* <GreenSitesRollover
                          type="button"
                          id="greenButton"
                          onClick={() => this.handleFilter(0)}
                          className="greenRollover colourRollover"
                          width={35}
                        /> */}
                                                <div
                                                    className={`underline ${this.state.greenSelected}`}
                                                ></div>
                                            </div>
                                            <div className="greySites colourSites">
                                                <GreySites type="button" className="sites" width={35} />
                                                {/* <GreySitesRollover
                          type="button"
                          onClick={() => this.handleFilter(undefined)}
                          className="greyRollover colourRollover"
                          width={35}
                        /> */}
                                                <div
                                                    className={`underline ${this.state.greySelected}`}
                                                ></div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                            {this.props.location.pathname === "/dashboard" ||
                                this.props.location.pathname === "/detail" ? (
                                <div className="streaming-alarms">
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModal("liveFeed")}
                                    >
                                        LIVE FEED
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={() => this.toggleModal("callBox")}
                                    >
                                        CALL BOX
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() => this.toggleModal("emergency", "alarms")}
                                    >
                                        RAISE SPS ALARMS
                                    </Button>
                                    <Button
                                        color="danger"
                                        onClick={() => this.toggleModal("emergency", "999")}
                                    >
                                        EMERGENCY '999'
                                    </Button>
                                    <CallBoxModal ref="callBox" />
                                    <LiveFeedModal ref="liveFeed" />
                                    <EmergencyModal ref="emergency" />
                                </div>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>

                    <CornerTriangle className="corner" />
                </Navbar>
            </Container>
        );
    }
}

export default withRouter(NavMenu);
