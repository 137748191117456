import Dexie from 'dexie';
import { BehaviorSubject } from 'rxjs';
import { processService } from "./processing.service";
import { authService } from './auth.service';
import { apiService } from './api.service';
import { tileService } from './tile.service';

const dbName = "cachedb";

// var startTime, endTime; //Perf tracking
var initalCacheState;
if (JSON.parse(localStorage.getItem('cacheState')) === null) {
    initalCacheState = { populated: false };
} else {
    initalCacheState = JSON.parse(localStorage.getItem('cacheState'));
}
const cacheLoaded = new BehaviorSubject(initalCacheState);

var db = new Dexie(dbName);

db.version(1).stores({
    //Every site for an account
    sites: 'id, name, address, access, rooms, onSiteContactName, onSitePhoneNum, mainRoomLocation, image, latitude, longitude',
    //Every room for every site
    rooms: 'id, name, location, site_Id, notes, image',
    //Every sensor for every room
    sensors: '++id, sensorId, name, type, roomId',
    //24hrs of readings for every sensor
    readings: '++id, sensorId, type, name, value, alertLevel, readingTime, batteryLevel, signalStrength',
    //All assets for an account
    assets: 'id, name, type, serialNumber, notes, assetCondition, location, room_Id, account_Id, image, thumbnailImage',
    //All defects for an account
    defects: 'id, name, description, defectStatus, location, room_Id, asset_Id, assetName, account_Id, image, thumbnailImage',
    //Current dashboard tiles
    tiles: 'id++, name, type, isGraph, isSensor, isMini, alertLevel, sensorId, roomId, roomName, siteName, siteId, siteAddress, headColour, bodyColour, icon, tile'
});

const deleteDb = () => {
    //Removes the database completely and the local storage cache state
    db.delete()
        .then(() => {
            cacheLoaded.next({ populated: false });
            localStorage.removeItem('cacheState');
        })
}

const fillCache = () => new Promise((resolve) => {
    //For performance testing
    // startTime = performance.now();

    //Open the Dexie database
    db.open();

    //Set cache state to match current status
    localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting sites..." }))
    cacheLoaded.next({ populated: false, status: "Getting sites..." })

    //Make sure someone is logged in
    if (authService.currentUserValue !== null) {

        //get sites that match that account Id
        apiService.getData("/dash/" + authService.currentUserValue.AccountId)
            .then(sites => {
                addSites(sites)
                    .then(() => {
                        //Set cache state to match current status
                        localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting rooms..." }))
                        cacheLoaded.next({ populated: false, status: "Getting rooms..." })

                        //get rooms
                        var rooms = sites.map(site => apiService.getData("/room/site?id=" + site.id));
                        Promise.all(rooms)
                            .then(roomsData => {
                                var flatRooms = roomsData.flat();
                                addRooms(flatRooms)
                                    .then(() => {
                                        //Set cache state to match current status
                                        localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting sensors..." }))
                                        cacheLoaded.next({ populated: false, status: "Getting sensors..." })

                                        //get sensors
                                        var sensors = flatRooms.map(room => apiService.getData("/sensor?roomid=" + room.id));
                                        Promise.all(sensors)
                                            .then(sensorData => {
                                                var flatSensors = sensorData.flat();
                                                var sensorIds = [];

                                                //Fix ampersand and add each sensor id to an array
                                                flatSensors.forEach(sensor => {
                                                    sensor.name = sensor.name.replace(/&amp;/g, "&");
                                                    sensorIds.push(sensor.originId);
                                                })
                                                addSensors(flatSensors)
                                                    .then(() => {
                                                        //Set cache state to match current status
                                                        localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting readings..." }))
                                                        cacheLoaded.next({ populated: false, status: "Getting readings..." })

                                                        //get readings
                                                        //pass in the whole array of sensor ids instead of doing a call for each sensor - reduces hit on api/database
                                                        apiService.postData("/sensor/recent", sensorIds)
                                                            .then(readingData => {
                                                                var flatReadings = readingData.flat();
                                                                addReadings(flatReadings)
                                                                    .then(flatReadings => {
                                                                        //Set cache state to match current status
                                                                        localStorage.setItem('cacheState', JSON.stringify({ populated: true }))
                                                                        cacheLoaded.next({ populated: true, count: flatReadings });

                                                                        //for performance testing
                                                                        // endTime = performance.now();
                                                                        // var execTime = endTime - startTime;

                                                                        //Only return true if every step was a success
                                                                        return resolve(true);
                                                                    })
                                                            })
                                                    })
                                            })
                                    })
                            })
                    })
            });
    }
})

const updateCache = () => {
    //Get the latest reading
    db.readings.orderBy('readingTime').reverse().first(lastReading => {

        //Pass in the time of that last reading to the this endpoint to get all readings after that time
        apiService.getData(`/dash/refresh?accountId=${authService.currentUserValue.AccountId}&lastReadTime=${lastReading.readingTime.toISOString()}`)
            .then(readings => {
                addReadings(readings);
            })
    })
}

const addSites = (sites) => {
    return new Promise(async (resolve) => {
        db.sites.bulkAdd(sites)
            .then(addedSites => {
                resolve(addedSites);
            });
    })
}

const addRooms = (rooms) => {
    return new Promise((resolve) => {
        db.rooms.bulkAdd(rooms)
            .then(addedRooms => {
                resolve(addedRooms);
            });
    })
}

const addSensors = (sensors) => {
    return new Promise((resolve) => {
        var processedSensors = processService.ProcessSensor(sensors)
        db.sensors.bulkAdd(processedSensors)
            .then(addedSensors => {
                resolve(addedSensors);
            })
    })
}

const updateSensorName = (sensorId, sensorName) => {
    return new Promise((resolve) => {
        //Change the name of the sensor where the sensor Ids match
        db.sensors.where("sensorId").equals(sensorId).modify({ name: sensorName }).then(() => {
            return resolve(true);
        })
    })
}

const addReadings = (readings) => {
    return new Promise((resolve) => {
        //Run through the process service before adding
        var processedReadings = processService.ProcessReading(readings);

        db.readings.bulkAdd(processedReadings)
            .then(processedReadings => {
                resolve(processedReadings);
            })
    })
}

const fillAssetDefectCache = () => {
    return new Promise((resolve) => {
        //Open the Dexie database
        db.open();

        //Set cache state to match current status
        localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting assets..." }))
        cacheLoaded.next({ populated: false, status: "Getting assets..." })


        if (authService.currentUserValue !== null) {
            //Get assets
            apiService.getData("/asset").then(assets => {
                addAssets(assets).then(() => {

                    //Set cache state to match current status
                    localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting defects..." }))
                    cacheLoaded.next({ populated: false, status: "Getting defects..." })

                    //Get defects
                    apiService.getData("/defect").then(defects => {
                        addDefects(defects).then(() => {

                            //Set cache state to match current status
                            localStorage.setItem('cacheState', JSON.stringify({ populated: true, status: "All done" }))
                            cacheLoaded.next({ populated: true, status: "All done" })

                            return resolve(true)
                        })
                    })
                })
            })
        }
    })
}

const addAssets = (assets) => {
    return new Promise(async (resolve) => {
        db.assets.bulkAdd(assets)
            .then(addedAssets => {
                resolve(addedAssets);
            });
    })
}

const updateAssets = () => {
    return new Promise(resolve => {
        //Clear the assets store first
        db.assets.clear();

        //Get all the assets
        apiService.getData("/asset").then(assets => {
            addAssets(assets).then(() => {

                //Clear the tiles store
                db.tiles.clear();

                //Re-run the tile service
                tileService.createTileData().then(data => {
                    resolve(data)
                })
            })
        })
    })
}

const addDefects = (defects) => {
    return new Promise(async (resolve) => {
        db.defects.bulkAdd(defects)
            .then(addedDefects => {
                resolve(addedDefects);
            });
    })
}

const updateDefects = () => {
    return new Promise(resolve => {
        //Clear the defects store first
        db.defects.clear();

        //Get all the defects
        apiService.getData("/defect").then(defects => {
            addDefects(defects).then(() => {
                resolve(defects)
            })
        })
    })
}

const fillTileCache = (sensorInfo) => {
    return new Promise(resolve => {
        //Open the dexie database
        db.open();

        //Clear the tiles store
        db.tiles.clear();

        //Set cache state to match current status
        localStorage.setItem('cacheState', JSON.stringify({ populated: false, status: "Getting tiles..." }))
        cacheLoaded.next({ populated: false, status: "Getting tiles..." })

        //Add the sensors to the tile cache
        db.tiles.bulkAdd(sensorInfo)
            .then((addedSensors) => {
                //Set cache state to match current status
                localStorage.setItem('cacheState', JSON.stringify({ populated: true, status: "All done" }))
                cacheLoaded.next({ populated: true, status: "All done" })

                return resolve(addedSensors);
            })
    })
}

export const cacheService = {
    fillCache,
    fillAssetDefectCache,
    fillTileCache,
    updateCache,
    updateSensorName,
    updateAssets,
    updateDefects,
    deleteDb,
    cacheLoaded,
    db
} 
