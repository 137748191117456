import React from 'react';

import { ReactComponent as SignalStrength5 } from '../../icons/SignalStrength5.svg';
import { ReactComponent as SignalStrength4 } from '../../icons/SignalStrength4.svg';
import { ReactComponent as SignalStrength3 } from '../../icons/SignalStrength3.svg';
import { ReactComponent as SignalStrength2 } from '../../icons/SignalStrength2.svg';
import { ReactComponent as SignalStrength1 } from '../../icons/SignalStrength1.svg';
import { ReactComponent as SignalStrength0 } from '../../icons/SignalStrength0.svg';

export default class Signal extends React.Component {

    //Takes a value in as props and returns a signal indicator SVG with the correct amount of bars
    render() {
        const { value } = this.props;

        if (value >= 85) {
            return (
                <SignalStrength5 width={this.props.width} />
            )
        } else if (value < 85 && value >= 70) {
            return (
                <SignalStrength4 width={this.props.width} />
            )
        } else if (value < 70 && value >= 50) {
            return (
                <SignalStrength3 width={this.props.width} />
            )
        } else if (value < 50 && value >= 30) {
            return (
                <SignalStrength2 width={this.props.width} />
            )
        } else if (value < 30 && value >= 10) {
            return (
                <SignalStrength1 width={this.props.width} />
            )
        } else if (value < 10) {
            return (
                <SignalStrength0 width={this.props.width} />
            )
        } else {
            return (
                <SignalStrength0 width={this.props.width} />
            )
        }
    }
}