import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';

export default class DetailCoChart extends React.Component {

    // Just a standard page for the CO levels information
    render() {
        return (
            <Container className="co-level">
                <h3>Levels of carbon monoxide exposure can range from low to dangerous: </h3>
                <Row>
                    <Col className="co-level-low">
                        <h4>LOW LEVEL</h4>
                        <span><strong>50</strong> PPM</span> <br />
                        <span>AND LESS ▼</span>
                    </Col>
                    <Col className="co-level-mid">
                        <h4>MID LEVEL</h4>
                        <span>BETWEEN</span> <br />
                        <span><strong>51</strong> PPM ◄► <strong>100</strong> PPM</span>
                    </Col>
                    <Col className="co-level-high">
                        <h4>HIGH LEVEL</h4>
                        <span>GREATER THAN</span> <br />
                        <span>► <strong>100</strong> PPM</span>  <br />
                        <p>WITH NO SYMPTOMS</p>
                    </Col>
                    <Col className="co-level-high">
                        <h4>DANGEROUS LEVEL</h4>
                        <span>GREATER THAN</span> <br />
                        <span>► <strong>100</strong> PPM</span> <br />
                        <p>WITH SYMPTOMS</p>
                    </Col>
                </Row>

                <Row className="readings-table-body">
                    <h2>SYMPTOMS</h2>
                    <Table size="l">
                        <tbody>
                            <tr>
                                <td>50 PPM</td>
                                <td>
                                    No symptoms for healthy adults. According to the Occupational Safety & Health Administration
                                    (OSHA), this is the maximum allowable concentration for continuous exposure for healthy
                                    adults in any eight-hour period.
                            </td>
                            </tr>
                            <tr>
                                <td>200 PPM</td>
                                <td>
                                    Slight headache, fatigue, dizziness, and nausea after two to three hours.
                            </td>
                            </tr>
                            <tr>
                                <td>400 PPM</td>
                                <td>
                                    Frontal headaches with one to two hours. Life threatening after three hours.
                            </td>
                            </tr>
                            <tr>
                                <td>800 PPM</td>
                                <td>
                                    Dizziness, nausea, and convulsions within 45 minutes. Unconsciousness within two hours.
                                    Death within two to three hours.
                            </td>
                            </tr>
                            <tr>
                                <td>1600 PPM</td>
                                <td>
                                    Headache, dizziness and nausea within 20 minutes. Death within one hour.
                            </td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Container>
        )
    }
}